<template>
    <div>
        <div
            v-if="!feedback"
            class="feedback-form-component">
            <button
                v-for="(feedbackOption, type) in feedbacks"
                :key="type"
                class="flex w-full items-center gap-6 p-6 text-lg transition hover:bg-iel-silver"
                @click="setFeedbackType(feedbackOption, type)">
                <AppIcon :name="feedbackOption.icon" />
                <p>{{ getLabel(type) }}</p>
            </button>
        </div>

        <div v-else>
            <button
                class="mb-4 mt-2 h-6 w-12 rotate-180 text-iel-blue"
                @click="back">
                <AppIcon name="far fa-arrow-right" />
            </button>

            <div
                v-if="!showSuccessMessage"
                class="p-4 pt-0">
                <h2>{{ getLabel(feedback.type) }}</h2>
                <form
                    id="feedback-form"
                    @submit.prevent="submit">
                    <textarea
                        v-model="form.content"
                        class="my-6 w-full border border-b-4 border-iel-silver border-b-iel-blue placeholder:text-sm focus:border-iel-lightest-gray focus:border-b-iel-blue focus:ring-1 focus:ring-iel-blue focus:ring-opacity-25"
                        placeholder="Please provide your feedback."
                        rows="3"
                        required />
                    <AppButton
                        type="submit"
                        :disabled="isLoading"
                        class="ml-auto">
                        {{ labels.button }}
                    </AppButton>
                </form>
            </div>

            <div
                v-if="showSuccessMessage"
                class="text-center">
                <p class="font-bold text-iel-blue">{{ labels.success }}</p>
                <svg
                    class="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52">
                    <circle
                        class="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none" />
                    <path
                        class="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, toRefs } from 'vue';
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    feedbacks: Object,
    labels: Object
});

const { feedbacks, labels } = toRefs(props);

const form = useForm({
    content: '',
    type: '',
    page: window.location.href
});

const feedback = ref(null);
const isLoading = ref(false);
const showSuccessMessage = ref(false);

function setFeedbackType(feedbackOption, type) {
    feedbackOption.type = type;
    feedback.value = feedbackOption;
    form.type = type;
}

function getLabel(type) {
    return labels.value.feedbacks[type]?.label || 'Unknown';
}

function back() {
    showSuccessMessage.value = false;
    feedback.value = null;
    form.reset();
}

function submit() {
    isLoading.value = true;
    form.post(route('feedback.store'), {
        preserveScroll: true,
        onSuccess() {
            isLoading.value = false;
            showSuccessMessage.value = true;
        },
        onError() {
            isLoading.value = false;
        }
    });
}
</script>

<style scoped>
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #16a34a;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #16a34a;
    animation:
        fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #16a34a;
    }
}
</style>
