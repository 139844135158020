<template>
    <details
        :open="!unauthorized"
        :class="{ disabled: unauthorized }">
        <summary
            :id="`load-${title.replace(/ /g, '')}-section-button`"
            class="flex w-full cursor-pointer select-none items-center justify-between rounded-md bg-iel-gray/60 px-2 py-1 text-lg font-medium text-white transition"
            :tabindex="unauthorized ? -1 : 0">
            <span>{{ title }}</span>
            <AppIcon
                name="far fa-caret-up"
                class="mr-2 text-4xl transition"
                :class="{ 'opacity-50': unauthorized }"
                :permission="permission" />
        </summary>
        <div class="pt-2">
            <slot />
        </div>
    </details>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    permission: String
});

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));
</script>

<style scoped>
/* CSS rules to disable interaction with the <summary> element */
details.disabled summary {
    pointer-events: none; /* prevents click events */
    user-select: none; /* prevents text selection */
}
</style>
