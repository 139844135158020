<template>
    <form
        class="flex w-full flex-col items-center justify-center gap-4 rounded-md border-2 border-dashed border-iel-gray/50 p-4 text-iel-gray transition"
        :class="[
            isDragging ? 'bg-iel-light-green/20' : 'bg-white',
            unauthorized ? 'disabled cursor-not-allowed bg-white' : ''
        ]"
        @dragover.prevent="isDragging = true"
        @dragleave="isDragging = false"
        @drop.prevent="drop">
        <input
            :id="id"
            ref="file"
            multiple
            type="file"
            :name="id"
            :accept="fileTypesAsString"
            class="sr-only"
            :disabled="unauthorized"
            @change="onChange" />
        <AppIcon
            :name="files.length ? 'fal fa-file-circle-check' : 'fal fa-file-arrow-up'"
            class="h-12 w-12" />
        <label
            v-if="!disabled && !unauthorized"
            :for="id"
            class="text-center text-lg xl:text-base">
            <div v-if="isDragging && !unauthorized">{{ $t('Drop File') }}</div>
            <div
                v-else
                class="capitalize">
                <span
                    :class="[
                        'font-bold text-iel-blue underline transition hover:text-iel-blue/60',
                        !unauthorized ? 'cursor-pointer' : ''
                    ]">
                    {{ $t('Browse files') }}
                </span>
                {{ $t('or drag and drop') }}
            </div>
            <span class="block text-center text-xs">{{ $t('PDF, JPG, PNG') }}</span>
        </label>
        <div
            v-if="unauthorized"
            class="text-center">
            {{ $t('Unauthorized') }}
        </div>
        <AppErrorMessage
            name="pending-docs"
            :error="error" />
    </form>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
    id: String,
    acceptedFileTypes: Array,
    multiple: Boolean,
    disabled: Boolean,
    permission: String
});

const files = defineModel();

const isDragging = ref(false);
const file = ref(null);
const error = ref('');

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));

const fileTypesAsString = props.acceptedFileTypes.map(function (fileType) {
    return '.' + fileType.split('/')[1];
});

function onChange() {
    files.value = [...files.value, ...validFiles(file.value.files)];
}

function drop(e) {
    if (unauthorized.value) {
        isDragging.value = false;
        return;
    }
    files.value = [...files.value, ...validFiles(e.dataTransfer.files)];
    isDragging.value = false;
}

function validFiles(files) {
    error.value = '';

    files = [...files];
    files.map(file => {
        if (!props.acceptedFileTypes.includes(file.type)) {
            error.value += `${file.name} is an invalid file type. `;
        }
    });

    return files.filter(file => {
        return props.acceptedFileTypes.includes(file.type);
    });
}
</script>
