<template>
    <AppDropdown
        align="bottom-right"
        :prevent-close-on-content-click="true">
        <template #trigger>
            <button>
                <AppIcon
                    name="fas fa-filter"
                    class="mt-1 h-5 w-5 text-iel-dark-gray/70" />
            </button>
        </template>

        <template #content>
            <div class="p-2">
                <div class="mb-6 flex justify-between">
                    <p class="text-sm font-semibold">
                        {{ $t('Filter by group.') }}
                    </p>
                    <a
                        href="#"
                        class="text-sm font-semibold text-iel-red"
                        @click.prevent="resetFilters">
                        {{ $t('Reset') }}
                    </a>
                </div>

                <div>
                    <AppAutocomplete
                        v-model="customerGroup"
                        name="groups"
                        :as="'a'"
                        :href="updateUrl(customerGroup)"
                        :label="$t('Customer Groups')"
                        :options-url="route('customers.groups.autocomplete')"
                        @click="setUrl(customerGroup)" />
                </div>
            </div>
        </template>
    </AppDropdown>
</template>

<script setup>
import { router } from '@inertiajs/vue3';

const customerGroup = ref('');

const emit = defineEmits(['filter-changed']);

const currentSearch = ref(new URLSearchParams(location.search).get('group'));

watchEffect(() => {
    currentSearch.value = new URLSearchParams(location.search).get('group');
});

const setUrl = groupId => {
    const url = new URL(window.location.href);
    url.searchParams.set('group', groupId);
    history.pushState(null, '', url.toString());
    currentSearch.value = groupId;
    emit('filter-changed', groupId);
};

const updateUrl = groupId => {
    const url = new URL(window.location.href);
    url.searchParams.set('group', groupId);
    return url.toString();
};

watch(currentSearch, newGroup => {
    if (newGroup) {
        router.visit(route('customers.index', { group: newGroup }));
    }
});

const resetFilters = () => {
    router.visit(route('customers.index'));
};
</script>
