<template>
    <AppCard
        title="External"
        theme="light_gray">
        <div
            v-if="isInherited"
            class="border-y border-iel-light-blue bg-iel-lightest-blue px-4 py-3 text-iel-blue">
            <p class="uppercase">
                {{ $t('Inherited from group') }}
            </p>
        </div>
        <AppSelect
            name="map_service_version"
            label="PC Miler Version"
            permission="update_external"
            :model-value="customer.map_service_version"
            :url="url"
            :options="page.props.mapServiceVersions"
            :disabled="isInherited" />
        <div class="mt-4 flex items-center gap-4">
            <AppCheckbox
                name="enable_fourkites"
                label="Enable FourKites"
                permission="update_external"
                :model-value="customer.enable_fourkites"
                :url="url"
                :disabled="isInherited" />
            <AppInput
                v-if="customer.enable_fourkites"
                name="fourkites_customer_id"
                label="FourKites Customer ID"
                permission="update_external"
                :model-value="customer.fourkites_customer_id"
                :url="url"
                :disabled="isInherited" />
        </div>
        <AppInput
            name="macropoint_id"
            label="Macropoint ID"
            permission="update_external"
            :model-value="customer.macropoint_id"
            :url="url"
            class="mt-4"
            :disabled="isInherited" />
    </AppCard>
</template>

<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const customer = computed(() => page.props.customer);
const isInherited = computed(() => !!customer.value.customer_group?.id);

const url = route('customers.services.update', page.props.customer);
</script>
