<template>
    <AppGraphCircle
        title="Billed AR"
        :data="billedArData"
        :show-total="false"
        :show-legend="false" />
</template>

<script setup>
const props = defineProps({
    request: Object,
    availableCredit: Object
});

const customer = ref(props.request.entity);

const billedArData = computed(() => ({
    labels: ['Billed Amount', 'Unbilled Amount', 'Available Credit'],
    datasets: [
        {
            data: [
                (parseFloat(customer.value.billed_ar.amount) / 100).toFixed(2),
                (parseFloat(customer.value.unbilled_ar.amount) / 100).toFixed(2),
                parseFloat(props.availableCredit.amount).toFixed(2)
            ],
            formattedData: [
                customer.value.billed_ar.formatted,
                customer.value.unbilled_ar.formatted,
                props.availableCredit.formatted
            ],
            borderColor: ['#c10910', '#002a40', '#3bb273'],
            backgroundColor: ['#c10910', '#002a40', '#3bb273'],
            cutout: '70%'
        }
    ]
}));
</script>
