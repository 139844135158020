<template>
    <div class="space-y-2">
        <div class="flex items-center justify-between">
            <p class="font-bold text-iel-dark-gray">{{ $t('Stop Highlight') }}</p>
            <AppButton
                :href="route('loads.show', load) + '#stops_panel'"
                variant="outline"
                icon="far fa-arrow-right"
                trailing>
                {{ $t('All Stops') }}
            </AppButton>
        </div>
        <div
            v-if="stops.length"
            class="space-y-1 text-sm">
            <div
                v-for="(stop, i) in stops"
                :key="i"
                class="flex items-center gap-2">
                <AppIcon
                    :name="
                        stop.departed_at
                            ? 'far fa-check'
                            : stops[i - 1]?.departed_at
                              ? 'far fa-chevron-right'
                              : 'far fa-chevrons-right'
                    "
                    class="h-3 w-3 rounded p-0.5 text-white"
                    :class="stops[i - 1]?.departed_at ? 'bg-iel-blue' : 'bg-iel-gray'" />
                <span>
                    {{ $t('Appt#') }}
                    <span class="ml-2 font-medium">{{ stop.appointment_number }}</span>
                </span>
                <span
                    v-if="stop.stop_window_begins_at"
                    class="ml-auto">
                    <time
                        :datetime="stop.stop_window_begins_at"
                        class="font-medium">
                        {{ stop.stop_window_begins_at }}
                    </time>
                    <template v-if="stop.has_stop_window && stop.stop_window_ends_at">
                        -
                        <time
                            :datetime="stop.stop_window_ends_at"
                            class="font-medium">
                            {{ stop.stop_window_ends_at }}
                        </time>
                    </template>
                </span>
            </div>
        </div>
    </div>
    <hr />
</template>

<script setup>
const props = defineProps({
    load: Object
});

const stops = computed(() => {
    // Find the last departed stop in the array (max. is because findLastIndex returns -1 if nothing matches)
    const lastDepartedStop = Math.max(
        props.load.stops.findLastIndex(stop => stop.departed_at),
        0
    );
    // Return a subset of stops, up to a maximum of 3, starting with the last departed one
    return props.load.stops.slice(lastDepartedStop, lastDepartedStop + 3);
});
</script>
