<template>
    <div
        id="customer-credit-card"
        class="grid w-full gap-6 gap-x-12 md:grid-flow-col md:grid-cols-[3fr_1fr] md:grid-rows-2">
        <div class="grid grid-cols-2 items-center gap-6">
            <div class="relative">
                <RequestGraphCreditAmounts
                    :available-credit="availableCredit"
                    :request="request" />
            </div>
            <table class="max-w-72">
                <tbody>
                    <tr>
                        <td>
                            <AppIcon
                                name="fas fa-circle"
                                class="pr-1 text-iel-red" />
                            Billed Amount
                        </td>
                        <td class="text-right text-iel-gray">
                            {{ customer.billed_ar.formatted }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <AppIcon
                                name="fas fa-circle"
                                class="pr-1 text-iel-blue" />
                            Unbilled Amount
                        </td>
                        <td class="text-right text-iel-gray">
                            {{ customer.unbilled_ar.formatted }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <AppIcon
                                name="fas fa-circle"
                                class="pr-1 text-iel-green" />
                            Available Credit
                        </td>
                        <td class="text-right text-iel-gray">
                            {{ availableCredit.formatted }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="grid grid-cols-2 items-center gap-6">
            <div class="relative">
                <RequestGraphDaysToPay
                    :dtp-ranges="dtpRanges"
                    :dtp-counts="dtpCounts"
                    :total-sum="mean(daysToPay)" />
            </div>
            <table class="max-w-72">
                <thead>
                    <tr>
                        <th colspan="2">Average DTP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(range, index) in dtpRanges"
                        :key="index">
                        <td>
                            <AppIcon
                                name="fas fa-circle"
                                :class="`pr-1 ${range.color}`" />
                            {{ range.label }}
                        </td>
                        <td class="text-right text-iel-gray">
                            {{ dtpCounts[index] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <form class="grid content-center gap-4">
            <AppInput
                v-model="customer.first_pickup_date"
                name="customer_since"
                label="Customer Since"
                disabled />
            <AppInput
                :model-value="customer.credit_limit"
                name="credit_limit"
                label="Credit Limit"
                permission="update_customer_credit_request"
                :url="url"
                type="number"
                :min="0"
                step=".01"
                @blur="updateCreditDisplay" />
        </form>
    </div>
</template>

<script setup>
import { mean } from 'lodash-es';

const props = defineProps({
    request: Object
});

const customer = ref(props.request.entity);

const availableCredit = ref({
    amount: parseFloat(customer.value.availableCredit).toFixed(2),
    formatted: '$' + parseFloat(props.request.entity.available_credit.amount / 100).toFixed(2)
});

function updateCreditDisplay(value) {
    updateAvailableCredit(value.toFixed(2));
}

const updateAvailableCredit = newValue => {
    const newAmount =
        newValue -
        parseFloat(customer.value.billed_ar.amount) / 100 -
        parseFloat(customer.value.unbilled_ar.amount) / 100;
    availableCredit.value.amount = newAmount;
    availableCredit.value.formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        newAmount
    );
};

const url = route('customers.credit.update', props.request.entity.id);

const dtpRanges = [
    { color: 'text-iel-blue', label: '31-60', min: 31, max: 60 },
    { color: 'text-iel-dark-yellow', label: '61-90', min: 61, max: 90 },
    { color: 'text-iel-red', label: '91+', min: 91 }
];

const daysToPay = Object.entries(customer.value.days_to_pay).map(([, value]) => {
    return value;
});

const dtpCounts = dtpRanges.map(range => {
    return daysToPay.filter(num => {
        if (range.max) {
            return num >= range.min && num <= range.max;
        }

        return num >= range.min;
    }).length;
});

onMounted(() => {
    updateAvailableCredit(parseFloat(customer.value.credit_limit).toFixed(2));
});
</script>

<style scoped>
table tr {
    @apply h-8;
}
</style>
