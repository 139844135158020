<template>
    <div
        v-if="loaded"
        id="custom-legend-all-loads"
        class="absolute -ml-2 -mt-2 flex w-full items-center justify-center gap-x-6 py-4 text-sm md:text-xs">
        <div class="flex items-center gap-2">
            <span class="block h-3 w-3 rounded-full bg-[#c21714]"></span>
            Needs Attention ({{ needsAttentionCount }})
        </div>
        <div class="flex items-center gap-2">
            <span class="block h-3 w-3 rounded-full bg-iel-blue"></span>
            To Do ({{ toDoCount }})
        </div>
        <div class="flex items-center gap-2">
            <span class="block h-3 w-3 rounded-full bg-[#3bb273]"></span>
            Complete ({{ completeCount }})
        </div>
    </div>

    <Bar
        v-if="loaded"
        :id="kebabCase(title) + '-bar-graph'"
        :options="allLoadsOptions"
        :data="allLoadsData"
        aria-label="Bar graph"
        role="img">
        <p>Could not load graph.</p>
    </Bar>

    <AppGraphScreenReader
        :id="kebabCase(title) + 'graph-data-for-screen-readers'"
        :title="props.title"
        :data="allLoadsData" />
</template>

<script setup>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { kebabCase } from 'lodash-es';
import axios from 'axios';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const loaded = ref(false);

const needsAttentionCount = computed(
    () => props.needsAttentionCount || allLoadsData.value.datasets[0].data.reduce((a, b) => a + b, 0)
);
const toDoCount = computed(() => props.toDoCount || allLoadsData.value.datasets[1].data.reduce((a, b) => a + b, 0));
const completeCount = computed(
    () => props.completeCount || allLoadsData.value.datasets[2].data.reduce((a, b) => a + b, 0)
);

const props = defineProps({
    needsAttentionCount: Number,
    toDoCount: Number,
    completeCount: Number,
    title: {
        type: String,
        default: 'All Loads'
    }
});

const allLoadsData = ref({
    labels: [
        ['\ue1d9', 'Setup'],
        ['\uf46d', 'Plan'],
        ['\uf0d1', 'Ship'],
        ['\uf2e8', 'Bill'],
        ['\uf058', 'Done']
    ],
    datasets: [
        {
            label: 'Complete',
            data: [],
            backgroundColor: '#3bb273'
        },
        {
            label: 'To Do',
            data: [],
            backgroundColor: '#002a40'
        },
        {
            label: 'Needs Attention',
            data: [],
            backgroundColor: '#c21714'
        }
    ]
});

const fetchAllLoads = async () => {
    try {
        const response = await axios.get('/loads/all-loads');
        const data = response.data;
        const keys = ['setup', 'plan', 'ship', 'bill', 'complete'];
        const properties = ['complete', 'to_do', 'needs_attention'];
        keys.forEach((key, index) => {
            properties.forEach((property, i) => {
                if (data[key] && data[key][property] !== undefined) {
                    allLoadsData.value.datasets[i].data[index] = data[key][property];
                } else {
                    allLoadsData.value.datasets[i].data[index] = 0;
                }
            });
        });
        loaded.value = true;
    } catch (error) {
        console.error('Failed to fetch all loads data:', error);
    }
};

const allLoadsOptions = ref({
    responsive: true,
    maintainAspectRatio: false,
    label: false,
    borderRadius: 6,
    indexAxis: props.indexAxis,
    scales: {
        x: {
            ticks: {
                color: '#002a40',
                maxRotation: 0,
                font: {
                    family: "'Roboto', 'Font Awesome 6 Pro'",
                    size: 15,
                    lineHeight: 1.2
                }
            },
            grid: {
                display: false
            },
            border: {
                display: false
            },
            stacked: true
        },
        y: {
            grid: {
                display: false
            },
            border: {
                display: false
            },
            stacked: true,
            ticks: {
                color: '#002a40',
                stepSize: 20,
                maxRotation: 0
            }
        }
    },

    plugins: {
        title: {
            text: props.title
        },
        legend: {
            labels: {
                boxWidth: 0,
                boxHeight: 0,
                color: 'transparent',
                padding: 20
            }
        },
        tooltip: {
            titleFont: {
                family: "'Roboto', 'Font Awesome 6 Pro'"
            }
        }
    }
});

onMounted(() => {
    fetchAllLoads();
});
</script>

<style scoped>
canvas {
    @apply !h-72 w-auto;
}
</style>
