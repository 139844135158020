<template>
    <div
        class="flex items-center justify-center overflow-hidden rounded-full bg-iel-light-gray font-bold text-iel-blue">
        <img
            v-if="shouldDisplayAvatarImage()"
            class="h-full w-full object-cover"
            alt="User Avatar"
            :src="user.avatar" />

        <div
            v-else-if="shouldDisplayFirstLetterOfUserName()"
            class="uppercase">
            {{ getFirstLetterOfUserName() }}
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    user: {
        type: [Object, String],
        required: false
    }
});

const shouldDisplayAvatarImage = () => {
    return typeof props.user === 'object' && props.user.avatar !== undefined;
};

const shouldDisplayFirstLetterOfUserName = () => {
    if (typeof props.user === 'object') {
        return props.user.avatar === undefined;
    }

    return typeof props.user === 'string';
};

const getFirstLetterOfUserName = () => {
    if (typeof props.user === 'object') {
        return props.user.name[0];
    }

    return props.user[0];
};
</script>
