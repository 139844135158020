<template>
    <section>
        <header>
            <h2 class="text-lg font-medium text-iel-darkest-gray">Timezone</h2>
        </header>

        <form
            class="mt-4 grid gap-4"
            @submit.prevent="submit">
            <AppSelect
                id="profile-timezone-dropdown"
                v-model="form.timezone"
                name="user-timezone"
                label="Timezone"
                :options="timezones" />

            <div>
                <AppButton
                    id="profile-timezone-save-button"
                    type="submit"
                    :disabled="form.processing">
                    Save
                </AppButton>
            </div>
        </form>
    </section>
</template>

<script setup>
import { useForm, usePage, router } from '@inertiajs/vue3';
import { timezones } from '@/data';

defineProps({
    preferences: Array
});

const user = usePage().props.auth.user;

const form = useForm({
    timezone: user.preferences.timezone
});

function submit() {
    form.patch(route('userPreferences.update'), {
        preserveScroll: true,
        onSuccess() {
            router.reload({ only: ['settings'] });
        }
    });
}
</script>
