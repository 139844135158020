<template>
    <AppCard
        title="Contacts"
        theme="white"
        content-class="@container">
        <div class="w-full overflow-hidden">
            <AppTableSimple
                :columns="[
                    { name: 'name', label: 'Name' },
                    { name: 'phone_number', label: 'Phone' },
                    { name: 'email_address', label: 'Email' },
                    { name: 'title', label: 'Title' }
                ]"
                :data="contacts"
                th-class="bg-iel-light-gray">
                <template #actions="actionsProps">
                    <div class="flex gap-2">
                        <AppButton
                            icon="fal fa-pen"
                            variant="outline"
                            aria-label="button to edit contact"
                            @click="editContact(actionsProps)" />
                        <AppButton
                            icon="far fa-trash-can"
                            variant="outline"
                            permission="delete_carrier_contacts"
                            aria-label="button to delete contact"
                            @click="deleteContact(actionsProps)" />
                    </div>
                </template>

                <template #header>
                    <header class="mb-4 items-center justify-between md:flex">
                        <span class="uppercase text-iel-gray">{{ $t('Employees') }}</span>
                        <div class="md:flex md:items-center md:justify-end">
                            <AppButton
                                icon="far fa-circle-plus"
                                class="mb-2 md:mb-0 md:mr-4"
                                @click="createContact">
                                {{ $t('Add') }}
                            </AppButton>
                            <AppSearchInput
                                v-model="contactQuery"
                                class="mb-4 md:mb-0"
                                placeholder="Search" />
                        </div>
                    </header>
                </template>
            </AppTableSimple>
        </div>
    </AppCard>
</template>

<script setup>
import { router, usePage } from '@inertiajs/vue3';

const initialContactIds = ref([]);

const page = usePage();

const { open: openCarrierContactModal } = useModal('CarrierContactModal');

onMounted(() => (initialContactIds.value = page.props.carrier.contacts.map(({ id }) => id)));

const contactQuery = ref('');
const contacts = computed(() =>
    contactQuery.value
        ? (page.props.carrier.contacts?.filter(
              ({ name, phone_number, email_address }) =>
                  name.toLowerCase().includes(contactQuery.value.toLowerCase()) ||
                  phone_number.toLowerCase().includes(contactQuery.value.toLowerCase()) ||
                  email_address?.toLowerCase().includes(contactQuery.value.toLowerCase())
          ) ?? [])
        : (page.props.carrier.contacts ?? [])
);

function createContact() {
    openCarrierContactModal({
        title: 'Add Contact',
        mode: 'create',
        carrier: page.props.carrier,
        onClose() {
            router.reload({ only: ['carrier'] });
        }
    });
}

function deleteContact(contact) {
    if (confirm('Are you sure you want to delete this contact?')) {
        router.delete(
            route('carriers.contacts.destroy', {
                carrier: page.props.carrier,
                contact
            }),
            {
                preserveScroll: true,
                onError(e) {
                    alert(e.error_message);
                }
            }
        );
    }
}

function editContact(contact) {
    openCarrierContactModal({
        title: 'Edit Contact',
        mode: 'edit',
        carrier: page.props.carrier,
        contact,
        onClose() {
            router.reload({ only: ['shipping_details', 'receiving_details'] });
        }
    });
}
</script>
