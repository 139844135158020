<template>
    <div class="space-y-2 p-2">
        <LoadDetailDisclosure
            :title="$t('Load Details')"
            open>
            <LoadDetailDetails />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            :title="$t('Billing Details')"
            open>
            <LoadBilling />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            :title="$t('Accessorial Charges')"
            open>
            <LoadDetailAccessorialCharges />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            :title="$t('Carrier Info')"
            :open="!!load.carrier">
            <LoadDetailCarrierInfo @start-message="startMessage" />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            :title="$t('Carrier Pay Details')"
            :open="!!load.carrier">
            <LoadDetailCarrierPay />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            id="stops_panel"
            :title="$t('Stops')"
            open>
            <LoadDetailStops permission="update_stops" />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            :title="load.phase !== 'bill' ? $t('Documents') : $t('Required Documents for Customer Invoicing')"
            open>
            <LoadDocs
                v-if="load.customer"
                :load="load" />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            :title="$t('Load Notes')"
            open>
            <LoadDetailLoadNotes />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            id="load-text-section"
            :title="$t('Text')"
            open>
            <LoadDetailText ref="textTabs" />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            :title="$t('Tracking')"
            open>
            <LoadTracking :trackings="load.trackings" />
        </LoadDetailDisclosure>
        <LoadDetailDisclosure
            :title="$t('Checkcalls')"
            open>
            <LoadDetailCheckcalls />
        </LoadDetailDisclosure>
        <LoadDetailDisclosureCustomHeader
            v-if="isMacropointEnabled"
            :title="$t('Macropoint')"
            class="text-iel-red"
            open>
            <template #header>
                <LoadMacroPointStatusIcon
                    v-if="mpStatusMessage"
                    :mp-status-color="mpStatusColor"
                    :mp-status-message="mpStatusMessage" />
            </template>
            <template #body>
                <LoadDetailsMacropoint :load="load" />
            </template>
        </LoadDetailDisclosureCustomHeader>
        <div id="load-flags-detail-section">
            <LoadDetailDisclosure
                v-if="load.claim"
                class="mb-2"
                :title="$t('Claims Flag')"
                open>
                <LoadClaimsFlag />
            </LoadDetailDisclosure>
            <LoadDetailDisclosure
                v-if="load.carrier_incident"
                :title="$t('Carrier Relations Flag')"
                open>
                <LoadCarrierRelationsFlag />
            </LoadDetailDisclosure>
        </div>
        <LoadDetailDisclosure
            :title="$t('Advances')"
            open>
            <LoadDetailAdvances />
        </LoadDetailDisclosure>
    </div>
</template>

<script setup>
const props = defineProps({
    load: Object
});

const isMacropointEnabled = computed(() => Boolean(props.load.macropointOrder));

const textTabs = ref(null);

function startMessage(tabName) {
    textTabs.value.activateTab(tabName);
}

const mpStatusColor = ref(props.load?.macropointOrder?.status?.color || null);
const mpStatusMessage = ref(props.load?.macropointOrder?.status?.message || null);
</script>
