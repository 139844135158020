<template>
    <AppCard
        title="Equipment"
        theme="white"
        content-class="@container">
        <div class="w-full overflow-hidden">
            <AppTableSimple
                :columns="[
                    { name: 'vin', label: 'VIN' },
                    { name: 'scheduled_auto_only', label: 'Scheduled Auto' }
                ]"
                :data="vehicles"
                th-class="bg-iel-light-gray">
                <template #actions="actionsProps">
                    <div class="flex gap-2">
                        <AppButton
                            icon="fal fa-pen"
                            variant="outline"
                            permission="add_or_update_vehicle"
                            aria-label="button to edit vehicle"
                            @click="editVehicle(actionsProps)" />
                        <AppButton
                            icon="far fa-trash-can"
                            variant="outline"
                            permission="delete_vehicle"
                            aria-label="button to delete vehicle"
                            @click="deleteVehicle(actionsProps)" />
                    </div>
                </template>

                <template #header>
                    <header class="mb-4 items-center justify-between md:flex">
                        <span class="uppercase text-iel-gray">{{ $t('Vehicles') }}</span>
                        <div class="md:flex md:items-center md:justify-end">
                            <AppButton
                                icon="far fa-circle-plus"
                                class="mb-2 md:mb-0 md:mr-4"
                                permission="add_or_update_vehicle"
                                @click="createVehicle">
                                {{ $t('Add') }}
                            </AppButton>
                            <AppSearchInput
                                v-model="vehicleQuery"
                                class="mb-4 md:mb-0"
                                placeholder="Search" />
                        </div>
                    </header>
                </template>
            </AppTableSimple>
        </div>
    </AppCard>
</template>

<script setup>
import { router, usePage } from '@inertiajs/vue3';

const initialVehicleIds = ref([]);

const page = usePage();

const { open: openCarrierVehicleModal } = useModal('CarrierVehicleModal');

onMounted(() => (initialVehicleIds.value = page.props.carrier.vehicles.map(({ id }) => id)));

const vehicleQuery = ref('');
const vehicles = computed(() =>
    vehicleQuery.value
        ? (page.props.carrier.vehicles?.filter(({ vin }) =>
              vin.toLowerCase().includes(vehicleQuery.value.toLowerCase())
          ) ?? [])
        : (page.props.carrier.vehicles ?? [])
);

function createVehicle() {
    openCarrierVehicleModal({
        title: 'Create Vehicle',
        mode: 'create',
        carrier: page.props.carrier,
        onClose() {
            router.reload({ only: ['carrier'] });
        }
    });
}

function deleteVehicle(vehicle) {
    if (confirm('Are you sure you want to delete this vehicle?')) {
        router.delete(
            route('carriers.vehicles.destroy', {
                carrier: page.props.carrier,
                vehicle
            }),
            {
                preserveScroll: true,
                onError(e) {
                    alert(e.error_message);
                }
            }
        );
    }
}

function editVehicle(vehicle) {
    openCarrierVehicleModal({
        title: 'Edit Vehicle',
        mode: 'edit',
        carrier: page.props.carrier,
        vehicle,
        onClose() {
            router.reload({ only: ['shipping_details', 'receiving_details'] });
        }
    });
}
</script>
