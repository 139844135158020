import { ref } from 'vue';

export function allFilters() {
    const filters = ref({
        default: { label: 'All' },
        transit: { label: 'In Transit', icon: 'fal fa-truck' },
        'eta-behind': { label: 'Behind', color: 'bg-iel-red' },
        'eta-on-time': { label: 'On Time', color: 'bg-iel-green' },
        today: { label: 'Today', icon: 'far fa-calendar-day' },
        future: { label: 'Future', icon: 'far fa-calendar-arrow-up' },
        billing: { label: 'Bill', icon: 'fal fa-circle-dollar' },
        'past-due': { label: 'Needs Attention', icon: 'warning' }
    });

    return {
        filters
    };
}
