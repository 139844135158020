<template>
    <span
        class="flex items-center"
        :class="{ 'absolute inset-y-0 right-0': floating }">
        <AppIcon
            v-if="recentlySuccessful"
            name="far fa-circle-check"
            class="mr-2 h-4 w-4 text-iel-green" />
        <AppIcon
            v-else-if="processing"
            name="fal fa-arrows-rotate"
            class="mr-2 h-4 w-4 animate-spin text-iel-gray" />
        <AppIcon
            v-else-if="error"
            name="far fa-xmark"
            class="mr-2 h-4 w-4 cursor-pointer text-iel-red"
            mini
            @click="$emit('clear')" />
        <div
            v-if="displayText"
            class="text-sm">
            {{ text }}
        </div>
    </span>
</template>

<script setup>
const props = defineProps({
    recentlySuccessful: {
        type: Boolean
    },
    processing: {
        type: Boolean
    },
    error: {
        type: [String, Boolean]
    },
    floating: {
        type: Boolean,
        default: true
    },
    displayText: Boolean
});

defineEmits(['clear']);

const text = computed(() => {
    if (!props.displayText) return;
    if (props.processing) return 'Saving...';
    if (props.recentlySuccessful) return 'Saved';
    if (props.error) return 'Error';
});
</script>
