<template>
    <Head title="Users" />
    <AppHeader
        title="Users"
        :icon-component="IconUserRole">
        <template #search>
            <AppSearchInput
                id="users-list-search"
                v-model="q"
                placeholder="Search by name or role."
                class="w-52" />
        </template>
    </AppHeader>
    <slot />
</template>

<script setup>
import IconUserRole from '/resources/img/icon-user-role.svg';

defineProps({
    user: Object
});

const { q } = useQueryParam({ only: ['users'] });
</script>
