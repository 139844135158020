<template>
    <div class="flex flex-col gap-2">
        <div>
            <nav class="small-scrollbar overflow-y-hidden text-nowrap">
                <button
                    v-for="(tab, index) in tabs"
                    :key="index"
                    type="button"
                    class="relative m-1 border-b-2 font-bold"
                    :class="
                        activeTab === tab.title ? 'border-iel-blue text-iel-blue' : 'border-transparent text-iel-gray'
                    "
                    data-test="button"
                    @click="activateTab(tab)">
                    {{ tab.title }}
                    <AppBadge
                        v-if="tab.badge"
                        class="absolute -right-5 -top-1">
                        {{ tab.badge }}
                    </AppBadge>
                </button>
            </nav>
        </div>
        <div
            class="grow overflow-y-auto"
            tabindex="0">
            <slot />
        </div>
    </div>
</template>

<script setup>
defineProps({
    activeClass: String
});

const emit = defineEmits(['activatedTab']);

const activeTab = ref('');
const tabs = ref([]);

provide('tabs', tabs);
provide('activeTab', activeTab);

function activateTab(tab) {
    activeTab.value = tab.title;
    emit('activatedTab', tab);
}

defineExpose({ activateTab, tabs });
</script>
