<template>
    <table class="NX-mobile-stacked">
        <thead>
            <tr>
                <th class="border-b-2">{{ $t('Document Name') }}</th>
                <th class="border-b-2 lg:w-1/4">
                    {{ $t('Labels') }}
                </th>
            </tr>
        </thead>
        <tbody>
            <slot></slot>
        </tbody>
    </table>
</template>

<script setup></script>
