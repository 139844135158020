<template>
    <FontAwesomeLayers>
        <AppIcon
            name="fas fa-triangle"
            class="text-iel-yellow"
            :class="sizes[size]" />
        <AppIcon
            name="far fa-triangle-exclamation"
            class="text-black"
            :class="sizes[size]" />
        <slot />
    </FontAwesomeLayers>
</template>

<script setup>
defineProps({
    size: {
        type: String,
        required: false
    }
});

const sizes = {
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-6 w-6'
};
</script>
