<template>
    <AppTablePill
        id="carriers-table"
        :columns="[
            { name: 'name', label: 'Name', sortable: true },
            { name: 'mc_number', label: 'MC #' },
            { name: 'dot_number', label: 'DOT #' },
            { name: 'rating', label: 'Rating' },
            { name: 'current_loads', label: 'Current Loads' },
            { name: 'completed_loads', label: 'Total Loads' },
            { name: 'fallout_percentage', label: 'Fallout Percentage' },
            { name: 'open_ap', label: 'Open AP' },
            { name: 'w8_w9', label: '' },
            { name: 'insurance', label: '' },
            { name: 'authority', label: '' },
            { name: 'actions', label: '' }
        ]"
        data-key="carriers"
        :data="carriers.data">
        <template #name-cell="{ row }">
            <Link
                :href="route('carriers.show', row.id)"
                class="flex items-center gap-2">
                <div class="flex flex-1 gap-2 text-iel-blue">
                    <AppIcon
                        :name="row.is_active ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                        :class="row.is_active ? 'text-iel-green' : 'text-iel-red'"
                        class="h-5 w-5"
                        solid />
                    <strong>{{ row.name }}</strong>
                </div>
            </Link>
        </template>
        <template #w8_w9-cell="{ row }">
            <div class="flex flex-1 items-center gap-2 text-iel-blue">
                <AppIcon
                    :name="row.has_w8_w9 ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                    :class="row.has_w8_w9 ? 'text-iel-green' : 'text-iel-red'"
                    class="h-5 w-5"
                    solid />
                <strong>W8/W9</strong>
            </div>
        </template>
        <template #insurance-cell="{ row }">
            <div class="flex flex-1 items-center gap-2 text-iel-blue">
                <AppIcon
                    :name="row.has_coverage ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                    :class="row.has_coverage ? 'text-iel-green' : 'text-iel-red'"
                    class="h-5 w-5"
                    solid />
                <strong>Insurance</strong>
            </div>
        </template>
        <template #authority-cell="{ row }">
            <div class="flex flex-1 items-center gap-2 text-iel-blue">
                <AppIcon
                    :name="hasAuthorityCheckmark(row) ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                    :class="hasAuthorityCheckmark(row) ? 'text-iel-green' : 'text-iel-red'"
                    class="h-5 w-5"
                    solid />
                <strong>Authority</strong>
            </div>
        </template>
        <template #actions-cell="{ row }">
            <div class="flex w-full justify-end gap-4">
                <template v-if="row.is_active && row.needs_insurance_update">
                    <AppButton
                        id="update-insurance-button"
                        icon="far fa-shield-plus"
                        :disabled="row.has_open_ticket"
                        @click="openInsuranceModal(row)">
                        {{ $t('Update Insurance') }}
                    </AppButton>
                </template>
                <template v-else-if="!row.is_active">
                    <AppButton
                        id="activate-button"
                        icon="far fa-truck"
                        :disabled="row.has_open_ticket"
                        @click="openActivationModal(row)">
                        {{ $t('Activate') }}
                    </AppButton>
                </template>
            </div>
        </template>
    </AppTablePill>
    <AppPagination :meta="carriers.meta" />
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import CarrierLayout from '@/Layouts/CarrierLayout.vue';

// Define persistent layouts so that these layout components don't re-render when navigating between
// pages that both use them. E.g. when searching for a carrier, persist the AppHeader component so the
// search bar doesn't re-render and can stay focused even while the page 'reloads' with new results.
// See https://inertiajs.com/pages#persistent-layouts
defineOptions({
    layout: [AuthenticatedLayout, CarrierLayout]
});

defineProps({
    carriers: Object
});

const { open: CarrierTicketModal } = useModal('CarrierTicketModal');

const openInsuranceModal = row => {
    CarrierTicketModal({
        modalId: 'carrier-update-insurance-modal',
        title: 'Request Carrier Insurance Update',
        ticketType: 'Insurance Update',
        carrier: row
    });
};

const openActivationModal = row => {
    CarrierTicketModal({
        modalId: 'carrier-activate-modal',
        title: 'Request Carrier Activation',
        ticketType: 'Carrier Activation',
        carrier: row
    });
};

const hasAuthorityCheckmark = row => {
    const verificationData = row.verification_data;
    const isCanadaOnly = row.canada_only;
    const isIntrastateOnly = row.intrastate_only;

    if (!verificationData || isCanadaOnly || isIntrastateOnly) {
        return false;
    }
    return (
        verificationData.brokerAuthorityStatus === 'Active' ||
        verificationData.contractAuthorityStatus === 'Active' ||
        verificationData.commonAuthorityStatus === 'Active'
    );
};
</script>
