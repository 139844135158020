<template>
    <form
        class="mb-4 rounded-md border bg-white p-2 @container"
        @submit.prevent="createNote">
        <AppTextarea
            v-model="form.content"
            name="content"
            label=""
            required />
        <div class="my-2 flex w-full justify-end gap-x-2">
            <AppButton
                id="request-create-note-cancel-button"
                type="submit"
                class="self-start"
                variant="outline"
                @click.prevent="cancelNote">
                {{ $t('Cancel') }}
            </AppButton>
            <AppButton
                id="request-create-note-add-button"
                type="submit"
                class="self-start"
                :disabled="form.processing">
                {{ $t('Add Note') }}
            </AppButton>
        </div>
    </form>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    request: Object
});

const emit = defineEmits(['success']);

const noteForm = inject('showingCreateNoteForm');

const form = useForm({
    content: '',
    noteable_type: 'request',
    noteable_id: props.request.id
});

provide('form', form);

function createNote() {
    form.post(route('tickets.notes.store', props.request), {
        preserveScroll: true,
        onSuccess: () => {
            emit('success');
        }
    });
}

function cancelNote() {
    noteForm.value = false;
}
</script>
