<template>
    <div
        class="space-y-1"
        data-test="wrapper">
        <div class="flex items-center gap-2">
            <input
                v-bind="attributes"
                v-model="value"
                class="h-4 w-4 rounded border-iel-light-gray text-iel-blue"
                type="checkbox"
                @change="handleChange" />
            <label
                :for="attributes.name"
                class="text-sm text-iel-dark-gray"
                :class="{
                    'text-iel-dark-red': error,
                    'cursor-not-allowed !text-iel-gray': disabled
                }"
                data-test="label">
                {{ fieldLabel }}
                <span
                    v-if="required"
                    class="select-none">
                    *
                </span>
            </label>
            <AppRequestStatus
                v-if="hasAutoSave"
                v-bind="{ recentlySuccessful, processing, error }"
                class="!relative" />
        </div>
        <AppErrorMessage
            :name="attributes.name"
            :error="error" />
    </div>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: String,
    error: String,
    scope: [String, Number],
    required: Boolean,
    disabled: Boolean,
    url: String,
    confirmation: String,
    permission: String
});

const emit = defineEmits(['change', 'autoSaveChange']);

const hasAutoSave = props.url !== undefined;

const form = inject('form', undefined);

const {
    value: autoSaveValue,
    submit,
    processing,
    recentlySuccessful,
    error: autoSaveError,
    clearError
} = useAutoSave(props, 'modelValue');

const definedModel = defineModel({ type: [Boolean] });

const value = hasAutoSave ? autoSaveValue : definedModel;

const error = hasAutoSave ? autoSaveError : computed(() => props.error || form?.errors?.[props.name]);

const { label: fieldLabel, attributes } = useField(props, error);

function handleChange() {
    if (hasAutoSave) {
        clearError();
        nextTick(() => submit());
        emit('autoSaveChange');
    } else {
        emit('change');
        form?.clearErrors(props.name);
    }
}
</script>
