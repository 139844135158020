/**
 * Central place to store lists of static data, basically frontend enums.
 */

export const checkcallReasons = {
    Normal: 'Normal',
    'Customer Requested Future': 'Customer Requested Future',
    Delivery: 'Delivery',
    Accident: 'Accident',
    'Driver Related': 'Driver Related',
    'Mechanical Breakdown': 'Mechanical Breakdown',
    'Previous Stop': 'Previous Stop',
    'Shipper Related': 'Shipper Related',
    'Weather or Natural Disaster Related': 'Weather or Natural Disaster Related',
    'Insufficient Pick-up Time': 'Insufficient Pick-up Time',
    'Held Per Shipper': 'Held Per Shipper',
    'Road Conditions': 'Road Conditions',
    'Customer Wanted Earlier Delivery': 'Customer Wanted Earlier Delivery',
    'Carrier Dispatch Error': 'Carrier Dispatch Error'
};

export const tonuCancelReasons = {
    'Customer Canceled': 'Customer Canceled',
    'Product Unavailable': 'Product Unavailable',
    'Product Damaged': 'Product Damaged',
    'Shipper Issue': 'Shipper Issue',
    'Carrier Issue': 'Carrier Issue',
    'Delivery Issue': 'Delivery Issue',
    'Double Booking': 'Double Booking'
};

export const checkcallSources = {
    Dispatcher: 'Dispatcher',
    Driver: 'Driver',
    Receiver: 'Receiver',
    Shipper: 'Shipper',
    Macropoint: 'Macropoint',
    'No Answer': 'No Answer'
};

export const timezones = {
    // local: 'Local Time',
    'America/St_Johns': 'Newfoundland Time',
    'America/Halifax': 'Atlantic Time',
    'America/New_York': 'Eastern Time',
    'America/Chicago': 'Central Time',
    'America/Denver': 'Mountain Time',
    'America/Phoenix': 'Mountain Time (Arizona)',
    'America/Los_Angeles': 'Pacific Time'
};

export const commodityTypes = {
    dry: 'Dry',
    cooler: 'Cooler',
    frozen: 'Frozen'
};

export const userRoles = {
    0: 'None',
    1: 'Admin',
    2: 'Manager'
};

export const loadDocumentTypes = [
    'POD',
    'BOL',
    'Stamp',
    'Sticker',
    'Weight Ticket',
    'Pass',
    'Seal',
    'Receipt',
    'Lumper Receipt',
    'Accessorial Receipt',
    'Load Tender',
    'Customer Invoice',
    'Claims',
    'Carrier RateCon',
    'Carrier Invoice',
    'Carrier Insurance',
    'TempTale',
    'Damage Photo',
    'Pulp Temp Photo',
    'Reefer Download',
    'USDA Inspection Cert',
    'Claim Invoice',
    'Original Sales Invoice',
    'Salvage Invoice',
    'Disposal Invoice',
    'TONU Approval'
];

export const billingRates = [
    'Additional Miles',
    'Bobtail Fee',
    'Bond Fee',
    'Chassis Fee',
    'Claim',
    'Credit Card Fee',
    'Customer Rebate',
    'Customs',
    'Detention',
    'Drayage',
    "Driver's Assist",
    'Dry Run Free',
    'Escort Fee',
    'Extra Day Charge',
    'Extra Drop',
    'Extra Pick',
    'Extra Weight',
    'Flat',
    'Flip Charge',
    'Fuel Surcharge',
    'Gate Fee',
    'GST',
    'Hazmat Fee',
    'IEL Lease Fee',
    'Late Fee',
    'Layover',
    'Ocean',
    'Other',
    'Overweight',
    'Pallets',
    'Per 100 Wt',
    'Per 25 lbs Bag',
    'Per 50 Wt',
    'Per 50 lbs Bag',
    'Per Bag',
    'Per Box',
    'Per Mile',
    'Per Pallet',
    'Per Ton',
    'Per Tote',
    'Per Unit',
    'Port Congestion Fee',
    'Pre-Pull Fee',
    'Product Shortage',
    'Quick Pay Fee',
    'Re-Delivery',
    'Reefer Fee',
    'All Risk Insurance Fee',
    'Split Chassis',
    'Storage Fee',
    'Tarp Fee',
    'Team Drivers',
    'Tolls',
    'Truck Order Not Used',
    'Truck Wash',
    'TWIC Card',
    'Unloading Fee',
    'Work in',
    'XPL Savings'
];

export const accessorialCharges = [
    'Advanced Fees',
    'Bobtail Fee',
    'Bond Fee',
    'Brokerage Fee',
    'Chassis Fee',
    'Claim',
    'Crossdock',
    'Customs',
    'Detention',
    'Drayage',
    "Driver's Assist",
    'Dry Run Fee',
    'Extra Day Charge',
    'Extra Drop',
    'Extra Miles',
    'Extra Pick',
    'Factoring Fee',
    'Flip Charge',
    'Fuel Surcharge',
    'Gate Fee',
    'GST',
    'Hazmat Fee',
    'IEL Lease Fee',
    'Late Truck',
    'Layover',
    'Ocean',
    'Other',
    'Overweight',
    'Pallets',
    'Port Congestion Fee',
    'Pre-Pull Fee',
    'Product - Damaged',
    'Product - Shortage',
    'Re-Consignment',
    'Re-Delivery',
    'Reefer Fee',
    'Split Chassis',
    'Storage Fee',
    'Tolls',
    'Transportation Services',
    'Truck Ordered Not used',
    'Truck Wash',
    'Unloading Fee'
];

export const loadPhases = ['Setup', 'Plan', 'Ship', 'Bill', 'Complete'];

export const claimTypes = [
    'Temperature',
    'Damage',
    'Trailer Damage',
    'Shortage',
    'Unauthorized Disposal',
    'Late / Loss of Sale',
    'Property Damage',
    'Accident',
    'Stolen'
];

export const carrierRelationsIssues = {
    'Broker Complaint': ['Poor Service', 'Detention/Layover', 'Carrier Fees', 'TONU'],
    'Carrier Complaint': ['Double Brokered', 'Poor Service', 'Hostage Load'],
    'Carrier Verification': [],
    'Check/Comcheck Cashed Status': ['Cashed', 'Not Cashed'],
    'Confirming PPW': ['NOA/LOR', 'Billing PPW', 'All PPW'],
    'Payment Discrepancy': [
        'Missing Receipt',
        'Carrier Fees',
        'Reimbursement Needed',
        'Not Short',
        'QP Fee',
        'Rate Based on Weight/Count',
        'Detention/Layover'
    ],
    'TriumphPay Audit Request': [],
    'Internal Transfer': ['Claims', 'IEL Other', 'Active Load', 'Customer Call'],
    'Issue Comcheck': [],
    'Late Payment': [
        'Acct. Note Delay',
        'TriumphPay Audit Issue',
        'Customer Issue',
        'Release to Billing',
        'Switched Load #',
        'Wrong Carrier Added',
        'Pay Terms Error',
        'Vendor Hold',
        'Claim'
    ],
    'Zeroed Out Load': ['Over One Year'],
    Misc: [],
    'Missing PPW': ['BOL', 'POD', 'Receipts', 'Invoice/Ratecon', 'All PPW', 'Originals Required'],
    'Payment Status': [
        'Paid',
        'Future',
        'Sent to TP',
        'Wrong carrier added',
        'Switched load #',
        'Double Brokered',
        'Load Hold',
        'Vendor Hold'
    ],
    'Process Overview': ['Billing Process', 'Triumph Pay'],
    'Quick Pay': ['Missing PPW', 'Late', 'Future'],
    'Rate Verification': [],
    'SLA Escalation': [],
    'Issue Ratecon': [],
    'TP Hold': [],
    'Update Paytruck': [],
    'Wrong Number': [],
    'Stop Payment': [],
    Request: []
};

export const carrierDocumentTypes = {
    'TI Agreement': 'TI Agreement',
    'Carrier Insurance': 'Carrier Insurance',
    'W8/W9': 'W8/W9',
    'Broker Carrier Agreement': 'Broker Carrier Agreement',
    'Notice of Assignment': 'Notice of Assignment',
    'Letter of Request': 'Letter of Request',
    'Customer Certification Holder': 'Customer Certification Holder'
};
