<template>
    <AppModal
        :id="context.modalId"
        :show="isOpen"
        :title="context.title"
        class="max-w-4xl"
        @close="close"
        @closed="onClosed">
        <form
            class="grid gap-2"
            @submit.prevent="submit">
            <div>
                <div class="mb-2 grid grid-cols-2 items-end gap-2 md:grid-cols-4">
                    <AppInput
                        id="carrier_contact_name"
                        v-model="form.carrier_contact_name"
                        name="carrier_contact_name"
                        label="Carrier Contact Name"
                        required />
                    <AppInput
                        v-model="form.carrier_contact_phone"
                        name="carrier_contact_phone"
                        label="Carrier Phone"
                        required />
                    <AppInput
                        v-model="form.carrier_email"
                        name="carrier_email"
                        label="Carrier Email"
                        required
                        type="email" />
                    <AppAutocomplete
                        v-model="loadAutocomplete"
                        name="po_number"
                        label="PO Number"
                        :options-url="route('loads.autocomplete')" />
                </div>
                <div class="grid grid-cols-2 items-end gap-2 md:grid-cols-5">
                    <AppInput
                        v-model="form.load_lane"
                        name="load_lane"
                        label="Load Lane"
                        required />
                    <AppInput
                        v-model="form.load_rate_amount"
                        name="load_rate"
                        label="Load Rate"
                        required
                        type="number"
                        :min="0"
                        step="0.01" />
                    <AppSelect
                        id="equipment-type"
                        v-model="form.equipment_type"
                        name="equipment_type"
                        label="Equipment Type"
                        :options="options"
                        required />
                    <AppInput
                        v-model="form.load_commodity"
                        name="load_commodity"
                        label="Load Commodity"
                        required />
                    <AppInput
                        v-model="form.load_value_amount"
                        name="load_value"
                        label="Load Value"
                        required
                        type="number"
                        :min="0"
                        step="0.01" />
                </div>
            </div>
            <div>
                <div class="container mx-auto flex">
                    <div class="flex-grow">
                        <AppTextarea
                            v-model="form.notes"
                            class="h-full"
                            label="Notes"
                            name="notes"
                            rows="2" />
                    </div>
                    <div class="flex items-end justify-end">
                        <AppButton
                            variant="outline"
                            class="ml-4 mr-4 justify-end gap-4 p-2"
                            @click="close">
                            {{ $t('Cancel') }}
                        </AppButton>
                        <AppButton
                            id="save-carrier-request-update"
                            class="justify-end gap-4 p-2"
                            type="submit">
                            {{ $t('Save') }}
                        </AppButton>
                    </div>
                </div>
            </div>
            <div v-if="form.hasErrors">
                <ul>
                    <li
                        v-for="(message, index) in form.errors"
                        :key="index"
                        class="text-iel-red">
                        {{ message }}
                    </li>
                </ul>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CarrierTicketModal');

let carrierId;

const loadAutocomplete = ref(null);

watch(loadAutocomplete, value => {
    form.po_number = value?.po_number;
    form.load_lane = value?.lane;
    form.load_rate_amount = value?.rate;
    form.load_commodity = value?.commodity;
    form.equipment_type = value?.equipment_type;
});

const form = useForm({
    type: null,
    model: 'carrier',
    po_number: null,
    carrier_contact_name: null,
    carrier_contact_phone: null,
    carrier_email: null,
    load_lane: null,
    load_rate_amount: null,
    load_rate_currency: 'USD',
    load_value_amount: null,
    load_value_currency: 'USD',
    equipment_type: null,
    load_commodity: null,
    notes: null
});

watch(isOpen, async value => {
    if (value) {
        await nextTick();
        const focusableElement = document.querySelector('#carrier_contact_name');
        if (focusableElement) focusableElement.focus();
    }
});

watch(isOpen, value => {
    form.reset();
    form.clearErrors();
    carrierId = context.value.carrier.id;
    form.type = context.value.ticketType;
    if (!value) return;
});

function submit() {
    form.post(
        route('carriers.carrierTicket.store', {
            carrier: carrierId
        }),
        {
            preserveScroll: true,
            onSuccess() {
                close();
            }
        }
    );
}

const options = {
    Conestoga: 'Conestoga',
    Drayage: 'Drayage',
    'Drop Trailer': 'Drop Trailer',
    'Dry Box Truck': 'Dry Box Truck',
    'Dry-Haz': 'Dry-Haz',
    'Dump Truck': 'Dump Truck',
    Flat: 'Flat',
    'Flatbed or Stepdeck': 'Flatbed or Stepdeck',
    'Flat Team': 'Flat Team',
    Hotshot: 'Hotshot',
    LTL: 'LTL',
    'Power Only': 'Power Only',
    'Pwr-Haz': 'Pwr-Haz',
    Rail: 'Rail',
    Reefer: 'Reefer',
    'Reefer Team': 'Reefer Team',
    'Ref-Haz': 'Ref-Haz',
    'Refrigerated Box Truck': 'Refrigerated Box Truck',
    RGN: 'RGN',
    SD: 'SD',
    Specialty: 'Specialty',
    'Sprinter Van': 'Sprinter Van',
    Van: 'Van',
    'Van or Flat': 'Van or Flat',
    'Van or Reefer': 'Van or Reefer',
    'Van Team': 'Van Team',
    'Vented Van': 'Vented Van'
};
</script>
