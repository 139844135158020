<template>
    <BillingErrors
        class="mt-2"
        :load="props.load" />
</template>

<script setup>
import BillingErrors from '@/Pages/Loads/partials/BillingErrors.vue';

const props = defineProps({
    load: Object
});
</script>
