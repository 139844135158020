<template>
    <div
        class="space-y-1"
        data-test="wrapper">
        <AppLabel v-bind="{ name: attributes.name, label: fieldLabel, required }" />
        <div
            class="flex"
            :class="inline ? 'gap-x-2' : 'flex-col'">
            <div
                v-for="option in options"
                :key="option.value"
                class="flex items-center gap-2">
                <input
                    v-bind="attributes"
                    :id="`${attributes.name}-${option.value}`"
                    v-model="value"
                    :value="option.value"
                    class="h-4 w-4 border-iel-light-gray bg-iel-silver text-iel-blue"
                    type="radio"
                    :disabled="unauthorized || disabled"
                    @change="handleChange" />
                <label
                    :for="`${attributes.name}-${option.value}`"
                    class="text-sm"
                    :class="{
                        'text-iel-dark-red': error,
                        'cursor-not-allowed !text-iel-gray': unauthorized || disabled
                    }"
                    :data-test="`${option.value}-label`">
                    {{ option.label }}
                </label>
                <AppRequestStatus
                    v-if="hasAutoSave && value === option.value"
                    v-bind="{ recentlySuccessful, processing, error }"
                    class="!relative" />
            </div>
        </div>
        <AppErrorMessage
            :name="attributes.name"
            :error="error" />
    </div>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: String,
    error: String,
    scope: [String, Number],
    required: Boolean,
    disabled: Boolean,
    url: String,
    confirmation: String,
    options: Object,
    inline: Boolean,
    permission: String
});

const emit = defineEmits(['change']);

const hasAutoSave = props.url !== undefined;

const form = inject('form', undefined);

const {
    value: autoSaveValue,
    submit,
    processing,
    recentlySuccessful,
    error: autoSaveError,
    clearError
} = useAutoSave(props, 'modelValue');

const definedModel = defineModel({ type: [String, Number, Boolean] });

const value = hasAutoSave ? autoSaveValue : definedModel;

const error = hasAutoSave ? autoSaveError : computed(() => props.error || form?.errors?.[props.name]);

const { label: fieldLabel, attributes } = useField(props, error);

const { can } = useAuth();

const unauthorized = computed(() => props.permission && !can(props.permission));

function handleChange() {
    if (hasAutoSave) {
        clearError();
        nextTick(() => submit());
    } else {
        emit('change');
        form?.clearErrors(props.name);
    }
}
</script>
