<template>
    <AppModal
        :show="isOpen"
        :description="context.description"
        class="max-w-6xl"
        @close="close"
        @closed="onClosed">
        <div class="flex items-center justify-between">
            <h1 class="text-xl font-bold text-iel-blue">
                {{ context.title ?? `${startCase(context.mode)} Checkcall` }}
            </h1>
            <AppButton
                v-if="context.task?.past_due || context.task?.due_soon"
                variant="invisible"
                size="md"
                @click="snoozeTask(context)">
                {{ $t('Snooze') }}
                <AppIcon
                    name="fas fa-alarm-snooze"
                    class="ml-2" />
            </AppButton>
        </div>
        <form @submit.prevent="save">
            <div class="grid gap-2 md:grid-cols-2">
                <div class="flex gap-2">
                    <AppSelect
                        v-if="form.event === 'En Route'"
                        v-model="form.stop_id"
                        name="stop_id"
                        label="Stop #"
                        :options="stops"
                        :required="form.event !== 'En Route'" />
                    <AppDisplayField
                        label="Event"
                        :value="form.event"
                        name="event"
                        class="grow" />
                </div>
                <AppSelect
                    v-model="form.reason"
                    name="reason"
                    :options="checkcallReasons"
                    required />
                <AppSelect
                    v-model="form.source"
                    name="source"
                    :options="checkcallSources"
                    required />
                <div class="grid grid-cols-[3fr,1fr] gap-2">
                    <AppDateTime
                        v-model:datetime="form.event_happened_at"
                        v-model:timezone="form.event_timezone"
                        :field-names="{ datetime: 'event_happened_at', timezone: 'event_timezone' }"
                        name="event_happened_at"
                        label="Event Actual Date/Time"
                        required />
                </div>
                <div class="grid grid-cols-[3fr,1fr,2fr] gap-2">
                    <AppInput
                        v-model="form.city"
                        name="city"
                        required
                        @blur="lookupAddress('city_region')" />
                    <AppInput
                        v-model="form.region"
                        name="region"
                        label="State"
                        required
                        @blur="lookupAddress('city_region')" />
                    <AppInput
                        v-model="form.postal_code"
                        name="postal_code"
                        label="ZIP"
                        required
                        @blur="lookupAddress('postal_code')" />
                    <AppErrorMessage
                        v-if="lookupError"
                        :error="lookupError" />
                </div>
                <AppInput
                    id="new-stop-temperature"
                    v-model="form.temperature"
                    name="new-stop-temperature"
                    label="Temperature"
                    class="w-1/2"
                    type="number" />
            </div>

            <div
                v-if="['arrived', 'completed'].includes(stopTaskType)"
                class="my-2 grid gap-4">
                <div class="my-4 grid gap-4 md:grid-cols-3">
                    <div class="text-sm">
                        <p class="text-iel-dark-gray">{{ $t('Location') }}</p>
                        <p>
                            <strong>{{ location.name }}</strong>
                        </p>
                        <p>
                            {{ location.address.address_1 }}
                            <span v-if="location.address.address_2">,</span>
                            {{ location.address.address_2 }}
                        </p>
                        <p>
                            {{ location.address.city }}, {{ location.address.region }},
                            {{ location.address.postal_code }}
                        </p>
                    </div>
                    <div class="text-sm">
                        <p class="text-iel-dark-gray">{{ $t('Phone') }}</p>
                        <p v-if="location.phone_number">
                            {{ location.phone_number }}
                        </p>
                        <p v-else>{{ $t('No phone number available.') }}</p>
                    </div>
                    <div class="text-sm">
                        <p class="text-iel-dark-gray">{{ $t('Stop Notes') }}</p>
                        <p v-if="context.stop.note?.content">
                            {{ context.stop.note?.content }}
                        </p>
                        <p v-else>{{ $t('No note for this stop.') }}</p>
                    </div>
                </div>
            </div>

            <!-- Line items -->
            <ul
                v-if="taskLineItemType?.length > 0"
                class="line-items col-span-full my-3 text-sm">
                <AppLabel
                    :label="stopTaskType === 'departed' && nextStop ? 'Next Stop\'s Line Items' : 'Line Items'"
                    name="line-items" />
                <li
                    v-for="(item, index) in taskLineItemType"
                    :key="index"
                    class="my-2 flex items-end gap-1">
                    <AppInput
                        v-model="item.shipment_reference_number"
                        :name="`load-checkcall-line-item-${index}-shipment-reference-number`"
                        label="Ref#"
                        :disabled="stopTaskType === 'departed'" />
                    <AppInput
                        v-model="item.shipment_po"
                        :name="`load-checkcall-line-item-${index}-shipment-po`"
                        label="PO"
                        :disabled="stopTaskType === 'departed'" />
                    <AppSelect
                        v-model="item.commodity"
                        :name="`load-checkcall-line-item-${index}-commodity`"
                        label="Commodity"
                        :options="commodityTypes"
                        :disabled="stopTaskType === 'departed'" />
                    <div class="flex items-end gap-1">
                        <AppInput
                            v-model="item.weight"
                            :name="`load-checkcall-line-item-${index}-weight`"
                            class="grow"
                            label="Weight"
                            :disabled="stopTaskType === 'departed'" />
                        <AppSelect
                            v-model="item.weight_unit"
                            :name="`load-checkcall-line-item-${index}-weight-units`"
                            label="Unit"
                            :options="weightUnits"
                            class="min-w-[theme(spacing.10)]"
                            :disabled="stopTaskType === 'departed'" />
                    </div>
                    <div class="flex items-end gap-1">
                        <AppInput
                            v-model="item.volume"
                            :name="`load-checkcall-line-item-${index}-volume`"
                            class="grow"
                            label="Volume"
                            :disabled="stopTaskType === 'departed'" />
                        <AppSelect
                            v-model="item.volume_unit"
                            :name="`load-checkcall-line-item-${index}-volume-units`"
                            label="Unit"
                            :options="volumeUnits"
                            class="min-w-[theme(spacing.10)]"
                            :disabled="stopTaskType === 'departed'" />
                    </div>
                    <div class="flex items-end gap-1">
                        <AppInput
                            v-model="item.pieces"
                            :name="`load-checkcall-line-item-${index}-pieces`"
                            class="grow"
                            label="Pieces"
                            :disabled="stopTaskType === 'departed'" />
                        <AppSelect
                            v-model="item.pieces_unit"
                            :name="`load-checkcall-line-item-${index}-piece-unit`"
                            label="Unit"
                            :options="pieceUnits"
                            class="min-w-[theme(spacing.10)]"
                            :disabled="stopTaskType === 'departed'" />
                    </div>
                    <AppTextarea
                        v-model="item.description"
                        :name="`load-checkcall-line-item-${index}-description`"
                        label="Description"
                        class="resize-none"
                        rows="1"
                        :disabled="stopTaskType === 'departed'" />
                    <AppButton
                        v-if="stopTaskType != 'departed'"
                        icon="far fa-trash-can"
                        variant="outline"
                        class="inline-block transition hover:bg-iel-light-gray"
                        @click="deleteLineItem(index)">
                        {{ $t('Delete') }}
                    </AppButton>
                </li>
            </ul>

            <AppButton
                v-if="stopTaskType != 'departed'"
                id="add-new-checkcall-line-item"
                icon="fal fa-pen"
                class="mt-4 w-fit transition hover:bg-white hover:text-iel-blue"
                @click="addLineItem">
                {{ $t('New Line Item') }}
            </AppButton>

            <div
                v-if="stopTaskType === 'departed' && nextStop"
                class="grid grid-cols-2 gap-2">
                <div class="space-y-1 text-sm">
                    <p class="text-iel-dark-gray">{{ $t('Next Stop') }}</p>
                    <p>
                        {{ nextLocation.name }}
                        <br />
                        {{ nextLocation.address.address_1 }}
                        <br />
                        {{ nextLocation.address.address_2 }}
                        <br />
                        {{ nextLocation.address.city }}, {{ nextLocation.address.region }}
                        {{ nextLocation.address.postal_code }}
                    </p>
                </div>
                <div class="space-y-1 text-sm">
                    <p class="text-iel-dark-gray">{{ $t('Miles to Next Stop') }}</p>
                    <p>{{ context.stop.miles_to_next_stop }}</p>
                </div>
            </div>
            <div class="mt-4 grid gap-4">
                <AppTextarea
                    v-model="form.internal_note"
                    name="internal_note"
                    required />
                <AppTextarea
                    v-model="form.external_note"
                    name="external_note" />
            </div>
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    v-if="context.mode === 'edit'"
                    variant="outline"
                    class="mr-auto border-iel-dark-red text-iel-dark-red"
                    @click="confirmDelete">
                    {{ $t('Delete') }}
                </AppButton>
                <AppButton
                    variant="outline"
                    @click="
                        () => {
                            close();
                            clearEmptyLineItem();
                        }
                    ">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Save') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { checkcallReasons, checkcallSources, commodityTypes } from '@/data';
import { router, useForm, usePage } from '@inertiajs/vue3';
import { startCase, values, zipObject, cloneDeep, pick } from 'lodash-es';
import { useI18n } from 'vue-i18n';
import axios from 'axios';

const { t } = useI18n();

// Pull in the current reactive state of the `checkcall` modal. `context` includes all the data that
// was passed into the `open` trigger of the modal, which we can use to determine where the modal
// was opened from and how we should initialize it.
const { isOpen, close, onClosed, context } = useModal('LoadCheckcallModal');

const page = usePage();

const stops = computed(() =>
    context.value?.load?.stops?.reduce((all, current) => ({ ...all, [current.id]: current.order }), {})
);
// arrived/completed/departed
const stopTaskType = computed(() => context.value.defaults?.task?.split('_')?.pop());
const nextStop = computed(() =>
    context.value?.load?.stops?.find(stop => stop.order === context.value?.stop?.order + 1)
);

// determine which line items to show. departed will display the next stop's line items if there is a next stop. if there is no next stop, no line items shown.
const taskLineItemType = computed(() => {
    if (stopTaskType.value === 'departed' && nextStop.value) {
        return nextStop.value.line_items;
    } else if (stopTaskType.value === 'arrived' || stopTaskType.value === 'completed') {
        return form.line_items;
    } else {
        return [];
    }
});

const weightUnits = computed(() => zipObject(values(page.props.units.weight), values(page.props.units.weight)));
const volumeUnits = computed(() => zipObject(values(page.props.units.volume), values(page.props.units.volume)));
const pieceUnits = computed(() => zipObject(values(page.props.units.pieces), values(page.props.units.pieces)));

const form = useForm({
    stop_id: null,
    task: null,
    event: null,
    reason: 'Normal',
    source: null,
    event_happened_at: null,
    event_timezone: null,
    city: null,
    region: null,
    postal_code: null,
    temperature: null,
    internal_note: null,
    external_note: null,
    line_items: []
});

const location = ref({
    name: '',
    phone_number: '',
    address: {
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        region: '',
        postal_code: ''
    }
});

const nextLocation = ref({
    name: '',
    phone_number: '',
    address: {
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        region: '',
        postal_code: ''
    }
});

provide('form', form);

async function fetchStopLocation(stop) {
    return (await axios.get(route('stops.location', stop))).data;
}

async function fetchStopLocations() {
    if (context.value.stop) {
        location.value = Object.assign(location.value, await fetchStopLocation(context.value.stop));
    }

    if (nextStop.value) {
        nextLocation.value = Object.assign(nextLocation.value, await fetchStopLocation(nextStop.value));
    }
}

watch(
    () => form.stop_id,
    newStopId => {
        const stop = getStopById(newStopId);

        if (!stop) return;

        if (stop.stop_window_begins_at_timezone) {
            form.event_timezone = stop.stop_window_begins_at_timezone;
        }

        if (stop.stop_window_ends_at_timezone) {
            form.event_timezone = stop.stop_window_ends_at_timezone;
        }

        if (stop.arrived_at_timezone) {
            form.event_timezone = stop.arrived_at_timezone;
        }
    },
    { immediate: true }
);

watch(isOpen, async value => {
    // Reset form and clear errors whenever modal is opened or closed
    form.reset();
    form.clearErrors();

    lookupError.value = null;

    if (value) {
        // Set default form values when modal is opened
        for (const field in context.value?.defaults ?? {}) {
            form[field] = context.value.defaults[field];
        }

        if (context.value.stop) {
            await fetchStopLocations();
            form.city = location.value.address.city;
            form.region = location.value.address.region;
            form.postal_code = location.value.address.postal_code;
            if (stop.arrived_at_timezone) {
                form.event_timezone = stop.arrived_at_timezone;
            }
        }

        if (context.value.mode !== 'edit' && context.value?.stop?.line_items) {
            form['line_items'] = cloneDeep(context.value?.stop?.line_items);
        }

        if (context.value.mode === 'edit') {
            form['line_items'] = context.value?.checkcall?.line_items;
        }
    }
});

function getStopById(id) {
    return context.value.load?.stops?.find(stop => stop.id === id);
}

function getLoad(response) {
    return route().current() === 'loads.index'
        ? page.props.loads.data.find(load => load.id === response.data.id)
        : page.props.load;
}

function updateLoadData(response) {
    const load = getLoad(response);

    if (!load) {
        return;
    }

    load.tasks = response.data.tasks;
    load.phase = response.data.phase;
    load.trackings = response.data.trackings;
}

async function save() {
    const response =
        context.value.mode === 'edit'
            ? await axios.put(
                  route('loads.checkcalls.update', [context.value.load, context.value.checkcall]),
                  form.data()
              )
            : await axios.post(route('loads.checkcalls.store', [context.value.load]), form.data());

    updateLoadData(response);
    router.reload({ only: ['load'] });
    close();
}

function clearEmptyLineItem() {
    router.reload({ only: ['load'] });
}

function confirmDelete() {
    if (confirm(t('Are you sure you would like to delete the checkcall?'))) {
        router.delete(
            route('loads.checkcalls.destroy', [
                context.value.load,
                context.value.checkcall,
                context.value.checkcall.line_items
            ]),
            {
                preserveScroll: true,
                onSuccess() {
                    close();
                }
            }
        );
    }
}

function addLineItem() {
    form.line_items.push({
        line_items: null
    });
}

function deleteLineItem(i) {
    if (confirm(t('Are you sure you would like to delete the line item?'))) {
        form.line_items.splice(i, 1);
    }
}

const { open: openSnoozeModal } = useModal('SnoozeModal');

function snoozeTask(task) {
    openSnoozeModal(
        {
            task
        },
        close()
    );
}

const lookupError = ref(null);

async function lookupAddress(mode = 'city_region') {
    lookupError.value = null;

    if (!['city_region', 'postal_code'].includes(mode)) throw new Error('Invalid lookup address mode');

    const params = mode === 'city_region' ? pick(form, ['city', 'region']) : pick(form, ['postal_code']);

    if ((!params.city || !params.region) && !params.postal_code) return;

    const { data } = await axios.get(route('addresses.lookup'), { params });

    if (data.error) {
        lookupError.value = data.error;
    }

    if (mode === 'city_region') {
        form.postal_code = data.postal_code;
    } else {
        form.city = data.city;
        form.region = data.region;
    }
}
</script>
