<template>
    <tr>
        <td class="py-2 sm:py-0">
            <span
                class="text-sm font-bold"
                data-test="uploaded-filename">
                {{ pendingFile.name }}
            </span>
            -
            <span
                class="text-sm"
                data-test="uploaded-filesize">
                {{ Math.round(pendingFile.size / 1000) + 'kb' }}
            </span>
        </td>
        <td>
            <AppSelect
                id="select-label-carrier-docs"
                v-model="form.labels"
                :placeholder="$t('Select Label')"
                name="labels"
                :options="documentTypes"
                class="my-2 w-56 sm:my-0 md:w-72 xl:w-40 2xl:w-56"
                required />
        </td>
        <td>
            <div class="my-2 flex space-x-2 sm:my-0">
                <AppButton
                    :id="`documents-list-${pendingFile.name}-actions-upload-document-button`"
                    icon="fal fa-file-arrow-up"
                    @click="store">
                    <span class="sr-only">Upload {{ pendingFile.name }}</span>
                </AppButton>
                <AppButton
                    :id="`documents-list-${pendingFile.name}-actions-remove-document-button`"
                    variant="outline"
                    icon="fal fa-trash-can"
                    @click="remove">
                    <span class="sr-only">Remove {{ pendingFile.name }}</span>
                </AppButton>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    carrier: Object,
    pendingFile: Object,
    documentTypes: Object
});
const emit = defineEmits(['remove']);

const form = useForm({
    document: props.pendingFile,
    labels: ''
});

provide('form', form);

function store() {
    form.post(route('carriers.documents.store', [props.carrier, props.pendingFile]), {
        preserveScroll: true,
        onSuccess() {
            remove();
        }
    });
}

function remove() {
    emit('remove');
}
</script>
