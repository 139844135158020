<template>
    <div
        class="min-h-screen bg-cover bg-center"
        :style="{ backgroundImage: 'url(/images/login-bg.jpg)' }">
        <div
            class="flex h-full min-h-screen w-full flex-col items-center justify-center gap-8 overflow-hidden bg-gradient-to-b from-white to-white/90 p-6 sm:max-w-lg sm:gap-12">
            <Link href="/">
                <img
                    src="/images/arrow-IEL.png"
                    class="w-full max-w-xs" />
            </Link>
            <div class="w-full max-w-xs">
                <slot />
            </div>
        </div>
    </div>
</template>
