<template>
    <tr class="flex items-center">
        <td
            :class="{
                'w-full': request.entity_type !== 'carrier',
                'w-1/5': request.entity_type === 'carrier'
            }">
            <span
                class="font-bold"
                :class="{
                    'text-sm': request.entity_type !== 'carrier',
                    'text-xs': request.entity_type === 'carrier'
                }"
                data-test="uploaded-filename">
                {{ pendingFile.name }}
            </span>
        </td>
        <td v-if="request.entity_type === 'carrier'">
            <AppSelect
                id="select-label-carrier-docs"
                v-model="form.labels"
                :placeholder="$t('Select Label')"
                name="labels"
                :options="carrierDocumentTypes"
                class="my-2 w-44"
                required />
        </td>
        <td v-else>
            <div class="my-2 flex space-x-2 sm:my-0">
                <AppButton
                    :id="`documents-list-${pendingFile.name}-actions-upload-document-button`"
                    icon="fal fa-file-arrow-up"
                    @click="store">
                    <span class="sr-only">Upload {{ pendingFile.name }}</span>
                </AppButton>
                <AppButton
                    :id="`documents-list-${pendingFile.name}-actions-remove-document-button`"
                    variant="outline"
                    icon="fal fa-trash-can"
                    @click="remove">
                    <span class="sr-only">Remove {{ pendingFile.name }}</span>
                </AppButton>
            </div>
        </td>
    </tr>
    <div
        v-if="request.entity_type === 'carrier'"
        class="my-2 flex space-x-2 sm:my-0">
        <AppButton
            :id="`documents-list-${pendingFile.name}-actions-upload-document-button`"
            icon="fal fa-file-arrow-up"
            @click="store">
            <span class="sr-only">Upload {{ pendingFile.name }}</span>
        </AppButton>
        <AppButton
            :id="`documents-list-${pendingFile.name}-actions-remove-document-button`"
            variant="outline"
            icon="fal fa-trash-can"
            @click="remove">
            <span class="sr-only">Remove {{ pendingFile.name }}</span>
        </AppButton>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { carrierDocumentTypes } from '@/data.js';

const props = defineProps({
    request: Object,
    pendingFile: Object
});

const emit = defineEmits(['remove']);

const form = useForm({
    document: props.pendingFile,
    labels: [],
    stops: []
});

const stops = ref([]);

provide('form', form);

function store() {
    form.stops = stops.value.map(stop => stop.value);

    if (props.request.entity_type === 'customer') {
        form.labels = ['Credit Application'];
    }

    let targetRoute = 'tickets.customer.documents.store';

    if (props.request.entity_type === 'carrier') {
        targetRoute = 'carriers.documents.store';
    }

    form.post(route(targetRoute, [props.request.entity.id, props.request.pendingFile]), {
        preserveScroll: true,
        onSuccess() {
            remove();
        }
    });
}

function remove() {
    emit('remove');
}
</script>
