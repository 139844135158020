<template>
    <FontAwesomeIcon
        :icon="name"
        aria-hidden="true"
        :class="{ 'cursor-not-allowed opacity-50': unauthorized }"
        :style="{ pointerEvents: unauthorized ? 'none' : 'auto' }" />
</template>

<script setup>
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, far, fal } from '@awesome.me/kit-ce82e5ccac/icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

library.add(fas, far, fal);

const props = defineProps({
    name: {
        type: String,
        required: false
    },
    permission: String
});

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));
</script>
