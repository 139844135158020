<template>
    <Head title="Loadboard" />
    <AppHeader
        :title="$t('Loadboard')"
        :icon-component="IconLoadboard">
        <template #search>
            <AppSearchInput
                id="loadboard-search"
                v-model="q"
                placeholder="Name, #, Location, PO"
                class="w-52" />
        </template>

        <template #actions>
            <LoadFilterDropdown
                class="max-w-48 2xl:hidden"
                :filters="filters"
                :link-params="{ only: ['loads', 'filter'] }"
                :root-url="'/loads'" />
            <AppFilterPills
                id="loadboard-filters"
                class="hidden gap-x-2 border-r border-iel-gray pr-3 2xl:flex"
                :filters="filters"
                :link-params="{ only: ['loads', 'filter'] }"
                :root-url="'/loads'" />
            <div>
                <AppButton
                    id="add-load-button"
                    icon="far fa-circle-plus"
                    permission="create_new_load"
                    @click="openLoadCreateModal">
                    {{ $t('New Load') }}
                </AppButton>
            </div>
            <LoadLayoutToggle />
        </template>
    </AppHeader>
    <slot />
</template>

<script setup>
import IconLoadboard from '/resources/img/icon-loadboard.svg';
import { allFilters } from '@/Composables/useFilters.js';

// Sync the `q` query parameter in the URL with the value of the search input, and
// reload the data in the 'loads' prop (but nothing else) when it changes
const { q } = useQueryParam({ only: ['loads', 'filter'] });

const { open: openLoadCreateModal } = useModal('LoadCreateModal');

const { filters } = allFilters();
</script>
