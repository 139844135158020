/**
 * Track all registered modals. This is not exported because it should only be manipulated by the `useModal()`
 * hook below, but it's outside the hook because it should be global - we want one reactive list of all the
 * modals in the app, regardless of how many times `useModal()` is called. This is basically a small store.
 */
const modals = reactive({});

/**
 * Control the state of a specific modal from anywhere in the app.
 */
export function useModal(id) {
    // Initialize the modal. The first time `useModal()` is called with a new ID, that ID will be added to our
    // `modals` store along with some metadata, like whether it's open. Calling `useModal()` again with the
    // same ID won't do anything, which makes it safe to call again with the same ID in many components.
    modals[id] ??= { open: false, context: {} };

    // Open the modal and provide its `context`, which can be anything it needs access to.
    function open(context = {}) {
        // Set the modal's context to whatever was passed in when it was opened. Initialize empty
        // open/close handlers so we can call them without checking if they exist first.
        modals[id].context = {
            onOpen: () => {},
            onClose: () => {},
            ...context
        };
        // Set the modal to be open.
        modals[id].open = true;
        // Call the modal's open handler.
        modals[id].context.onOpen();
    }

    // Close the modal.
    function close() {
        // Set the modal to be closed.
        modals[id].open = false;
        // Call the modal's close handler.
        modals[id].context.onClose();
    }

    // Handle the modal being completely closed, including all transitions being finished. We have
    // to wait for the transitions to finish so that users don't see a blank 'flash' of field
    // values disappearing immediately when a modal *starts* closing.
    function onClosed() {
        nextTick(() => (modals[id].context = {}));
    }

    // Whether the modal is open.
    const isOpen = computed(() => modals[id].open);
    // The modal's current context.
    const context = computed(() => modals[id].context);

    return {
        // The `open` function is mostly for the component triggering/initializing the modal.
        open,
        // These functions are mostly for the modal component itself.
        close,
        onClosed,
        isOpen,
        context
    };
}
