<template>
    <div class="flex gap-x-2">
        <Link
            v-for="({ label, color, icon }, key) in filters"
            :key="key"
            :href="updateUrlParam(key)"
            :data="{ [param]: key === 'default' ? undefined : key }"
            class="bg-iel flex items-center gap-2 rounded-full border border-iel-blue px-2 text-xs"
            :class="{ 'bg-iel-blue text-white': key === current || (key === 'default' && !current) }"
            v-bind="linkParams"
            :data-test="`link-${key}`"
            @click.prevent="setCurrent(key)">
            <AppIcon
                v-if="icon && icon !== 'warning'"
                :name="icon"
                class="my-0.5 h-4 w-4" />
            <AppWarningIcon
                v-else-if="icon === 'warning'"
                size="sm"
                class="my-0.5" />
            <div
                v-else-if="color"
                class="h-4 w-4 rounded-full border border-white"
                :class="color" />
            {{ label ?? startCase(key) }}
        </Link>
    </div>
</template>

<script setup>
import { startCase } from 'lodash-es';
import { usePage, Link } from '@inertiajs/vue3';

const props = defineProps({
    rootUrl: {
        type: String
    },
    filters: {
        type: Object,
        required: true
    },
    linkParams: {
        type: Object
    },
    resetPagination: {
        type: Boolean
    }
});

const param = 'filter';
const page = usePage();

const rootUrl = computed(() => props?.rootUrl ?? page.url);

const currentParam = ref(new URLSearchParams(location.search).get(param));

watchEffect(() => {
    currentParam.value = new URLSearchParams(location.search).get(param);
});

const current = computed(() => currentParam.value);

const setCurrent = key => {
    const baseUrl = new URL(rootUrl.value, location.origin);
    baseUrl.searchParams.set(param, key);
    history.pushState(null, '', baseUrl.toString());
    currentParam.value = key;
};

const updateUrlParam = key => {
    const baseUrl = new URL(rootUrl.value, location.origin);
    baseUrl.searchParams.set(param, key);
    if (props.resetPagination) baseUrl.searchParams.delete('page');
    return baseUrl.toString();
};
</script>
