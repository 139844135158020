<template>
    <table class="NX-mobile-stacked mr-4">
        <thead>
            <tr>
                <th class="border-b-2 md:w-48">{{ $t('Document Name') }}</th>
                <th class="w-56 space-y-1 border-b-2 md:w-72 xl:w-40 2xl:w-56">{{ $t('Document Label') }}</th>
                <th class="border-b-2">{{ $t('Actions') }}</th>
            </tr>
        </thead>
        <tbody>
            <slot></slot>
        </tbody>
    </table>
</template>
