<template>
    <AppGraphBar
        v-if="loaded"
        :title="'Billing'"
        :data="billingData" />
</template>

<script setup>
import axios from 'axios';

const loaded = ref(false);

const billingData = ref({
    labels: [
        ['Needs', 'Attention'],
        ['Needs', 'Paperwork'],
        ['Ready', 'to be', 'Invoiced'],
        ['Ready for', 'Carrier', 'Payment'],
        ['Collect', 'Customer', 'Payment']
    ],
    datasets: [
        {
            data: [],
            borderColor: ['#c21714', '#002a40', '#002a40', '#002a40', '#002a40'],
            backgroundColor: ['#c21714', '#002a40', '#002a40', '#002a40', '#002a40']
        }
    ]
});

const fetchBillingLoads = async () => {
    try {
        const response = await axios.get('/loads/billing');
        const data = response.data;

        billingData.value.datasets[0].data = [
            data.needs_attention,
            data.needs_paperwork,
            data.ready_for_invoicing,
            data.ready_for_carrier_payment,
            data.collect_customer_payment
        ];
        loaded.value = true;
    } catch (error) {
        console.error('Failed to fetch billing loads data:', error);
    }
};

onMounted(() => {
    fetchBillingLoads();
});
</script>

<style scoped>
canvas {
    @apply !h-72 w-auto;
}
</style>
