<template>
    <GuestLayout>
        <Head title="Reset Password" />

        <form
            class="grid gap-4"
            @submit.prevent="submit">
            <AppInput
                id="email"
                v-model="form.email"
                name="email"
                type="email"
                required
                label="Email"
                placeholder="Email"
                :error="form.errors.email" />
            <AppInput
                id="password"
                v-model="form.password"
                name="password"
                type="password"
                required
                label="Password"
                placeholder="Password"
                :error="form.errors.password" />
            <AppInput
                id="password_confirmation"
                v-model="form.password_confirmation"
                name="password"
                type="password"
                required
                label="Confirm Password"
                placeholder="Password"
                :error="form.errors.password_confirmation" />
            <AppButton
                type="submit"
                block
                :disabled="form.processing">
                Reset Password
            </AppButton>
        </form>
    </GuestLayout>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    email: {
        type: String,
        required: true
    },
    token: {
        type: String,
        required: true
    }
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: ''
});

function submit() {
    form.post(route('password.store'), {
        onFinish: () => form.reset('password', 'password_confirmation')
    });
}
</script>
