<template>
    <div>
        <ul
            v-if="page.props.load.messages.length > 0"
            role="list"
            class="my-4 max-h-96 space-y-2 overflow-y-auto px-1"
            tabindex="0">
            <li
                v-for="message in messages"
                :key="message.id">
                <AppMessage
                    :is-text="true"
                    :load="page.props.load"
                    :active-tab="activeTab"
                    icon="fal fa-sms"
                    :message="message"
                    :created-at="message.created_at"
                    size="md" />
            </li>
        </ul>
        <AppButton
            icon="fal fa-sms"
            class="my-2"
            :disabled="loadDisabled"
            @click="showingCreateMessageForm = true">
            {{ $t('Send Text') }}
        </AppButton>
        <LoadDetailMessageForm
            v-if="showingCreateMessageForm"
            :active-tab="activeTab"
            @success="onMessageCreated" />
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';

const page = usePage();

const props = defineProps({
    activeTab: String
});

const messages = computed(() =>
    page.props.load.messages.filter(message => {
        if (props.activeTab === 'Dispatcher') {
            return message.to === page.props.load.dispatcher.phone_number;
        }
        if (props.activeTab === 'Driver') {
            return message.to === page.props.load.driver.phone_number;
        }
        if (props.activeTab === 'Driver 2') {
            return message.to === page.props.load.driver2.phone_number;
        }
    })
);

defineEmits(['success']);

const showingCreateMessageForm = ref(false);

provide('showingCreateMessageForm', showingCreateMessageForm);

function onMessageCreated() {
    showingCreateMessageForm.value = false;
}

defineExpose({ showingCreateMessageForm });

const loadDisabled = useLoadDisabled(page.props.load);
</script>
