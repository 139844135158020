<template>
    <AppGraphBar
        v-if="loaded"
        :title="'In Transit'"
        :data="transitData"
        :index-axis="'y'" />
</template>

<script setup>
import axios from 'axios';

const loaded = ref(false);

const transitData = ref({
    labels: ['Needs Carrier', 'Needs RateCon', 'Needs Dispatch', 'On Time', 'Behind'],
    datasets: [
        {
            data: [],
            borderColor: ['#f0a806', '#f0a806', '#f0a806', '#3bb273', '#c21714'],
            backgroundColor: ['#f0a806', '#f0a806', '#f0a806', '#3bb273', '#c21714']
        }
    ]
});

const fetchTransitLoads = async () => {
    try {
        const response = await axios.get('/loads/transit');
        const data = response.data;

        transitData.value.datasets[0].data = [
            data.needs_carrier,
            data.needs_ratecon,
            data.needs_dispatch,
            data.on_time,
            data.behind
        ];
        loaded.value = true;
    } catch (error) {
        console.error('Failed to fetch transit loads data:', error);
    }
};

onMounted(() => {
    fetchTransitLoads();
});
</script>
