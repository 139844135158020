<template>
    <Head title="Roles" />
    <AppHeader
        title="Roles"
        :icon-component="IconRoles">
        <template #search>
            <AppSearchInput
                id="roles-list-search"
                v-model="q" />
        </template>
        <template #actions>
            <div>
                <AppButton
                    id="add-role-button"
                    icon="far fa-circle-plus"
                    @click="createRole">
                    {{ $t('Add Role') }}
                </AppButton>
            </div>
        </template>
    </AppHeader>
    <slot />
</template>

<script setup>
import IconRoles from '/resources/img/icon-roles.svg';

const { open: roleModal } = useModal('RoleModal');

function createRole() {
    roleModal({
        mode: 'create',
        onClose: () => {}
    });
}

const { q } = useQueryParam({ only: ['roles'] });
</script>
