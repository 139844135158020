<template>
    <form
        class="mb-4 rounded-md border bg-white p-2 @container"
        @submit.prevent="createNote">
        <p class="text-sm md:text-base">{{ $t('New Note') }}</p>
        <AppTextarea
            v-model="form.content"
            name="content"
            label=""
            required />
        <div class="my-2 flex w-full gap-x-2">
            <AppButton
                id="load-create-note-add-button"
                type="submit"
                class="self-start"
                :disabled="form.processing">
                {{ $t('Add') }}
            </AppButton>
            <AppButton
                id="load-create-note-cancel-button"
                type="submit"
                class="self-start"
                variant="outline"
                @click.prevent="cancelNote">
                {{ $t('Cancel') }}
            </AppButton>
        </div>
    </form>
</template>

<script setup>
import { router, useForm, usePage } from '@inertiajs/vue3';

const emit = defineEmits(['success']);

const noteForm = inject('showingCreateNoteForm');
const page = usePage();

const form = useForm({
    content: '',
    noteable_type: 'load',
    noteable_id: page.props.load.id
});

provide('form', form);

function createNote() {
    form.post(route('loads.notes.store', page.props.load), {
        preserveScroll: true,
        onSuccess: () => {
            emit('success');
            // TODO change reload call to `only: ['notes']` if notes are exposed as their own key in the response
            router.reload({ only: ['load'] });
        }
    });
}

function cancelNote() {
    noteForm.value = false;
}
</script>
