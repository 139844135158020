import { find } from 'lodash-es';

import { useI18n } from 'vue-i18n';

export function useRateCon(load) {
    const { t } = useI18n();

    const confirmChangeMessage = t('Changing this will void this load’s RateCon. Are you sure?');

    const isSent = computed(() => {
        if (!load?.value) return;
        return Boolean(find(load.value.tasks, { name: 'send_carrier_rate_con', is_complete: true }));
    });

    const confirmChange = computed(() => (isSent.value ? confirmChangeMessage : null));

    return { confirmChangeMessage, isSent, confirmChange };
}
