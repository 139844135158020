<template>
    <AppCard
        id="customer-credit-card"
        title="Credit"
        theme="light_gray"
        content-class="grid w-full gap-6 gap-x-12 md:grid-flow-col md:grid-rows-2 md:grid-cols-[3fr_1fr]">
        <div class="grid grid-cols-2 items-center gap-6">
            <div class="relative">
                <CustomerGraphCreditAmounts :available-credit="availableCredit" />
            </div>
            <table class="max-w-72">
                <thead>
                    <tr>
                        <th colspan="2">Credit Info</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <a
                                :href="
                                    route('loads.index', {
                                        customer_id: `${customer.id}`,
                                        billing_filter: 'billed'
                                    })
                                "
                                target="_blank">
                                <AppIcon
                                    name="fas fa-circle"
                                    class="pr-1 text-iel-red" />
                                Billed Amount
                            </a>
                        </td>
                        <td class="text-right text-iel-gray">
                            {{ customer.billed_ar.formatted }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a
                                :href="
                                    route('loads.index', {
                                        customer_id: `${customer.id}`,
                                        billing_filter: 'unbilled'
                                    })
                                "
                                target="_blank">
                                <AppIcon
                                    name="fas fa-circle"
                                    class="pr-1 text-iel-blue" />
                                Unbilled Amount
                            </a>
                        </td>
                        <td class="text-right text-iel-gray">
                            {{ customer.unbilled_ar.formatted }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <AppIcon
                                name="fas fa-circle"
                                class="pr-1 text-iel-green" />
                            Available Credit
                        </td>
                        <td class="text-right text-iel-gray">
                            {{ availableCredit.formatted }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="grid grid-cols-2 items-center gap-6">
            <div class="relative">
                <CustomerGraphDaysToPay
                    :dtp-ranges="dtpRanges"
                    :dtp-counts="dtpCounts"
                    :total-sum="mean(daysToPay)" />
            </div>
            <table class="max-w-72">
                <thead>
                    <tr>
                        <th colspan="2">Average DTP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(range, index) in dtpRanges"
                        :key="index">
                        <td>
                            <a
                                :href="
                                    route('loads.index', {
                                        customer_id: `${range.customerId}`,
                                        billing_filter: `${range.filter}`
                                    })
                                "
                                target="_blank">
                                <AppIcon
                                    name="fas fa-circle"
                                    :class="`pr-1 ${range.color}`" />
                                {{ range.label }}
                            </a>
                        </td>
                        <td class="text-right text-iel-gray">
                            {{ dtpCounts[index] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <form class="grid content-start gap-4">
            <AppInput
                v-model="customer.first_pickup_date"
                name="customer_since"
                label="Customer Since"
                disabled />
            <AppInput
                name="credit_limit"
                label="Credit Limit"
                type="number"
                step=".01"
                :model-value="form.credit_limit"
                permission="update_customer_credit"
                :url="url"
                :min="0"
                @blur="updateAvailableCredit($event)" />
        </form>
        <div class="self-end">
            <div
                v-if="customer.is_active"
                class="grid pt-2">
                <AppButton
                    id="activate-button"
                    icon="far fa-truck"
                    :disabled="customer.has_open_ticket"
                    @click="openCustomerCreditIncreaseModal(customer)">
                    {{ $t('Request Credit') }}
                </AppButton>
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import { mean } from 'lodash-es';

const page = usePage();

const customer = page.props.customer;

const availableCredit = reactive({
    amount: parseFloat(customer.credit_limit).toFixed(2),
    formatted: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        parseFloat(customer.credit_limit).toFixed(2)
    )
});

const updateAvailableCredit = newValue => {
    const maxLimit = 99999999.99;

    if (newValue > maxLimit) {
        alert('Any amount higher than 99999999.99 is not allowed.');
        return;
    }

    const newAmount =
        newValue - parseFloat(customer.billed_ar.amount) / 100 - parseFloat(customer.unbilled_ar.amount) / 100;
    availableCredit.amount = newAmount;
    availableCredit.formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        newAmount
    );
};

updateAvailableCredit(parseFloat(customer.credit_limit).toFixed(2));

const form = useForm({
    credit_limit: parseFloat(customer.credit_limit).toFixed(2)
});

provide('form', form);

const url = route('customers.credit.update', page.props.customer);

const dtpRanges = [
    { color: 'text-iel-blue', label: '31-60', min: 31, max: 60, filter: '31-to-60', customerId: customer.id },
    {
        color: 'text-iel-dark-yellow',
        label: '61-90',
        min: 61,
        max: 90,
        filter: '61-to-90',
        customerId: customer.id
    },
    { color: 'text-iel-red', label: '91+', min: 91, filter: 'overNinety', customerId: customer.id }
];

const daysToPay = Object.entries(customer.days_to_pay).map(([, value]) => {
    return value;
});

const dtpCounts = dtpRanges.map(range => {
    return daysToPay.filter(num => {
        if (range.max) {
            return num >= range.min && num <= range.max;
        }

        return num >= range.min;
    }).length;
});

const { open: CustomerCreditRequestModal } = useModal('CustomerCreditRequestModal');

const openCustomerCreditIncreaseModal = customer => {
    CustomerCreditRequestModal({
        modalId: 'customer-request-credit-modal',
        title: 'Request Credit Increase',
        ticketType: 'Credit Increase',
        customer: customer
    });
};
</script>

<style scoped>
table tr {
    @apply h-8;
}
</style>
