<template>
    <div
        v-if="meta.total"
        class="my-2 flex items-center gap-2 lg:my-0">
        <div class="text-sm text-iel-dark-gray">
            {{ $t('Showing records') }} {{ meta.from }} {{ $t('to') }} {{ meta.to }} {{ $t('of') }}
            {{ meta.total }} &middot;
        </div>
        <AppDropdown align="top-right">
            <template #trigger>
                <button
                    id="pagination-user-dropdown-trigger"
                    class="flex items-center"
                    type="button">
                    <div class="text-sm font-semibold">{{ meta.per_page }} {{ $t('rows per page') }}</div>
                    <AppIcon
                        name="far fa-chevron-down"
                        class="ml-2 h-4 w-4" />
                </button>
            </template>
            <template #content>
                <AppDropdownLink
                    v-for="option in perPageOptions"
                    :key="option.id"
                    :href="option.href"
                    :data-active="meta.per_page === option.id ? 1 : null"
                    :class="{ 'bg-iel-silver': meta.per_page === option.id }">
                    {{ option.label }} {{ $t('per page') }}
                </AppDropdownLink>
            </template>
        </AppDropdown>
    </div>
</template>

<script setup>
defineProps({
    meta: {
        type: Object
    },
    externalLinks: Boolean
});

const perPageOptions = computed(() => {
    return [5, 10, 25, 50].map(option => {
        const params = new URLSearchParams(window.location.search);
        params.delete('page');
        params.set('perPage', option);
        return {
            id: option,
            label: option,
            href: `${window.location.pathname}?${params.toString()}`
        };
    });
});
</script>
