<template>
    <LoadNotes
        class="mt-2"
        :load="props.load" />
    <div class="flex justify-end pr-1">
        <AppButton
            v-if="props.load.notes.length > 0"
            :href="route('loads.show', props.load) + '#load-LoadNotes-section-button'"
            variant="outline"
            icon="far fa-arrow-right-long"
            trailing>
            {{ $t('All Notes') }}
        </AppButton>
    </div>
</template>

<script setup>
const props = defineProps({
    load: Object
});
</script>
