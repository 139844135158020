<template>
    <Head :title="location.name" />
    <AppHeader
        title="Locations"
        :icon-component="IconLocations" />
    <AppPanels
        id="location-show-toggle"
        :title="location.name"
        :sections="{ info: 'Info', details: 'Details', loadHistory: 'Load History' }">
        <template #nav>
            <div class="flex flex-col items-center gap-4 md:flex-row md:gap-8">
                <div class="flex items-center gap-2">
                    <span class="h-5 w-5 rounded-full border border-iel-lightest-gray bg-iel-light-green"></span>
                    <span class="text-sm uppercase text-white">{{ $t('Active') }}</span>
                </div>
                <div class="flex items-center gap-2">
                    <button
                        class="rounded px-1.5 leading-snug text-white"
                        :class="view === 'ship' ? 'bg-iel-blue' : 'border-iel-blue/25 bg-iel-blue/25'"
                        @click="view = 'ship'">
                        {{ $t('Ship') }}
                    </button>
                    <button
                        class="rounded px-1.5 leading-snug text-white"
                        :class="view === 'receive' ? 'bg-iel-blue' : 'border-iel-blue/25 bg-iel-blue/25'"
                        @click="view = 'receive'">
                        {{ $t('Receive') }}
                    </button>
                </div>
            </div>
        </template>
        <template #info>
            <LocationInfoCard />
        </template>
        <template #details>
            <LocationDetailsCard :detail="detail" />
        </template>
        <template #loadHistory>
            <LocationLoadHistoryCard :detail="detail" />
        </template>
    </AppPanels>
</template>

<script setup>
import IconLocations from '/resources/img/icon-locations.svg';

const props = defineProps({
    location: Object,
    shipping_details: Object,
    receiving_details: Object
});

// 'ship' or 'receive'
const view = ref('ship');
// current location detail based on ship/receive toggle
const detail = computed(() => (view.value === 'ship' ? props.shipping_details : props.receiving_details));
</script>
