<template>
    <div class="flex flex-col gap-2">
        <Draggable
            :id="`load-${load.id}-stops`"
            :list="load.stops"
            item-key="id"
            tag="div"
            handle=".handle"
            :disabled="loadDisabled || unauthorized"
            class="space-y-2"
            ghost-class="pending-drop-target"
            @change="drop">
            <template #item="{ element: stop }">
                <details
                    :id="`load-stop-${stop.id}`"
                    class="rounded-md border-2"
                    :class="{
                        'border-iel-red': !stop.location.address.validated_at,
                        'not-draggable': loadDisabled || unauthorized
                    }"
                    :open="!initialStopIds.includes(stop.id)">
                    <LoadDetailStopsStop :stop="stop" />
                </details>
            </template>
        </Draggable>
        <LoadDetailCreateStopForm
            v-if="showingCreateStopForm"
            @success="onStopCreated" />
        <AppButton
            id="load-create-stop-button"
            class="self-start"
            :permission="permission"
            :disabled="loadDisabled || unauthorized"
            @click="showCreateStopForm">
            {{ $t('Create Stop') }}
        </AppButton>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeMount, provide } from 'vue';
import { router, usePage } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';
import Draggable from 'vuedraggable';

const props = defineProps({
    permission: String
});

const page = usePage();

const showingCreateStopForm = ref(false);

const initialStopIds = ref([]);

const load = computed(() => page.props.load);

const ratecon = useRateCon(load);

provide('showingCreateStopForm', showingCreateStopForm);

onBeforeMount(() => (initialStopIds.value = page.props.load.stops.map(({ id }) => id)));

function drop({ moved }) {
    if (ratecon.isSent.value && !confirm(ratecon.confirmChangeMessage)) {
        return;
    }

    router.put(
        route('loads.stops.reorder', [page.props.load, moved.element.id]),
        {
            toOrderNumber: moved.newIndex + 1 // Order numbers are 1-based
        },
        {
            preserveScroll: true,
            // TODO change reload call to `only: ['stops']` if stops are exposed as their own key in the response
            only: ['load']
        }
    );
}

function onStopCreated() {
    showingCreateStopForm.value = false;
}

function showCreateStopForm() {
    if (ratecon.isSent.value && !confirm(ratecon.confirmChangeMessage)) {
        return;
    }

    showingCreateStopForm.value = true;
}

const loadDisabled = useLoadDisabled(page.props.load);

// Allows dragging ability of this component to be blocked w permission prop
const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));
</script>

<style scoped>
.pending-drop-target {
    @apply border-[1.5px] border-dashed border-iel-gray;
}
.pending-drop-target > * {
    @apply opacity-0;
}

.not-draggable {
    @apply cursor-no-drop;
}
</style>
