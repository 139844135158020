<template>
    <AppModal
        id="role-users-modal"
        :show="isOpen"
        title="Add User to Role"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form @submit.prevent="handleSubmit">
            <AppAutocomplete
                v-model="user"
                name="user"
                :label="$t('User')"
                :options-url="route('users.autocomplete')"
                :placeholder="$t('Search for an user...')" />
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">
                    {{ $t('Add') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
const user = ref(null);

const { isOpen, close, onClosed, context } = useModal('RoleUsersAddModal');

watch(isOpen, () => {
    user.value = null;
});

function handleSubmit() {
    router.post(
        route('roles.users.store', context.value.role.id),
        {
            users: [user.value]
        },
        {
            preserveScroll: true,
            onSuccess: () => {
                close();
            }
        }
    );
}
</script>
