<template>
    <GuestLayout>
        <Head title="Confirm Password" />

        <p class="mb-4 text-sm text-iel-dark-gray">
            This is a secure area of the application. Please confirm your password before continuing.
        </p>

        <form
            class="grid gap-4"
            @submit.prevent="submit">
            <AppInput
                id="password"
                v-model="form.password"
                name="password"
                type="password"
                required
                label="Password"
                placeholder="Password"
                :error="form.errors.password" />
            <AppButton
                type="submit"
                class="py-2"
                block
                :disabled="form.processing">
                Confirm
            </AppButton>
        </form>
    </GuestLayout>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const form = useForm({
    password: ''
});

function submit() {
    form.post(route('password.confirm'), {
        onFinish: () => form.reset()
    });
}
</script>
