<template>
    <AppModal
        :show="isOpen"
        :title="modalTitle"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="onConfirm">
            <AppInput
                v-model="form.name"
                name="name"
                required />
            <AppTextarea
                v-model="form.description"
                name="description"
                required />
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    type="submit"
                    :disabled="!form.name || !form.description">
                    {{ context.mode === 'edit' ? $t('Update') : $t('Create') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('RoleModal');

const form = useForm({
    name: null,
    description: null,
    permissions: []
});

const modalTitle = computed(() => {
    switch (context.value.mode) {
        case 'edit':
            return 'Update Role';
        case 'copy':
            return 'Copy Role';
        case 'create':
        default:
            return 'Create Role';
    }
});

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();

    if (context.value.mode === 'edit') {
        form.name = context.value.role.name;
        form.description = context.value.role.description;
    }

    if (context.value.mode === 'create') {
        form.name = '';
        form.description = '';
    }

    if (context.value.mode === 'copy') {
        form.name = context.value.role.name;
        form.description = context.value.role.description;
        form.permissions = context.value.role.permissions || [];
    }
});

function onConfirm() {
    const payload = {
        name: form.name,
        description: form.description,
        permissions: form.permissions
    };

    if (context.value.mode === 'create') {
        form.post(route('roles.store'), {
            preserveScroll: true,
            data: payload,
            onSuccess() {
                close();
            }
        });
    }

    if (context.value.mode === 'copy') {
        form.post(route('roles.store'), {
            preserveScroll: true,
            data: payload,
            onSuccess() {
                close();
            }
        });
    }

    if (context.value.mode === 'edit') {
        form.put(route('roles.update', context.value.role.id), {
            preserveScroll: true,
            data: payload,
            onSuccess() {
                close();
            }
        });
    }
}
</script>
