<template>
    <div>
        <AppHeading
            size="sm"
            class="pb-2 text-iel-gray">
            {{ $t('Documents') }}
        </AppHeading>
        <div class="mb-6 flex flex-col gap-4">
            <RequestsDocsList
                v-if="request.entity.documents?.length > 0"
                :request="request">
                <RequestsDocsItem
                    v-for="document in request.entity.documents"
                    :key="document.id"
                    :request="request"
                    :document="document"
                    :edit-permission="permissions.edit"
                    :delete-permission="permissions.delete"
                    @edit="success"
                    @remove="success" />
            </RequestsDocsList>
            <div
                v-else
                class="text-sm">
                {{ $t('No documents found on this load.') }}
            </div>
            <AppDocsDragDrop
                id="customer-activation-document"
                v-model="pending"
                class="mt-2"
                :permission="permissions.upload"
                :accepted-file-types="['application/pdf', 'image/jpeg', 'image/png']" />
            <div
                v-if="pending.length > 0"
                class="flex flex-col">
                <h2 class="text-sm font-bold">Pending Uploads</h2>
                <RequestsDocsList :request="request">
                    <RequestsDocsPendingItem
                        v-for="file in pending"
                        :key="file.name"
                        :request="request"
                        :pending-file="file"
                        @remove="removePending(index)" />
                </RequestsDocsList>
            </div>
        </div>
    </div>
</template>

<script setup>
defineProps({
    request: Object,
    permissions: {
        type: Object,
        default: () => ({})
    }
});

const pending = ref([]);
const emit = defineEmits(['success']);

function success() {
    emit('success');
}

function removePending(i) {
    pending.value.splice(i, 1);
    success();
}
</script>
