<template>
    <AppModal
        :id="context.modalId"
        :show="isOpen"
        :title="context.title"
        class="max-w-sm"
        @close="close"
        @closed="onClosed">
        <form
            class="gap-2"
            @submit.prevent="submit">
            <div>
                <div class="items-end gap-2">
                    <AppInput
                        v-model="form.increase_amount"
                        name="increase_amount"
                        label="Increase Requested Amount"
                        required
                        type="number"
                        :min="0"
                        step="0.01" />
                    <AppTextarea
                        v-model="form.updates"
                        class="h-full pt-2"
                        label="Update on Invoices Over 30 Days"
                        name="notes"
                        rows="2"
                        required />
                </div>
            </div>

            <div class="flex items-end justify-end pt-4">
                <AppButton
                    variant="outline"
                    class="ml-4 mr-4 justify-end gap-4 p-2"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="save-customer-request-update"
                    class="justify-end gap-4 p-2"
                    type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
            <div v-if="form.hasErrors">
                <ul>
                    <li
                        v-for="(message, index) in form.errors"
                        :key="index"
                        class="text-iel-red">
                        {{ message }}
                    </li>
                </ul>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CustomerCreditRequestModal');

let customerId;

const form = useForm({
    type: 'Credit Increase',
    increase_currency: 'USD',
    increase_amount: null,
    updates: null
});

watch(isOpen, value => {
    form.reset();
    form.clearErrors();
    customerId = context.value.customer.id;
    if (!value) return;
});

function submit() {
    form.post(
        route('customers.customerTicket.store', {
            customer: customerId
        }),
        {
            preserveScroll: true,
            onSuccess() {
                close();
            }
        }
    );
}
</script>
