<template>
    <AppModal
        id="send-dispatch-sheet-modal"
        class="lg:w-[8.5in] lg:max-w-none"
        :show="isOpen"
        title="Send Dispatch Sheet"
        @close="close"
        @closed="onClosed">
        <form
            id="send-dispatch-sheet-form"
            class="contents"
            @submit.prevent="handleSubmit">
            <iframe
                v-if="context.load"
                id="send-dispatch-sheet-iframe"
                class="h-[60vh] lg:h-[80vh]"
                :src="`/loads/${context.load.id}/dispatch-sheet`" />
            <AppInput
                id="send-dispatch-sheet-email"
                v-model="form.email"
                name="email"
                label="Email address"
                required />
            <div
                id="send-dispatch-sheet-buttons"
                class="flex justify-end gap-4 pt-2">
                <AppButton
                    id="send-dispatch-sheet-btn-cancel"
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="send-dispatch-sheet-btn-submit"
                    type="submit">
                    {{ $t('Send') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('LoadDispatchSheetSendModal');

const form = useForm({
    email: ''
});

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();
});

function handleSubmit() {
    form.post(route('loads.dispatchSheet.send', context.value.load), {
        preserveScroll: true,
        onSuccess() {
            close();
        }
    });
}
</script>
