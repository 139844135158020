<template>
    <div class="grid gap-2">
        <details
            v-for="(permissions, key) in modules"
            :key="key"
            open>
            <summary class="flex cursor-pointer items-center justify-between rounded bg-iel-light-gray p-2">
                <span class="font-medium">
                    {{ key }}
                </span>
                <AppIcon
                    name="far fa-caret-up"
                    class="transition" />
            </summary>
            <div class="grid gap-2 pt-2">
                <label
                    v-for="permission in permissions"
                    :key="permission.name"
                    class="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        :checked="roleHasPermission(permission)"
                        :disabled="loading[permission.name]"
                        class="h-4 w-4 rounded border-iel-gray text-iel-blue"
                        @click="togglePermission(permission)" />

                    <label class="ml-2">
                        {{ permission.label }}
                    </label>
                    <AppRequestStatus
                        :recently-successful="isPermissionRecentlySuccessful(permission)"
                        :processing="loading[permission.name]"
                        :error="hasPermissionError(permission)"
                        :floating="false" />
                </label>
            </div>
        </details>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';

const props = defineProps({
    modules: Object,
    role: Object
});

const permissionStatus = ref({});
const loading = ref({});

function roleHasPermission(permission) {
    return props.role.permissions.filter(p => p.id === permission.id).length > 0;
}

function togglePermission(permission) {
    delete permissionStatus.value[permission.name];
    loading.value[permission.name] = true;

    if (roleHasPermission(permission)) {
        router.delete(route('roles.permissions.destroy', props.role.id), {
            data: { permission: permission.id },
            preserveScroll: true,
            onSuccess: () => {
                loading.value[permission.name] = false;
                setPermissionStatus(permission, 'successful');
            },
            onError: () => {
                loading.value[permission.name] = false;
                setPermissionStatus(permission, 'error');
            }
        });
    } else {
        router.post(
            route('roles.permissions.store', props.role.id),
            {
                permission: permission.id
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    loading.value[permission.name] = false;
                    setPermissionStatus(permission, 'successful');
                },
                onError: () => {
                    loading.value[permission.name] = false;
                    setPermissionStatus(permission, 'error');
                }
            }
        );
    }
}

function setPermissionStatus(permission, status) {
    permissionStatus.value[permission.name] = status;

    setTimeout(() => {
        delete permissionStatus.value[permission.name];
    }, 2000);
}

function isPermissionRecentlySuccessful(permission) {
    return permissionStatus.value[permission.name] === 'successful';
}

function hasPermissionError(permission) {
    return permissionStatus.value[permission.name] === 'error';
}
</script>
