<template>
    <div class="grid gap-2">
        <div>
            <div class="flex flex-col gap-1 xl:col-span-2">
                <table
                    v-if="object.documents.length > 0"
                    class="NX-mobile-stacked">
                    <thead>
                        <tr>
                            <th class="border-b-2">{{ $t('Document Name') }}</th>
                            <th class="border-b-2">{{ $t('Label') }}</th>
                            <th class="border-b-2">{{ $t('Stops') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <LoadCardDocsList
                            v-for="document in object.documents"
                            :key="document.id"
                            :document="document"
                            :object="object"
                            :object-name="objectName"
                            :stops-options="stopsOptions"
                            :download-route-name="downloadRouteName"
                            :show-route-name="showRouteName"
                            :update-route-name="updateRouteName"
                            :document-type-options="documentTypeOptions"
                            :destroy-route-name="destroyRouteName"
                            :multiple="multiple" />
                    </tbody>
                </table>
                <div
                    v-else
                    class="text-sm">
                    {{ $t(`No documents found on this ${props.objectName}.`) }}
                </div>
            </div>
        </div>
        <form
            class="flex w-full flex-col items-center justify-center gap-1 rounded-md border-2 border-dashed border-iel-gray/50 p-1 text-iel-gray transition xl:order-2"
            :class="isDragging ? 'bg-iel-light-green/20' : 'bg-white'"
            @dragover.prevent="isDragging = true"
            @dragleave="isDragging = false"
            @drop.prevent="drop">
            <input
                :id="`${props.objectName}-${props.object.id}-files`"
                ref="file"
                multiple
                type="file"
                :name="`${props.objectName}-${props.object.id}-files`"
                :accept="fileTypesAsString"
                class="sr-only"
                @change="onChange" />
            <AppIcon
                :name="files.length ? 'fal fa-file-circle-check' : 'fal fa-file-arrow-up'"
                class="h-12 w-12" />
            <label
                :for="`${props.objectName}-${props.object.id}-files`"
                class="text-center text-lg xl:text-base">
                <div v-if="isDragging">{{ $t('Drop File') }}</div>
                <div
                    v-else
                    class="capitalize">
                    <span class="cursor-pointer font-bold text-iel-blue underline transition hover:text-iel-blue/60">
                        {{ $t('Browse files') }}
                    </span>
                    {{ $t('or drag and drop') }}
                </div>
                <span class="block text-center text-xs">{{ $t('PDF, JPG, PNG') }}</span>
            </label>
            <ul
                v-if="files.length"
                class="mx-auto w-full max-w-3xl text-iel-gray">
                <li
                    v-for="uploadedFile in files"
                    :key="uploadedFile.name"
                    class="my-4 flex w-full flex-col items-center md:my-2 md:flex-row md:items-start md:justify-between xl:flex-col xl:items-center">
                    <div class="pb-1 transition">
                        <span class="text-sm font-bold">{{ $t(uploadedFile.name) }}</span>
                        -
                        <span class="text-sm">{{ $t(Math.round(uploadedFile.size / 1000) + 'kb') }}</span>
                    </div>
                    <div class="flex gap-2 pb-1">
                        <AppSelectTags
                            :id="`select-label-load-card-for-load-${props.object.id}`"
                            v-model="form.labels"
                            :placeholder="$t('Select Label')"
                            name="Labels"
                            :options="props.documentTypeOptions"
                            :taggable="false"
                            :max-height="140"
                            :open-direction="'top'"
                            class="w-60"
                            required />
                        <AppErrorMessage
                            :name="`${props.objectName}-${props.object.id}-labels`"
                            :error="form.errors.labels" />
                    </div>
                    <div class="flex gap-2 pb-2">
                        <template v-if="props.objectName === 'load' && props.stopsOptions">
                            <AppSelectTags
                                id="select-label-app-stops"
                                v-model="form.stops"
                                :placeholder="$t('Select Stops')"
                                name="stops"
                                :options="props.stopsOptions"
                                :taggable="false"
                                class="w-60" />
                            <AppErrorMessage
                                :name="`${props.objectName}-${props.object.id}-stops`"
                                :error="form.errors.stops" />
                        </template>
                    </div>
                    <div class="flex items-center gap-2">
                        <AppButton
                            :disabled="form.processing"
                            @click="submit(files.indexOf(uploadedFile))">
                            Upload
                        </AppButton>
                        <AppButton
                            class="border-iel-dark-red text-iel-dark-red"
                            variant="outline"
                            title="Remove file"
                            data-test="remove-file-button"
                            :disabled="loadPanelDisabled"
                            @click="remove(files.indexOf(uploadedFile))">
                            {{ $t('Remove') }}
                        </AppButton>
                    </div>
                </li>
            </ul>
        </form>
    </div>
    <AppErrorMessage
        :name="`${props.objectName}-${props.object.id}-files`"
        :error="form.errors.document" />
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    object: Object,
    objectName: String,
    storeRoute: String,
    documentTypeOptions: Array,
    stopsOptions: Array,
    destroyRouteName: String,
    downloadRouteName: String,
    showRouteName: String,
    updateRouteName: String,
    acceptedFileTypes: Array,
    multiple: Boolean
});

const form = useForm({
    document: null,
    labels: [],
    stops: []
});

provide('name', `${props.objectName}-${props.object.id}-files`);

const isDragging = ref(false);
const files = ref([]);
const file = ref(null);

const fileTypesAsString = props.acceptedFileTypes.map(function (fileType) {
    return '.' + fileType.split('/')[1];
});

function onChange() {
    files.value = [...files.value, ...validFiles(file.value.files)];
}
function submit(i) {
    form.document = files.value[i];
    form.stops = form.stops.map(stop => stop.value);

    form.post(props.storeRoute, { preserveScroll: true });
    remove(i);
    form.reset();
}
function remove(i) {
    files.value.splice(i, 1);
}
function drop(e) {
    files.value = [...files.value, ...validFiles(e.dataTransfer.files)];
    isDragging.value = false;
}

function validFiles(files) {
    form.errors.document = '';

    files = [...files];
    files.map(file => {
        if (!props.acceptedFileTypes.includes(file.type)) {
            form.errors.document += `${file.name} is an invalid file type. `;
        }
    });

    return files.filter(file => {
        return props.acceptedFileTypes.includes(file.type);
    });
}
</script>
