<template>
    <AppCard
        title="External"
        theme="light_gray">
        <AppSelect
            name="map_service_version"
            label="PC Miler Version"
            :model-value="customerGroup.map_service_version"
            :url="url"
            :options="page.props.mapServiceVersions" />
        <div class="mt-4 flex items-center gap-4">
            <AppCheckbox
                name="enable_fourkites"
                label="Enable FourKites"
                :model-value="customerGroup.enable_fourkites"
                :url="url" />
            <AppInput
                v-if="customerGroup.enable_fourkites"
                name="fourkites_customer_id"
                label="FourKites Customer ID"
                :model-value="customerGroup.fourkites_customer_id"
                :url="url" />
        </div>
        <AppInput
            name="macropoint_id"
            label="Macropoint ID"
            :model-value="customerGroup.macropoint_id"
            :url="url"
            class="mt-4" />
    </AppCard>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
    customerGroup: Object
});

const page = usePage();

const url = route('customers.groups.services.update', props.customerGroup);
</script>
