<template>
    <AppModal
        id="report-modal"
        :show="isOpen"
        :title="context.title ?? $t('Add Report')"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="onConfirm">
            <AppInput
                v-model="form.name"
                name="name"
                label="Report Name"
                required
                :placeholder="$t('Enter the report name')" />
            <AppInput
                v-model="form.description"
                name="description"
                label="Report Description"
                required
                :placeholder="$t('Enter a desctiption of the report')" />
            <AppSelect
                id="select-tier"
                v-model="form.tier_level"
                :placeholder="$t('Select a Tier')"
                name="tier"
                :options="tierOptions"
                class="w-full"
                required />
            <AppInput
                v-model="form.power_bi_report_id"
                name="Power Bi Report Id"
                label="Power Bi Report Id"
                :placeholder="$t('Enter the Power Bi Report Id')" />
            <AppInput
                v-model="form.power_bi_workspace_id"
                name="Power Bi Workspace Id"
                label="Power Bi Workspace Id"
                :placeholder="$t('Enter the Power Bi Workspace Id')" />
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Save') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import { zipObject } from 'lodash-es';

const page = usePage();
const tiers = computed(() => page.props.tiers);

const { isOpen, close, onClosed, context } = useModal('ReportModal');

const form = useForm({
    name: null,
    description: null,
    tier_level: null,
    power_bi_report_id: null,
    power_bi_workspace_id: null,
    source: 'power_bi'
});

const tierOptions = computed(() => {
    const tierNames = tiers.value.map(tier => 'Tier ' + tier);
    return zipObject(tiers.value, tierNames);
});

provide('form', form);

watch(isOpen, value => {
    // Reset form and clear errors whenever modal is opened or closed
    form.reset();
    form.clearErrors();
    if (!value) return;

    if (context.value.mode === 'edit') {
        form.name = context.value.report.name;
        form.description = context.value.report.description;
        form.tier_level = context.value.report.tier_level;
        form.source = context.value.report.source;
        form.power_bi_report_id = context.value.report.data.power_bi_report_id;
        form.power_bi_workspace_id = context.value.report.data.power_bi_workspace_id;
    }
});

function onConfirm() {
    form.transform(form => ({
        name: form.name,
        description: form.description,
        tier_level: form.tier_level,
        source: form.source,
        data: {
            power_bi_report_id: form.power_bi_report_id,
            power_bi_workspace_id: form.power_bi_workspace_id
        }
    }));

    if (context.value.mode === 'create') {
        form.post(route('reports.store'), {
            onSuccess() {
                close();
            }
        });
    }

    if (context.value.mode === 'edit') {
        form.put(route('reports.update', { report: context.value.report }), {
            onSuccess() {
                close();
            }
        });
    }
}
</script>
