<template>
    <AppTableSimple
        :data="users"
        :checkable="true"
        :columns="[
            { name: 'id', label: 'ID', sortable: true, defaultSort: true },
            { name: 'name', label: 'Name', sortable: true },
            { name: 'email', label: 'Email', sortable: true },
            { name: 'actions', label: '' }
        ]"
        th-class="bg-iel-light-gray">
        <template #header="{ selected }">
            <div class="mb-4 flex items-center justify-between">
                <div class="flex gap-2">
                    <AppSearchInput
                        v-model="keyword"
                        placeholder="Search" />
                    <div v-if="selected.length">
                        <AppButton @click="removeUsersFromRole(selected)">
                            {{ $t('Remove users from role') }}
                        </AppButton>
                    </div>
                </div>
                <AppButton
                    icon="far fa-circle-plus"
                    @click="addUsersToRole">
                    {{ $t('Add User to Role') }}
                </AppButton>
            </div>
        </template>
        <template #actions="{ id }">
            <AppButton @click="removeUsersFromRole([id])">{{ $t('Remove Role') }}</AppButton>
        </template>
    </AppTableSimple>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { some, toString } from 'lodash-es';

const props = defineProps({
    modules: Object,
    role: Object
});

const keyword = ref('');

const users = computed(() => {
    if (!keyword.value) return props.role.users;

    return props.role.users.filter(user =>
        some(
            ['name', 'email'],
            key => user[key] && toString(user[key])?.toLowerCase().includes(keyword.value.toLowerCase())
        )
    );
});

const { open: openRoleUsersAddModal } = useModal('RoleUsersAddModal');

function addUsersToRole() {
    openRoleUsersAddModal({
        role: props.role
    });
}

function removeUsersFromRole(users) {
    router.delete(route('roles.users.destroy', props.role.id), {
        preserveScroll: true,
        data: { users }
    });
}
</script>
