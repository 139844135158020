<template>
    <AppCard
        title="Customers"
        theme="light_gray"
        class="span-all">
        <div class="flex justify-end">
            <AppButton
                name="all_group_billing"
                @click="allGroupBilling">
                {{ $t(allGroupBillingText) }}
            </AppButton>
        </div>
        <AppTablePill
            :columns="[
                { name: 'name', label: $t('Name'), sortable: true },
                { name: 'external_customer_name', label: $t('Contact') },
                { name: 'phone_number', label: $t('Phone Number') },
                { name: 'completed_loads_within_last_year', label: $t('Rolling 12/yr') },
                { name: 'total_loads', label: $t('Total Loads') },
                { name: 'unbilled_ar_formatted', label: $t('Unbilled A/R') },
                { name: 'billed_ar_formatted', label: $t('Billed A/R') },
                { name: 'available_credit_formatted', label: $t('Avail. Credit') },
                { name: 'overdue_beyond_30_days', label: $t('Over 30') },
                { name: 'group_billing', label: $t('Group Billing') }
            ]"
            :data="customerGroup.customers">
            <template #name-cell="{ row }">
                <div class="flex items-center">
                    <a
                        :href="route('customers.show', row)"
                        target="_blank"
                        class="flex gap-2 text-iel-blue underline">
                        <AppIcon
                            :name="row.is_active ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                            :class="row.is_active ? 'text-iel-green' : 'text-iel-red'"
                            class="h-5 w-5"
                            solid />
                        <strong>{{ row.name }}</strong>
                    </a>
                </div>
            </template>

            <template #header>
                <p>something.</p>
            </template>

            <template #group_billing-cell="{ row }">
                <AppCheckbox
                    v-model="row.use_group_billing"
                    class="flex justify-center"
                    name="use_group_billing"
                    label=""
                    :url="route('customers.billing.toggle', [row.id])" />
            </template>
        </AppTablePill>
    </AppCard>
</template>

<script setup>
import AppCheckbox from '@/Components/AppCheckbox.vue';
import AppButton from '@/Components/AppButton.vue';
import { router } from '@inertiajs/vue3';

const props = defineProps({
    customerGroup: Object
});
const allSelected = computed(() => props.customerGroup.customers.every(customer => customer.use_group_billing));
const allGroupBillingText = computed(() => (allSelected.value ? 'Deselect All' : 'Select All'));

const allGroupBilling = () => {
    router.patch(route('customers.groups.billing.toggle', props.customerGroup.id), {
        use_group_billing: !allSelected.value
    });
};
</script>
