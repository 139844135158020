<template>
    <AppModal
        id="load-copy-modal"
        :show="isOpen"
        :title="$t('Copy Load')"
        class="max-w-md"
        @close="close"
        @closed="handleModalClose">
        <div
            v-if="replicas"
            class="grid gap-2">
            <div class="text-sm">You have successfully copied the load. Here are the new load numbers:</div>
            <div
                v-for="replica in replicas"
                :key="replica.id"
                class="grid gap-2">
                <a
                    :href="route('loads.show', replica.id)"
                    target="_blank"
                    class="text-md font-bold">
                    Load # {{ replica.po_number }}
                </a>
            </div>
            <div class="flex justify-end">
                <AppButton @click="close">
                    {{ $t('Close') }}
                </AppButton>
            </div>
        </div>
        <form
            v-else
            ref="root"
            class="grid gap-2"
            @submit.prevent="submit">
            <AppAutocomplete
                v-model="form.customerId"
                name="customerId"
                label="Customer"
                required
                :options-url="route('customers.autocomplete')"
                :placeholder="$t('Search for a customer...')"
                :initial-options="
                    context.load?.customer
                        ? [{ value: context.load.customer.id, display: context.load.customer.name }]
                        : []
                " />
            <AppInput
                v-model.number="form.copies"
                label="Copies"
                name="copies"
                type="number"
                :min="1"
                required />
            <AppCheckbox
                v-model="form.details"
                label="Details"
                name="details" />
            <AppCheckbox
                v-model="form.billing"
                label="Billing"
                name="billing" />
            <AppCheckbox
                v-model="form.carrierInfo"
                label="Carrier Info"
                name="carrierInfo" />
            <AppCheckbox
                v-model="form.carrierPay"
                label="Carrier Pay"
                name="carrierPay" />
            <div
                v-if="context.load.stops?.length"
                class="mt-4 space-y-2">
                <AppHeading size="md">
                    {{ $t('Stops') }}
                </AppHeading>
                <LoadCopyStop
                    v-for="stop in context.load.stops"
                    :key="stop.id"
                    :stop="stop"
                    @update="updateStopElement" />
            </div>
            <AppErrorMessage :error="errorMessage" />
            <div class="flex justify-end gap-4">
                <AppButton
                    variant="outline"
                    class="mr-auto"
                    @click="selectAll">
                    {{ $t('Select All') }}
                </AppButton>
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    type="submit"
                    :disabled="loading">
                    {{ $t('Copy') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import axios from 'axios';

const { isOpen, close, onClosed, context } = useModal('LoadCopyModal');

const root = ref(null);
const loading = ref(false);
const errorMessage = ref(null);
const replicas = ref(null);
const selectedStops = [];

const initialData = {
    customerId: null,
    copies: 1,
    details: true,
    billing: true,
    carrierInfo: true,
    carrierPay: true,
    selectedStops: null
};

const form = ref(structuredClone(initialData));

watch(isOpen, value => {
    if (value) form.value.customerId = context.value.load?.customer.id;
});

function updateStopElement(stop) {
    const index = selectedStops.findIndex(item => item.id === stop.id);

    if (!stop.location) {
        selectedStops.splice(index, 1);
        return;
    }

    if (index === -1) {
        selectedStops.push(stop);
    } else if (stop.location || stop.details || stop.lineItems) {
        selectedStops.splice(index, 1, stop);
    }
}

async function submit() {
    form.value.selectedStops = selectedStops.map(stop => ({
        id: stop.id,
        lineItems: stop.lineItems,
        details: stop.details
    }));
    loading.value = true;
    try {
        const response = await axios.post(route('loads.copy', context.value.load.id), form.value);
        replicas.value = response.data;
    } catch (error) {
        errorMessage.value = error.response?.data?.message;
    } finally {
        loading.value = false;
    }
}

function handleModalClose() {
    onClosed();
    form.value = structuredClone(initialData);
    errorMessage.value = null;
    replicas.value = null;
}

function selectAll() {
    root.value.querySelectorAll('input[type="checkbox"]:not(:checked)').forEach(element => element.click());
}
</script>
