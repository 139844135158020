<template>
    <div class="relative">
        <div
            aria-haspopup="true"
            :aria-expanded="open"
            role="button"
            @click="open = !open">
            <slot name="trigger" />
        </div>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <div
                v-show="open"
                ref="target"
                class="absolute z-50 w-48 rounded-md shadow-lg"
                :class="alignments[align]"
                role="menu">
                <div class="w-full rounded-md bg-white py-1 ring-1 ring-black ring-opacity-5">
                    <slot name="content" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

const alignments = {
    'top-left': 'origin-top-left right-0 bottom-8',
    'bottom-left': 'origin-bottom-left top-8 right-0',
    'top-right': 'origin-top-right left-0 bottom-8',
    'bottom-right': 'origin-bottom-right top-8 left-0'
};

defineProps({
    align: {
        type: String,
        default: 'top-right',
        validator: value => ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(value)
    }
});

const target = ref(null);
const open = ref(false);

onClickOutside(target, () => (open.value = false));

watch(open, value => {
    if (value) {
        document.addEventListener('keydown', closeOnEscape);
    } else {
        document.removeEventListener('keydown', closeOnEscape);
    }
});

function closeOnEscape(e) {
    if (open.value && e.key === 'Escape') open.value = false;
}
</script>
