<template>
    <div>
        <PowerBIReportEmbed
            :embed-config="embedConfig"
            :css-class-name="reportClass"
            :phased-embedding="false"
            :event-handlers="eventHandlers"></PowerBIReportEmbed>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { PowerBIReportEmbed } from 'powerbi-client-vue-js';
import { models } from 'powerbi-client';

const { report } = usePage().props.data;
const { token } = usePage().props.data;
const reportClass = 'report-container';

const embedConfig = computed(() => ({
    type: 'report',
    id: report.id,
    embedUrl: report.embedUrl,
    accessToken: token.token,
    tokenType: models.TokenType.Embed,
    settings: {
        panes: {
            filters: {
                expanded: false,
                visible: false
            }
        },
        background: models.BackgroundType.Transparent
    }
}));

const eventHandlers = new Map([
    ['loaded', () => console.log('Report loaded')],
    ['rendered', () => console.log('Report rendered')],
    ['error', event => console.log(event.detail)]
]);
</script>

<style>
.report-container {
    height: 75vh;
    margin: 8px auto;
    width: 90%;
}
</style>
