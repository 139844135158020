<template>
    <AppHeading
        size="sm"
        class="pb-2 text-iel-gray">
        {{ $t(request.type) }}
    </AppHeading>
    <div class="grid gap-2">
        <div class="grid grid-cols-2 gap-2">
            <AppDisplayField
                label="Carrier Name"
                :value="request.details.carrier_contact_name" />
            <AppDisplayField
                label="Carrier Phone"
                :value="request.details.carrier_contact_phone" />
            <AppDisplayField
                label="Carrier Email"
                :value="request.details.carrier_email" />
            <AppDisplayField
                label="IEL PO Number"
                :value="request.details?.po_number" />
            <AppDisplayField
                label="Load Lane"
                :value="request.details?.load_lane" />
            <AppDisplayField
                label="Load Rate"
                :value="loadRate" />
            <AppDisplayField
                label="Load Equipment Type"
                :value="request.details?.equipment_type" />
            <AppDisplayField
                label="Load Commodity"
                :value="request.details?.load_commodity" />
            <AppDisplayField
                label="Load Value"
                :value="loadValue" />
            <AppDisplayField
                v-if="request.type === 'Carrier Activation'"
                label="Authority Types"
                :value="authorityTypes" />
            <AppDisplayField
                v-if="request.type === 'Carrier Activation'"
                label="Power Units"
                :value="powerUnits" />
            <AppDisplayField
                label="Notes"
                :value="request.details?.notes" />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    request: Object
});

const loadRate = computed(() => {
    if (props.request.details.load_rate_amount) {
        return '$' + props.request.details.load_rate_amount + ' ' + props.request.details.load_rate_currency;
    } else {
        return '-';
    }
});

const loadValue = computed(() => {
    if (props.request.details.load_value_amount) {
        return '$' + props.request.details.load_value_amount + ' ' + props.request.details.load_value_currency;
    } else {
        return '-';
    }
});

const authorityTypes = computed(() => {
    if (props.request.details.authority_types) {
        return props.request.details.authority_types;
    } else {
        return '-';
    }
});

const powerUnits = computed(() => {
    if (props.request.details.power_units) {
        return props.request.details.power_units;
    } else {
        return '-';
    }
});
</script>
