<template>
    <AppModal
        id="add-customer-to-customer-group-modal"
        :show="isOpen"
        :title="$t('Add Customer to Customer Group')"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="save">
            <AppRadioGroup
                v-model="selectedOption"
                label=""
                name="AddOrCreate"
                :options="[
                    { value: 'add', label: 'Add Existing' },
                    { value: 'create', label: 'Create New' }
                ]" />
            <div v-if="selectedOption === 'add'">
                <AppAutocomplete
                    v-model="customer"
                    name="customer"
                    label="Customer"
                    :options-url="route('customers.autocomplete')"
                    :placeholder="$t('Search for a customer...')" />
            </div>

            <div v-else-if="selectedOption === 'create'">
                <AppInput
                    v-model="form.name"
                    name="name"
                    required
                    :label="$t('Customer Name')" />
            </div>

            <AppSelect
                v-model="form.group_reason"
                name="group_reason"
                required
                :options="{ billing: 'Billing', load_management: 'Load Management' }"
                :label="$t('Reason for Setup')" />

            <div v-if="form.group_reason === 'billing'">
                <AppInput
                    v-model="form.billing_contact_name"
                    name="billing_contact_name"
                    :label="$t('AP Contact Name')" />
                <AppInput
                    v-model="form.billing_contact_emails"
                    name="billing_contact_emails"
                    :label="$t('AP Contact Email')" />
            </div>
            <AppErrorMessage
                v-if="errorMessage"
                :error="errorMessage"
                name="error" />
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Save') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { ref, watch, provide } from 'vue';
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CustomerGroupAddCustomerModal');

const form = useForm({
    name: null,
    type: null,
    customer_group_id: null,
    group_reason: null,
    billing_contact_name: null,
    billing_contact_emails: null
});

const customer = ref(null);
const errorMessage = ref('');
const selectedOption = ref('add');

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();
    errorMessage.value = '';
    selectedOption.value = 'add';

    if (value) {
        form.customer_group_id = context.value.customerGroup.id;
        customer.value = context.value.customer;
    }
});

// Watch for changes in the selected customer to reset errors and fields
watch(customer, (newCustomer, oldCustomer) => {
    if (newCustomer !== oldCustomer) {
        form.clearErrors();
        errorMessage.value = '';
        form.group_reason = null;
        form.billing_contact_name = null;
        form.billing_contact_emails = null;
    }
});

function save() {
    if (selectedOption.value === 'add') {
        addExistingCustomer();
    } else {
        createCustomer();
    }
}

function createCustomer() {
    delete form.type;
    form.post(route('customers.store'), {
        onSuccess() {
            close();
        }
    });
}

function addExistingCustomer() {
    delete form.name;
    form.type = 'Add Customer to Group';

    form.post(
        route('customers.customerTicket.store', {
            customer: customer.value
        }),
        {
            onSuccess() {
                close();
            },
            onError() {
                handleErrors(form.errors);
            }
        }
    );
}

function handleErrors(errors) {
    let messages = [];
    const visibleFields = ['billing_contact_name', 'billing_contact_emails'];

    Object.entries(errors).forEach(([field, message]) => {
        if (!visibleFields.includes(field)) {
            if (Array.isArray(message)) {
                messages.push(...message);
            } else {
                messages.push(message);
            }
        }
    });

    errorMessage.value = messages.join('\n');
}
</script>
