<template>
    <GuestLayout>
        <Head title="Log in" />

        <div
            v-if="status"
            class="mb-4 text-sm font-medium text-iel-green">
            {{ status }}
        </div>

        <div class="mb-4 grid gap-4 border-b border-[#e5e7eb] pb-4">
            <form
                v-if="allowCredentialsLogin"
                class="grid gap-4"
                @submit.prevent="submit">
                <AppInput
                    id="email"
                    v-model="form.email"
                    name="email"
                    type="email"
                    required
                    label="Email"
                    placeholder="Email"
                    :error="form.errors.email" />
                <AppInput
                    id="password"
                    v-model="form.password"
                    name="password"
                    type="password"
                    required
                    label="Password"
                    placeholder="Password"
                    :error="form.errors.password" />
                <AppButton
                    id="login-button"
                    class="py-2"
                    type="submit"
                    block
                    :disabled="form.processing">
                    Log in
                </AppButton>
            </form>

            <Link
                id="forgot-password-link"
                :href="route('password.request')"
                class="mt-2 block rounded-md text-sm text-iel-dark-gray underline hover:text-iel-darkest-gray focus:outline-none focus:ring-2 focus:ring-iel-light-blue focus:ring-offset-2">
                Forgot your password?
            </Link>
        </div>

        <a
            id="saml-login-link"
            class="block w-full w-full rounded-md border border-iel-blue bg-iel-blue py-2 text-center text-sm font-medium text-white"
            disabled="false"
            :href="route('login.saml')">
            Log in with Microsoft
        </a>
    </GuestLayout>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

defineProps({
    canResetPassword: {
        type: Boolean
    },
    status: {
        type: String
    },
    allowCredentialsLogin: {
        type: Boolean
    }
});

const form = useForm({
    email: '',
    password: '',
    remember: true
});

function submit() {
    form.post(route('login'), {
        onFinish: () => form.reset('password')
    });
}
</script>
