<template>
    <div class="flex items-center gap-x-2">
        <button
            id="loadboard-regular-layout-toggle"
            class="rounded p-0.5"
            :class="loadboard.layout === 'regular' ? 'bg-iel-light-gray' : 'hover:bg-iel-silver'"
            aria-label="view regular layout"
            @click="setLayout('regular')">
            <AppIcon
                name="far fa-bars"
                class="rotate-90 text-lg" />
        </button>
        <button
            id="loadboard-mini-layout-toggle"
            class="rounded p-0.5"
            :class="loadboard.layout === 'mini' ? 'bg-iel-light-gray' : 'hover:bg-iel-silver'"
            aria-label="view mini layout"
            @click="setLayout('mini')">
            <AppIcon
                name="far fa-grid"
                class="text-lg" />
        </button>
        <button
            id="loadboard-list-layout-toggle"
            class="rounded p-0.5"
            :class="loadboard.layout === 'list' ? 'bg-iel-light-gray' : 'hover:bg-iel-silver'"
            aria-label="view list layout"
            @click="setLayout('list')">
            <AppIcon
                name="far fa-bars"
                class="text-lg" />
        </button>
    </div>
</template>

<script setup>
import { loadboard } from '@/stores';

const emit = defineEmits(['change']);

function setLayout(layout) {
    // Change the layout value
    loadboard.value.layout = layout;
    // Emit an event so parent components can react to the layout being changed
    emit('change', layout);
}
</script>
