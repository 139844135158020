<template>
    <Head title="Reports" />
    <AppHeader
        title="Reports"
        :icon-component="IconReports">
        <template #actions>
            <AppFilterPills
                :root-url="route('reports.index')"
                :filters="tierFilters"
                :link-params="{ only: ['reports', 'tiers'] }" />
            <AppButton
                id="add-report-button"
                icon="far fa-circle-plus"
                @click="addReport">
                {{ $t('Add Report') }}
            </AppButton>
        </template>
    </AppHeader>
    <slot />
</template>

<script setup>
import IconReports from '/resources/img/icon-reports.svg';
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const { open: openReportModal } = useModal('ReportModal');

const tierFilters = computed(() => {
    const tiers = {};
    page.props.tiers.forEach(tier => {
        tiers[tier] = { label: 'tier ' + tier };
    });
    return tiers;
});

function addReport() {
    openReportModal({
        mode: 'create',
        title: 'Add Report'
    });
}
</script>
