<template>
    <AppModal
        id="create-customer-modal"
        :show="isOpen"
        :title="$t('Create Customer')"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="save">
            <AppInput
                v-model="form.name"
                name="name"
                label="Customer name"
                :placeholder="$t('Enter the customer name')" />
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Save') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed } = useModal('CustomerCreateModal');

const form = useForm({
    name: null,
    is_active: false
});

provide('form', form);

watch(isOpen, () => {
    // Reset form and clear errors whenever modal is opened or closed
    form.reset();
    form.clearErrors();
});

function save() {
    form.post(route('customers.store'), {
        onSuccess() {
            close();
        }
    });
}
</script>
