<template>
    <div class="flex max-w-md flex-col items-end">
        <AppModal
            id="snooze-task-modal"
            :show="isOpen"
            class="max-w-sm"
            @close="close"
            @closed="onClosed">
            <div class="mb-4 flex items-center text-xl font-bold text-iel-blue">
                <span>Snooze Task</span>
                <AppIcon
                    name="fas fa-alarm-snooze"
                    class="ml-2" />
            </div>
            <form
                class="grid gap-5"
                @submit.prevent="handleSubmit">
                <div class="datetime flex w-full items-end gap-2">
                    <AppIcon
                        name="far fa-calendar-day"
                        class="h-6 w-6 text-iel-gray" />
                    <AppDateTime
                        v-model:datetime="currentDueAt"
                        v-model:timezone="currentDueAtTimezone"
                        :field-names="{ datetime: 'current_due_at', timezone: 'current_due_at_timezone' }"
                        name="current_due_at"
                        :label="$t('Current Due Date/time')"
                        disabled />
                </div>

                <div class="datetime flex w-full items-end gap-2">
                    <AppIcon
                        name="far fa-calendar-day"
                        class="h-6 w-6 text-iel-gray" />
                    <AppDateTime
                        v-model:datetime="form.due_at"
                        v-model:timezone="form.due_at_timezone"
                        :field-names="{ datetime: 'due_at', timezone: 'due_at_timezone' }"
                        name="due_at"
                        :label="$t('New Due Date/time')"
                        required />
                </div>

                <AppInput
                    v-model="form.note"
                    name="note"
                    bottom-border
                    :label="$t('Reason')"
                    required />
                <div class="flex justify-end gap-4 pt-2">
                    <AppButton type="submit">{{ $t('Save') }}</AppButton>
                </div>
            </form>
        </AppModal>
    </div>
</template>

<script setup>
import { useForm, router } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('SnoozeModal');

const form = useForm({
    note: '',
    due_at: null,
    due_at_timezone: null
});

const currentDueAt = ref(null);
const currentDueAtTimezone = ref(null);

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();

    if (value) {
        currentDueAt.value = context.value.task.task.due_at;
        currentDueAtTimezone.value = context.value.task.task.due_at_timezone;
        form.due_at = null;
        form.due_at_timezone = null;
        form.note = context.value.task.form;
    }
});

function handleSubmit() {
    form.patch(
        route('loads.tasks.snooze', {
            load: context.value.task.load.id,
            task: context.value.task.task.id
        }),
        {
            preserveScroll: true,
            onSuccess() {
                close();
                router.reload({ only: ['load'] });
            }
        }
    );
}
</script>

<style>
.datetime input {
    @apply !rounded-none !border-l-0 !border-r-0 !border-t-0;
}
.datetime .dp__input_icon {
    @apply !hidden;
}
.datetime .dp__input_icon_pad {
    @apply !pl-2;
}
</style>
