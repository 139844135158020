<template>
    <summary
        class="flex w-full cursor-pointer select-none flex-col items-start gap-2 p-2 text-left text-sm lg:flex-row lg:items-center lg:justify-between lg:py-1">
        <div class="flex w-full flex-col items-start gap-2 lg:grid lg:grid-cols-12 lg:justify-between lg:py-1">
            <div class="flex gap-4 whitespace-normal lg:col-span-3 lg:gap-2">
                <AppIcon
                    name="far fa-bars"
                    class="handle h-5 w-5 cursor-move text-iel-gray" />
                <div class="flex truncate">
                    <p
                        class="lg:whitespace-wrap truncate whitespace-normal lg:col-span-4"
                        :class="{ 'text-iel-red': !stop.location.address.validated_at }">
                        <span class="mr-1 font-semibold">{{ $t('Stop #') }} {{ stop.order }}</span>
                        <span class="uppercase">{{ stop.type }}</span>
                    </p>
                </div>
            </div>
            <p
                class="lg:whitespace-wrap truncate whitespace-normal lg:col-span-4"
                :class="{ 'text-iel-red': !stop.location.address.validated_at }">
                <span class="mr-1 font-semibold">{{ stop.location.name }}</span>
                {{ stop.location.address.city }},
                {{ stop.location.address.region }}
            </p>
            <p class="flex gap-x-4 lg:col-span-5">
                {{ stop.stop_window_begins_at }}
                <span class="font-semibold">APPT. #{{ stop.appointment_number }}</span>
            </p>
        </div>
        <div class="flex items-center gap-4">
            <p
                v-if="stop.order === load.nextStop?.number"
                class="whitespace-nowrap"
                :class="trackingStatusColor">
                {{ load.nextStop.status }}
            </p>
            <AppButton
                :id="`load-delete-stop-${stop.id}-button`"
                variant="outline"
                icon="far fa-trash-can"
                permission="update_stops"
                :disabled="loadDisabled"
                @click.stop="deleteStop(stop.id)">
                Delete
            </AppButton>
        </div>
    </summary>

    <div class="grid gap-6 border-t p-4 @container">
        <div class="grid items-start gap-4 md:items-end @lg:md:grid-cols-2">
            <div class="grid gap-2 md:items-end @lg:md:grid-cols-2">
                <AppRadioGroup
                    name="type"
                    :url="url"
                    label=""
                    :scope="stop.id"
                    :model-value="stop.type"
                    permission="update_stops"
                    :options="[
                        { value: 'pickup', label: 'Pickup' },
                        { value: 'dropoff', label: 'Dropoff' }
                    ]"
                    inline
                    class="col-span-full"
                    :disabled="loadDisabled"
                    :confirmation="confirmation" />
                <div class="col-span-full">
                    <AppAutocomplete
                        v-model="stop.location.id"
                        name="location_id"
                        label="Stop Location"
                        permission="update_stops"
                        :scope="stop.id"
                        :url="url"
                        :initial-options="[{ value: stop.location.id, display: stop.location.name }]"
                        :options-url="route('locations.autocomplete')"
                        :placeholder="$t('Search for a location...')"
                        :disabled="loadDisabled"
                        :confirmation="confirmation" />
                    <Link
                        :href="route('locations.show', stop.location.id)"
                        class="group mt-1 inline-flex items-center gap-2 text-sm underline transition hover:text-iel-gray/90">
                        View {{ stop.location.name }}
                        <AppIcon
                            name="far fa-arrow-right"
                            class="h-3 w-3 transition group-hover:translate-x-2" />
                    </Link>
                </div>
                <AppDisplayField
                    label="Stop Address"
                    :value="fullAddress"
                    class="col-span-full"
                    :error="!stop.location.address.validated_at && 'Invalid address'" />

                <AppSelect
                    :scope="stop.id"
                    label="Stop Contacts"
                    name="contact_id"
                    :model-value="stop.contact?.id"
                    permission="update_stops"
                    :url="url"
                    :disabled="loadDisabled"
                    class="mb-auto">
                    <optgroup
                        v-if="stop.location.primaryContact"
                        label="Location Contact">
                        <option :value="stop.location.primaryContact?.id">
                            {{ stop.location.primaryContact?.name }} {{ stop.location.primaryContact?.phone_number }}
                        </option>
                    </optgroup>

                    <optgroup
                        v-if="stop.location.shippingContacts"
                        label="Shippers">
                        <option
                            v-for="contact in stop.location.shippingContacts"
                            :key="contact.id"
                            :value="contact.id">
                            {{ contact.name }} {{ contact.phone_number }}
                        </option>
                    </optgroup>

                    <optgroup
                        v-if="stop.location.receivingContacts"
                        label="Receivers">
                        <option
                            v-for="contact in stop.location.receivingContacts"
                            :key="contact.id"
                            :value="contact.id">
                            {{ contact.name }} {{ contact.phone_number }}
                        </option>
                    </optgroup>
                </AppSelect>

                <div class="grid gap-2 md:grid-cols-2">
                    <AppInput
                        v-model="stop.appointment_number"
                        permission="update_stops"
                        name="appointment_number"
                        :scope="stop.id"
                        :url="url"
                        :disabled="loadDisabled"
                        label="Appointment #" />
                    <AppDisplayField
                        label="Miles to Next Stop"
                        :value="stop.miles_to_next_stop?.toLocaleString()"
                        :error="milesToNextStopError" />
                </div>
            </div>
            <div class="grid items-start gap-2 @2xl:grid-cols-2">
                <div class="grid grid-cols-[3fr,1fr] items-end gap-2">
                    <AppInput
                        v-model="stop.weight"
                        permission="update_stops"
                        :scope="stop.id"
                        name="weight"
                        :disabled="loadDisabled"
                        :url="url" />
                    <AppSelect
                        name="weight_unit"
                        label=""
                        permission="update_stops"
                        :scope="stop.id"
                        :model-value="stop.weight_unit"
                        :disabled="loadDisabled"
                        :url="url"
                        :options="$page.props.units.weight" />
                    <AppInput
                        v-model="stop.volume"
                        permission="update_stops"
                        :scope="stop.id"
                        name="volume"
                        :disabled="loadDisabled"
                        :url="url" />
                    <AppSelect
                        name="volume_unit"
                        label=""
                        permission="update_stops"
                        :model-value="stop.volume_unit"
                        :disabled="loadDisabled"
                        :url="url"
                        :scope="stop.id"
                        :options="$page.props.units.volume" />
                    <AppInput
                        :id="`load-${load.id}-stop-${stop.id}-pieces`"
                        v-model="stop.pieces"
                        permission="update_stops"
                        :name="`load-${load.id}-stop-${stop.id}-pieces`"
                        label="Pieces"
                        :disabled="loadDisabled"
                        :url="url" />
                    <AppSelect
                        name="pieces_unit"
                        label=""
                        :model-value="stop.pieces_unit"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        :url="url"
                        :scope="stop.id"
                        :options="$page.props.units.pieces" />
                    <AppInput
                        v-model="stop.pallets"
                        permission="update_stops"
                        :scope="stop.id"
                        name="pallets"
                        :disabled="loadDisabled"
                        :url="url" />
                </div>
                <AppTextarea
                    :model-value="stop.note?.content"
                    permission="update_stops"
                    :scope="stop.id"
                    label="Notes"
                    name="note"
                    :url="url"
                    :disabled="loadDisabled"
                    class="resize-none"
                    rows="7" />
            </div>

            <!-- References -->
            <div
                :id="`load-${load.id}-stop-${stop.id}-references`"
                class="flex flex-col gap-2">
                <div class="hidden grid-cols-4 text-sm text-iel-dark-gray md:grid">
                    <span>{{ $t('Reference Number') }}</span>
                    <span>{{ $t('Reference Description') }}</span>
                </div>
                <div
                    v-for="(reference, i) in form.references"
                    :key="i"
                    class="-mt-2 grid gap-4">
                    <div class="grid gap-2 md:grid-cols-4">
                        <div class="relative">
                            <AppInput
                                v-model="reference.number"
                                permission="update_stops"
                                class="hide-stop-ref-label"
                                name="reference_number"
                                :disabled="loadDisabled"
                                :scope="stop.id"
                                :error="form.errors[`references.${i}.number`]"
                                @blur="submitReferences(`${i}.number`)" />
                            <AppRequestStatus
                                v-if="lastSubmittedReferenceField === `${i}.number`"
                                class="!right-2"
                                :processing="form.processing"
                                :recently-successful="form.recentlySuccessful"
                                :error="form.errors[`references.${i}.number`]" />
                        </div>
                        <div class="relative">
                            <div class="flex gap-2">
                                <AppSelect
                                    :id="`load-${load.id}-stop-${stop.id}-reference-description-${i}`"
                                    v-model="reference.description"
                                    permission="update_stops"
                                    class="hide-stop-ref-label"
                                    name="reference_description"
                                    :scope="stop.id"
                                    :options="{
                                        BOL: 'BOL',
                                        'Delivery Number': 'Delivery Number',
                                        PRO: 'PRO',
                                        SAP: 'SAP',
                                        'Customer Reference': 'Customer Reference',
                                        'Freight Agreement Number': 'Freight Agreement Number',
                                        'Purchase Order Number': 'Purchase Order Number',
                                        'Release Number': 'Release Number',
                                        Other: 'Other'
                                    }"
                                    :error="form.errors[`references.${i}.description`]"
                                    :disabled="loadDisabled"
                                    @change="submitReferences(`${i}.description`)" />
                            </div>
                            <AppRequestStatus
                                v-if="lastSubmittedReferenceField === `${i}.description`"
                                class="!right-2"
                                :processing="form.processing"
                                :recently-successful="form.recentlySuccessful"
                                :error="form.errors[`references.${i}.description`]" />
                        </div>
                        <div
                            v-if="reference.description === 'Other'"
                            class="relative">
                            <AppInput
                                :id="`load-${load.id}-stop-${stop.id}-other-reference-description-${i}`"
                                v-model="reference.other"
                                permission="update_stops"
                                class="hide-stop-ref-label"
                                name="reference_description_other"
                                :disabled="loadDisabled"
                                :error="form.errors[`references.${i}.other`]"
                                @blur="submitReferences(`${i}.other`)" />
                            <AppRequestStatus
                                v-if="lastSubmittedReferenceField === `${i}.other`"
                                class="!right-2"
                                :processing="form.processing"
                                :recently-successful="form.recentlySuccessful"
                                :error="form.errors[`references.${i}.other`]" />
                        </div>
                        <button
                            v-if="form.references.length > 1"
                            id="stop-reference-delete-button"
                            type="button"
                            :disabled="loadDisabled"
                            class="mb-1 flex h-5 w-5 items-center justify-center self-end rounded-full border border-iel-blue p-1 text-iel-blue hover:bg-iel-silver"
                            @click="removeReference(i)">
                            <AppIcon
                                name="far fa-minus"
                                class="h-4 w-4" />
                        </button>
                    </div>
                    <hr class="my-8 md:hidden" />
                </div>
                <div>
                    <AppButton
                        :id="`load-${load.id}-stop-${stop.id}-add-stop-reference-button`"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        @click="addReference()">
                        {{ $t('Add') }}
                    </AppButton>
                </div>
            </div>

            <!-- Responsibility -->
            <div class="grid gap-2">
                <h2 class="font-bold text-iel-blue">{{ $t('Carrier Responsible For') }}</h2>
                <div class="grid grid-cols-2 gap-2">
                    <AppRadioGroup
                        name="is_pallet_exchange"
                        label="Pallet Exchange"
                        :scope="stop.id"
                        :model-value="stop.is_pallet_exchange"
                        permission="update_stops"
                        :url="url"
                        :disabled="loadDisabled"
                        :confirmation="confirmation"
                        :options="[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' }
                        ]"
                        inline />
                    <AppRadioGroup
                        name="has_unloading_charges"
                        label="Unloading Charges"
                        :scope="stop.id"
                        :model-value="Number(stop.has_unloading_charges)"
                        :url="url"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        :confirmation="confirmation"
                        :options="[
                            { value: 1, label: 'Yes' },
                            { value: 0, label: 'No' }
                        ]"
                        inline />
                </div>
            </div>
        </div>

        <!-- Timing -->
        <div class="flex flex-col gap-2 xl:flex-row xl:items-center">
            <AppRadioGroup
                name="has_stop_window"
                label="Type"
                inline
                :scope="stop.id"
                :model-value="stop.has_stop_window"
                :url="url"
                permission="update_stops"
                :disabled="loadDisabled"
                :confirmation="confirmation"
                :options="[
                    { value: true, label: 'FCFS' },
                    { value: false, label: 'Appt' }
                ]" />
            <AppDateTime
                v-model:datetime="stop.stop_window_begins_at"
                v-model:timezone="stop.stop_window_begins_at_timezone"
                permission="update_stops"
                name="stop_window_begins_at"
                :scope="stop.id"
                :label="`Stop Plan Arrival ${stop.has_stop_window ? 'Window Start' : ''}`"
                :field-names="{ datetime: 'stop_window_begins_at', timezone: 'stop_window_begins_at_timezone' }"
                :url="url"
                :disabled="loadDisabled"
                :confirmation="confirmation"
                class="xl:col-span-2" />
            <template v-if="stop.has_stop_window">
                <AppDateTime
                    v-model:datetime="stop.stop_window_ends_at"
                    v-model:timezone="stop.stop_window_ends_at_timezone"
                    permission="update_stops"
                    name="stop_window_ends_at"
                    :scope="stop.id"
                    :label="`Stop Plan Arrival ${stop.has_stop_window ? 'Window End' : ''}`"
                    :field-names="{ datetime: 'stop_window_ends_at', timezone: 'stop_window_ends_at_timezone' }"
                    :url="url"
                    :disabled="loadDisabled"
                    :confirmation="confirmation"
                    class="xl:col-span-2" />
            </template>
        </div>

        <!-- Line Items -->
        <h2 class="font-bold text-iel-blue">{{ $t('Line Items') }}</h2>
        <ul
            v-if="stop.line_items.length > 0"
            :id="`load-${page.props.load.id}-stop-${stop.id}-line-items`"
            class="grid gap-8 divide-y xl:divide-none">
            <li
                v-for="item in stop.line_items"
                :id="`line-item-${item.id}`"
                :key="item.id"
                class="grid items-end gap-2 pt-8 first-of-type:pt-0 lg:grid-cols-3 xl:grid-cols-8 xl:pt-0">
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-shipment-reference-number-${item.id}`"
                    v-model="item.shipment_reference_number"
                    permission="update_stops"
                    name="shipment_reference_number"
                    :scope="stop.id"
                    label="Shipment Ref#"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-shipment-po-${item.id}`"
                    v-model="item.shipment_po"
                    permission="update_stops"
                    name="shipment_po"
                    :scope="stop.id"
                    label="Shipment PO"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppSelect
                    :id="`load-${load.id}-stop-${stop.id}-commodity-${item.id}`"
                    name="commodity"
                    label="Commodity"
                    permission="update_stops"
                    :disabled="loadDisabled"
                    :scope="stop.id"
                    :model-value="item.commodity"
                    :url="route('stops.lineItems.update', item)"
                    :options="commodityTypes" />
                <div class="grid grid-cols-2 items-end gap-2">
                    <AppInput
                        :id="`load-${load.id}-stop-${stop.id}-weight-${item.id}`"
                        v-model="item.weight"
                        permission="update_stops"
                        name="weight"
                        :scope="stop.id"
                        label="Weight"
                        :disabled="loadDisabled"
                        :url="route('stops.lineItems.update', item)" />
                    <AppSelect
                        :id="`load-${load.id}-stop-${stop.id}-weight-unit-${item.id}`"
                        name="weight_unit"
                        :scope="stop.id"
                        label="Unit"
                        class="min-w-[theme('spacing.14')]"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        :model-value="item.weight_unit"
                        :options="$page.props.units.weight"
                        :url="route('stops.lineItems.update', item)" />
                </div>
                <div class="grid grid-cols-2 items-end gap-2">
                    <AppInput
                        :id="`load-${load.id}-stop-${stop.id}-volume-${item.id}`"
                        v-model="item.volume"
                        permission="update_stops"
                        name="volume"
                        :scope="stop.id"
                        label="Volume"
                        :disabled="loadDisabled"
                        :url="route('stops.lineItems.update', item)" />
                    <AppSelect
                        :id="`load-${load.id}-stop-${stop.id}-volume-unit-${item.id}`"
                        name="volume_unit"
                        :scope="stop.id"
                        label="Unit"
                        class="min-w-[theme('spacing.14')]"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        :model-value="item.volume_unit"
                        :options="$page.props.units.volume"
                        :url="route('stops.lineItems.update', item)" />
                </div>
                <div class="grid grid-cols-2 items-end gap-2">
                    <AppInput
                        :id="`load-${load.id}-stop-${stop.id}-pieces-${item.id}`"
                        v-model="item.pieces"
                        permission="update_stops"
                        name="pieces"
                        :scope="stop.id"
                        label="Pieces"
                        :disabled="loadDisabled"
                        :url="route('stops.lineItems.update', item)" />
                    <AppSelect
                        :id="`load-${load.id}-stop-${stop.id}-piece-unit-${item.id}`"
                        name="pieces_unit"
                        :scope="stop.id"
                        label="Unit"
                        class="min-w-[theme('spacing.14')]"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        :model-value="item.pieces_unit"
                        :options="$page.props.units.pieces"
                        :url="route('stops.lineItems.update', item)" />
                </div>
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-description-${item.id}`"
                    v-model="item.description"
                    permission="update_stops"
                    name="description"
                    :scope="stop.id"
                    label="Description"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <div>
                    <AppButton
                        icon="far fa-trash-can"
                        variant="outline"
                        class="inline-block transition hover:bg-iel-light-gray"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        @click="deleteLineItem(item)">
                        {{ $t('Delete') }}
                    </AppButton>
                </div>
            </li>
        </ul>
        <div v-else>{{ $t('No line items for this stop') }}</div>
        <AppButton
            :id="`load-${load.id}-stop-${stop.id}-add-new-line-item-button`"
            icon="fal fa-pen"
            class="w-fit transition hover:bg-white hover:text-iel-blue"
            permission="update_stops"
            :disabled="loadDisabled"
            @click="addLineItem">
            {{ $t('New Line Item') }}
        </AppButton>
    </div>
</template>

<script setup>
import { commodityTypes } from '@/data';
import { usePage, router, useForm } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import { useLoadDisabled } from '@/helpers';

const props = defineProps({
    stop: Object
});

const { t } = useI18n();

const page = usePage();

const load = computed(() => page.props.load);

const url = route('stops.update', props.stop);

const ratecon = useRateCon(load);

const confirmation = ratecon.confirmChange;

const milesToNextStopError = computed(() => {
    if (props.stop.miles_to_next_stop) return null;
    const { address } = props.stop.location;
    const stops = page.props.load.stops;
    if (stops[stops.length - 1].id === props.stop.id) {
        return address.validated_at ? null : 'We are unable to calculate because this stop has an invalid address.';
    } else {
        return `We are unable to calculate because ${
            address.validated_at ? 'one of the other stops' : 'this stop'
        } has an invalid address.`;
    }
});

const fullAddress = computed(() => {
    const { address } = props.stop.location;
    const address2 = address.address_2 ? `, ${address.address_2}` : '';
    return `${address.address_1}${address2}, ${address.city}, ${address.region} ${address.postal_code}`;
});

function deleteStop(stop) {
    const message = ratecon.isSent.value ? ratecon.confirmChangeMessage : t('Delete stop?');

    if (confirm(message)) {
        // TODO change reload call to `only: ['stops']` if stops are exposed as their own key in the response
        router.delete(route('stops.destroy', stop), {
            preserveScroll: true,
            only: ['load']
        });
    }
}

function deleteLineItem(lineItem) {
    if (confirm('Delete Line Item?')) {
        router.delete(route('stops.lineItems.destroy', lineItem.id), {
            preserveScroll: true
        });
    }
}

function addLineItem() {
    router.post(route('stops.lineItems.store', props.stop), null, {
        preserveScroll: true
    });
}

const lastSubmittedReferenceField = ref();

const form = useForm({
    references:
        props.stop.references?.length > 0 ? props.stop.references : [{ number: null, description: null, other: null }]
});

provide('form', form);

function submitReferences(field) {
    lastSubmittedReferenceField.value = field;
    form.patch(url, {
        preserveScroll: true
    });
}

function removeReference(i) {
    form.references.splice(i, 1);
    submitReferences();
}

function addReference() {
    form.references.push({
        number: null,
        description: null,
        other: null
    });
}

const loadDisabled = useLoadDisabled(page.props.load);

const trackingStatusColor = computed(() => ({
    'text-green-500': load.value.nextStop?.status === 'On Time',
    'text-yellow-500': load.value.nextStop?.status === 'Behind',
    'text-red-500': load.value.nextStop?.status !== 'On Time' && load.value.nextStop?.status !== 'Behind'
}));
</script>

<style>
@screen md {
    .hide-stop-ref-label label {
        display: none;
    }
}
</style>
