<template>
    <Head :title="$t('Locations')" />
    <AppHeader
        :title="$t('Locations')"
        :icon-component="IconLocations">
        <template #search>
            <AppSearchInput
                id="locations-list-search"
                v-model="q" />
        </template>
        <template #actions>
            <AppButton
                id="add-location-button"
                icon="far fa-circle-plus"
                @click="createLocation">
                {{ $t('Add Location') }}
            </AppButton>
        </template>
    </AppHeader>
    <slot />
</template>

<script setup>
import IconLocations from '/resources/img/icon-locations.svg';

const props = defineProps({
    location: Object
});

const { open: locationModal } = useModal('LocationCreateModal');

function createLocation() {
    locationModal({
        location: props.location
    });
}

const { q } = useQueryParam({ only: ['locations'] });
</script>
