<template>
    <Head :title="role.name" />
    <AppHeader
        title="Roles"
        :icon-component="IconRoles" />
    <AppCard :title="role.name">
        <template #nav>
            <AppButton
                icon="far fa-pen"
                class="inline-block transition hover:bg-iel-light-gray"
                @click="editRole" />
        </template>
        <AppHeading size="md">{{ role.description }}</AppHeading>
        <div class="my-2 flex gap-2">
            <AppButton
                block
                :variant="activeTab === 'permissions' ? 'primary' : 'outline'"
                @click="activeTab = 'permissions'">
                Permissions
            </AppButton>
            <AppButton
                block
                :variant="activeTab === 'users' ? 'primary' : 'outline'"
                @click="activeTab = 'users'">
                Users
            </AppButton>
        </div>
        <RolePermissions
            v-if="activeTab === 'permissions'"
            v-bind="{ role, modules }" />
        <RoleUsers
            v-if="activeTab === 'users'"
            v-bind="{ role, modules }" />
    </AppCard>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import IconRoles from '/resources/img/icon-roles.svg';

const page = usePage();

const role = computed(() => page.props.role);
const modules = computed(() => page.props.modules);

const activeTab = ref('permissions');

const { open: openRoleModal } = useModal('RoleModal');

function editRole() {
    openRoleModal({
        mode: 'edit',
        role: page.props.role,
        onClose: () => {
            router.reload({ only: ['roles'] });
        }
    });
}
</script>
