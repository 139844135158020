<script setup>
import { useForm } from '@inertiajs/vue3';

const form = useForm({
    current_password: '',
    password: '',
    password_confirmation: ''
});

const updatePassword = () => {
    form.put(route('password.update'), {
        preserveScroll: true,
        onSuccess: () => form.reset()
    });
};
</script>

<template>
    <section>
        <header>
            <h2 class="text-lg font-medium text-iel-darkest-gray">Update Password</h2>

            <p class="mt-1 text-sm text-iel-dark-gray">
                Ensure your account is using a long, random password to stay secure.
            </p>
        </header>

        <form
            class="mt-4 grid gap-4"
            @submit.prevent="updatePassword">
            <AppInput
                id="current_password"
                v-model="form.current_password"
                name="current_password"
                type="password"
                required
                label="Current Password"
                placeholder="Password"
                :error="form.errors.current_password" />
            <AppInput
                id="password"
                v-model="form.password"
                name="password"
                type="password"
                required
                label="New Password"
                placeholder="Password"
                :error="form.errors.password" />
            <AppInput
                id="password_confirmation"
                v-model="form.password_confirmation"
                name="password_confirmation"
                type="password"
                required
                label="Confirm Password"
                placeholder="Password"
                :error="form.errors.password_confirmation" />
            <div>
                <AppButton
                    type="submit"
                    :disabled="form.processing">
                    Save
                </AppButton>
            </div>
            <Transition
                enter-from-class="opacity-0"
                leave-to-class="opacity-0"
                class="transition ease-in-out">
                <p
                    v-if="form.recentlySuccessful"
                    class="text-sm text-iel-dark-gray">
                    Saved.
                </p>
            </Transition>
        </form>
    </section>
</template>
