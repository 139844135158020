<template>
    <GuestLayout>
        <Head title="Email Verification" />
        <p class="mb-4 text-sm text-iel-dark-gray">
            Thanks for signing up! Before getting started, could you verify your email address by clicking on the link
            we just emailed to you? If you didn't receive the email, we will gladly send you another.
        </p>
        <div
            v-if="verificationLinkSent"
            class="mb-4 text-sm font-medium text-iel-green">
            A new verification link has been sent to the email address you provided during registration.
        </div>
        <form @submit.prevent="submit">
            <div>
                <AppButton
                    type="submit"
                    block
                    :disabled="form.processing">
                    Resend Verification Email
                </AppButton>

                <Link
                    :href="route('logout')"
                    method="post"
                    as="button"
                    class="mt-2 block rounded-md text-sm text-iel-dark-gray underline hover:text-iel-darkest-gray focus:outline-none focus:ring-2 focus:ring-iel-light-blue focus:ring-offset-2">
                    Log Out
                </Link>
            </div>
        </form>
    </GuestLayout>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    status: {
        type: String
    }
});

const form = useForm({});

function submit() {
    form.post(route('verification.send'));
}

const verificationLinkSent = computed(() => props.status === 'verification-link-sent');
</script>
