<template>
    <AppModal
        id="issue-comcheck-modal"
        :show="isOpen"
        :title="$t(context.title ?? 'Comcheck')"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="submit(context.advance)">
            <div class="grid gap-3 md:grid-cols-2 xl:grid-cols-3">
                <AppSelect
                    v-model="form.sent_to"
                    name="sent_to"
                    :options="sentToOptions"
                    required />

                <AppInput
                    v-model="form.first_name"
                    name="first_name"
                    label="First Name"
                    required />
                <AppInput
                    v-model="form.last_name"
                    name="last_name"
                    label="Last Name"
                    required />
                <AppSelect
                    v-model="form.sent_via"
                    name="sent_via"
                    label="Contact Type"
                    :options="{ email: 'Email', text: 'Text' }"
                    required />

                <AppInput
                    v-if="form.sent_via === 'email'"
                    v-model="form.email"
                    name="email"
                    label="Email"
                    type="email"
                    required />
                <AppInput
                    v-if="form.sent_via === 'text'"
                    v-model="form.phone_number"
                    name="phone_number"
                    label="Phone Number"
                    type="tel"
                    required />

                <AppSelect
                    v-model="form.reason"
                    name="reason"
                    :options="filteredLoadComcheckReasons" />
                <AppInput
                    v-model="form.amount"
                    type="number"
                    name="amount"
                    placeholder="$0.00"
                    required
                    step="0.01" />
                <AppInput
                    v-model="form.fee"
                    type="string"
                    name="fee"
                    placeholder="$0.00"
                    disabled />
            </div>
            <div class="my-2 grid grid-cols-3 gap-2">
                <AppCheckbox
                    v-model="form.waive_fee"
                    name="waive_fee" />
                <AppCheckbox
                    v-model="form.receipt_required"
                    name="receipt_required" />
                <AppCheckbox
                    v-model="form.charge_carrier"
                    name="charge_carrier" />
            </div>
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Submit') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('LoadComcheckModal');

const form = useForm({
    sent_to: '',
    first_name: '',
    last_name: '',
    contact_info: [],
    sent_via: '',
    email: '',
    phone_number: '',
    reason: '',
    amount: null,
    fee: null,
    waive_fee: false,
    receipt_required: false,
    charge_carrier: false
});

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();
    if (!value) return;

    if (context.value.mode === 'edit') {
        form.sent_to = context.value.advance.sent_to;
        form.first_name = context.value.advance.first_name;
        form.last_name = context.value.advance.last_name;
        form.sent_via = context.value.advance.sent_via;

        form.sent_via === 'email'
            ? (form.email = context.value.advance.contact_info)
            : (form.phone_number = context.value.advance.contact_info);

        form.reason = context.value.advance.reason;
        form.amount = context.value.advance.amount;
        form.fee = context.value.advance.fee;
        form.waive_fee = context.value.advance.waive_fee;
        form.receipt_required = context.value.advance.receipt_required;
        form.charge_carrier = context.value.advance.charge_carrier;
    }
});

watch(
    () => form.reason,
    newValue => {
        form.fee = context.value.load.carrier.advance_reasons.third_party.find(reason => reason.name === newValue)?.fee;
    }
);

const sentToOptions = computed(() => {
    const options = {};

    const carrier = context.value.load.carrier;

    if (carrier.advance_reasons.driver.length) {
        options.driver = 'Driver';
    }

    if (carrier.advance_reasons.dispatcher.length) {
        options.dispatcher = 'Dispatcher';
    }

    options.third_party = 'Third Party';

    return options;
});

const filteredLoadComcheckReasons = computed(() => {
    const carrier = context.value.load.carrier;
    if (form.sent_to === 'driver') {
        return carrier.advance_reasons.driver
            .map(reason => reason.name)
            .reduce((acc, curr) => ((acc[curr] = curr), acc), {});
    } else if (form.sent_to === 'dispatcher') {
        return carrier.advance_reasons.dispatcher
            .map(reason => reason.name)
            .reduce((acc, curr) => ((acc[curr] = curr), acc), {});
    } else if (form.sent_to === 'third_party') {
        return carrier.advance_reasons.third_party
            .map(reason => reason.name)
            .reduce((acc, curr) => ((acc[curr] = curr), acc), {});
    }
    return {};
});

function submit() {
    const carrier = context.value.load.carrier;
    const loadId = context.value.load.id;

    if (context.value.mode === 'create') {
        form.post(route('comcheck.store', { carrier: carrier.id, load: loadId }), {
            preserveScroll: true,
            onSuccess: () => {
                close();
            }
        });
    }

    if (context.value.mode === 'edit') {
        form.patch(route('comcheck.update', { advance: context.value.advance }), {
            preserveScroll: true,
            onSuccess: () => {
                close();
            }
        });
    }
}
</script>
