import { debounce } from 'lodash-es';
import { router } from '@inertiajs/vue3';

/**
 * Sync a piece of reactive state with a query parameter in the current URL,
 * using Inertia partial reloads to refresh the page after each change.
 */
export function useQueryParam(visitParams, param = 'q') {
    // Set `q`'s value to the value of that URL query parameter, if it's set
    const q = ref(new URLSearchParams(location.search).get(param));

    // When `q` changes (e.g. when a user types into a search input in a component using this hook), update
    // the value of the `q` URL query parameter, or remove the query parameter completely if `q` is empty,
    // and then perform a partial reload of the current page to refresh the list of resources being
    // searched. To avoid excessive requests, update and refresh at most every 300ms.
    watch(
        q,
        debounce(value => {
            const url = new URL(location);
            const params = new URLSearchParams(url.search);
            value === '' ? params.delete(param) : params.set(param, value);
            url.search = params;
            router.visit(url, visitParams);
        }, 300)
    );

    return { q };
}
