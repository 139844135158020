<template>
    <div class="grid gap-4">
        <div class="grid grid-cols-2 gap-4">
            <AppDisplayField
                :id="`load-${load.id}-load-billing-contact`"
                :value="load.customer.billing_contact_name"
                label="Billing Contact" />
            <AppDisplayField
                :id="`load-${load.id}-billing-method`"
                :value="load.customer.billing_method"
                label="Billing Method" />
        </div>
        <AppDisplayField
            :id="`load-${load.id}-billing-address`"
            :value="load.customer.billing_contact_emails"
            label="Billing Address" />
        <AppDisplayField
            :id="`load-${load.id}-billing-notes`"
            :value="load.customer.billing_notes"
            label="Billing Notes" />
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();
const load = computed(() => page.props.load);
</script>
