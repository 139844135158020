<template>
    <div class="flex items-center">
        <AppIcon
            :name="mpPartner.icon"
            :class="mpPartner.color"
            class="mx-2 h-5 w-5" />
        <p :class="mpPartner.color">
            {{ $t(mpPartner.text) }}
        </p>
    </div>
</template>

<script setup>
const props = defineProps({
    carrier: Object
});

const date = computed(() =>
    props.carrier.macropoint_checked_at ? new Date(props.carrier.macropoint_checked_at + 'Z') : null
);

const formattedDate = computed(() =>
    date?.value?.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
        timeZone: 'America/New_York'
    })
);

const mpPartner = ref({
    icon: '',
    color: '',
    text: ''
});

onMounted(() => {
    updateMpPartner(props.carrier);
});

const updateMpPartner = updatedCarrier => {
    const { macropoint_checked_at, is_macropoint_partnered } = updatedCarrier;
    if (macropoint_checked_at) {
        mpPartner.value = {
            icon: is_macropoint_partnered ? 'fal fa-circle-check' : 'fal fa-circle-xmark',
            color: is_macropoint_partnered ? 'text-iel-dark-green' : 'text-iel-red',
            text: `Carrier is ${is_macropoint_partnered ? '' : 'not '}partnered with MacroPoint as of ${formattedDate.value}`
        };
    } else {
        mpPartner.value = {
            icon: 'fal fa-circle-info',
            color: 'text-iel-grey',
            text: 'Carrier MacroPoint partner status has not been verified.'
        };
    }
};

watch(() => props.carrier, updateMpPartner);
</script>
