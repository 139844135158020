<template>
    <div>
        <Doughnut
            :id="kebabCase(title) + '-bar-graph'"
            :options="graphOptions"
            :data="data"
            aria-label="Doughnut graph"
            role="img">
            <p>Could not load graph.</p>
        </Doughnut>

        <AppGraphScreenReader
            :id="kebabCase(title) + '-graph-data-for-screen-readers'"
            :title="props.title"
            :data="props.data" />
    </div>
</template>

<script setup>
import { Doughnut } from 'vue-chartjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { kebabCase } from 'lodash-es';

ChartJS.register(ArcElement, Tooltip, Legend);

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    data: {
        type: [Object, Array],
        default: () => []
    },
    showTotal: {
        type: Boolean,
        default: true
    },
    showLegend: {
        type: Boolean,
        default: true
    },
    legendTitle: {
        type: String,
        default: ''
    }
});

const emit = defineEmits(['updateData']);

// trigger function in graphOptions, get & send two dependencies up to parent
const triggerParentFunctionFromGraph = (event, element) => {
    emit('updateData', { event, element });
};

const graphOptions = ref({
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
    },
    plugins: {
        title: {
            display: false,
            text: props.title
        },
        legend: {
            display: props.showLegend,
            position: 'right',
            align: 'middle',
            title: {
                display: props.legendTitle.length > 0,
                text: props.legendTitle,
                font: {
                    size: 16,
                    weight: 'bold'
                }
            },
            labels: {
                boxWidth: 8,
                boxHeight: 8,
                borderRadius: 1000,
                useBorderRadius: true,
                usePointStyle: true,
                padding: 25,
                color: '#002a40',
                font: {
                    size: 14
                },
                generateLabels: function (chart) {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                        return data.labels.map((label, i) => {
                            const dataset = data.datasets[0];
                            const value =
                                dataset.formattedData && dataset.formattedData[i]
                                    ? dataset.formattedData[i]
                                    : dataset.data[i];
                            return {
                                text: `${label} (${value})`,
                                fillStyle: dataset.backgroundColor[i],
                                strokeStyle: dataset.backgroundColor[i],
                                index: i
                            };
                        });
                    }
                    return [];
                }
            }
        },
        totalSum: {
            enabled: props.showTotal
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    const labelIndex = context.dataIndex;
                    const dataset = context.dataset;
                    return dataset.formattedData && dataset.formattedData[labelIndex]
                        ? dataset.formattedData[labelIndex]
                        : dataset.data[labelIndex];
                }
            }
        }
    },
    onClick: triggerParentFunctionFromGraph
});

const totalSum = {
    id: 'totalSum',
    afterDraw: chart => {
        if (!chart.options.plugins.totalSum.enabled) return;
        if (chart.config.type === 'doughnut') {
            const ctx = chart.ctx;
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2 + 7;
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = '70px Roboto';
            ctx.fillStyle = '#002a40';

            let total = 0;
            chart.data.datasets.forEach(dataset => {
                total += dataset.data.reduce((sum, value) => sum + value, 0);
            });

            if (chart.data.datasets[0].totalSum) {
                total = Math.round(chart.data.datasets[0].totalSum);
            }

            ctx.fillText(total, centerX, centerY);
            ctx.restore();
        }
    }
};

// Immediately invoke function to ensure the plugin is registered
(() => {
    ChartJS.register(totalSum);
})();
</script>

<style scoped>
canvas {
    @apply h-[16.5rem] p-4 md:p-2;
}
</style>
