<template>
    <div class="flex items-center">
        <div
            v-for="tab in tabs"
            :key="tab"
            class="text-md mb-4 cursor-pointer rounded-md rounded-b-none border-iel-light-gray px-4 py-2"
            :class="[
                activeTab === tab ? 'border-l border-r border-t  font-bold text-iel-blue' : 'border-b text-iel-gray'
            ]"
            @click="activeTab = tab">
            {{ tab }}
        </div>
    </div>
    <div>
        <LoadText
            ref="loadText"
            :active-tab="activeTab"
            class="max-w-screen" />
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();
const tabs = ref([]);
const activeTab = ref();
const loadText = ref();

function activateTab(targetTab) {
    let tab = tabs.value.filter(tab => tab == targetTab)[0];

    if (!tab || tab == undefined) {
        return;
    }

    activeTab.value = targetTab;
    loadText.value.showingCreateMessageForm = true;
}

function setTabs() {
    if (page.props.load.dispatcher?.phone_number) {
        tabs.value.push('Dispatcher');
        activeTab.value = 'Dispatcher';
    }

    if (page.props.load.driver?.phone_number) {
        tabs.value.push('Driver');
        if (!activeTab.value) {
            activeTab.value = 'Driver';
        }
    }

    if (page.props.load.driver2?.phone_number) {
        tabs.value.push('Driver 2');
    }
}

defineExpose({ activateTab });

onMounted(() => {
    setTabs();
});
</script>
