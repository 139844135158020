import { useStorage } from '@vueuse/core';

/**
 * Loadboard state. This works like `reactive()`, which means that any other files that import
 * this will all import the 'same' piece of reactive state, and any changes made to it here
 * or in any of those files will update all the other instances too.
 *
 * See https://vuejs.org/guide/scaling-up/state-management.html#simple-state-management-with-reactivity-api.
 */
export const loadboard = useStorage(
    'nexus-loadboard-state',
    {
        layout: 'regular'
    },
    localStorage,
    {
        mergeDefaults: true
    }
);

export const approvalForm = ref({
    isOpen: false,
    setIsOpen(value) {
        this.isOpen = value;
    },
    setAmount(value) {
        this.amount = value;
    }
});

export const denialForm = ref({
    isOpen: false,
    setIsOpen(value) {
        this.isOpen = value;
    }
});

export const isMobileMenuOpen = ref(false);
