import { startCase } from 'lodash-es';
import { useI18n } from 'vue-i18n';

/**
 * Set up shared state for a form field.
 */
export function useField(props, error) {
    const { can } = useAuth();
    const { t } = useI18n();
    const scope = props.scope;

    // Compute field name to use in the DOM based on the name prop and (optional) scope
    const name = computed(() => (scope ? `${props.name}-${scope}` : props.name));

    // Compute the label based on optional prop or capitalized field name
    const label = computed(() => props.label ?? startCase(props.name));

    const unauthorized = computed(() => props.permission && !can(props.permission));

    // Generate attributes to apply directly to a field's HTML input element
    const attributes = computed(() => ({
        // Use scoped field name for 'id' attribute
        id: props.id || name.value,
        // Use scoped field name for 'name' attribute
        name: name.value,
        // Add conditional styles for invalid/disabled states
        class: {
            'border-iel-light-red text-iel-dark-red placeholder:text-iel-light-red': error.value,
            'cursor-not-allowed bg-iel-silver text-iel-dark-gray': props.disabled || unauthorized.value
        },
        // Pass through other state
        placeholder: props.placeholder,
        required: props.required,
        disabled: props.disabled || unauthorized.value,
        title: unauthorized.value ? t('Unauthorized') : undefined,
        // Compute aria attributes based on error state
        'aria-invalid': error.value ? true : undefined,
        'aria-describedby': error.value ? `${name.value}-error` : undefined,
        // Selector for unit tests
        'data-test': 'input'
    }));

    return {
        label,
        attributes
    };
}
