export function formatDate(date) {
    if (!date) return '-';
    return new Date(date).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });
}

export function useLoadDisabled(load) {
    if (load.is_canceled) return true;

    if (load.is_tonu) return true;
}
