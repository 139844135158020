<template>
    <Bar
        :id="kebabCase(title) + '-bar-graph'"
        :options="graphOptions"
        :data="data"
        aria-label="Bar graph"
        role="img">
        <p>Could not load graph.</p>
    </Bar>

    <AppGraphScreenReader
        :id="kebabCase(title) + 'graph-data-for-screen-readers'"
        :title="props.title"
        :data="props.data" />
</template>

<script setup>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { kebabCase } from 'lodash-es';

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale);

const props = defineProps({
    indexAxis: {
        type: String,
        default: 'x'
    },
    title: {
        type: String,
        required: true
    },
    data: {
        type: [Object, Array],
        default: () => []
    }
});

const graphOptions = ref({
    responsive: true,
    maintainAspectRatio: false,
    label: false,
    borderRadius: 6,
    borderSkipped: false,
    indexAxis: props.indexAxis,
    plugins: {
        // Title must be set in order to generate ID for screen reader and graph canvas
        title: {
            text: props.title
        },
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            border: {
                display: false
            },
            ticks: {
                color: '#002a40',
                stepSize: 20,
                maxRotation: 0
            }
        },
        y: {
            grid: {
                display: false
            },
            border: {
                display: false
            },
            ticks: {
                color: '#002a40',
                stepSize: 20
            }
        }
    }
});
</script>
<style scoped>
canvas {
    @apply h-64 w-auto;
}
</style>
