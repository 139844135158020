<template>
    <AppDropdown
        align="bottom-right"
        class="block w-auto rounded border border-iel-light-gray p-1">
        <template #trigger>
            <button
                id="loadboard-filters-dropdown"
                class="flex w-full items-center justify-between px-2 md:text-xs lg:text-base"
                type="button">
                <span>{{ current }}</span>
                <AppIcon
                    name="far fa-chevron-down"
                    class="ml-4 h-3 w-3" />
            </button>
        </template>
        <template #content>
            <Link
                v-for="({ label, color, icon }, key) in filters"
                :key="key"
                :href="rootUrl.replace(/page=\d+/, '')"
                :data="{ [param]: key === 'default' ? undefined : key }"
                class="flex w-full items-center p-2 text-sm transition hover:bg-iel-blue hover:text-white"
                :class="{ 'bg-iel-blue text-white': key === current || (key === 'default' && !current) }"
                v-bind="linkParams"
                :data-test="`link-${key}`">
                <AppIcon
                    v-if="icon && icon !== 'warning'"
                    :name="icon"
                    class="h-5 w-5" />

                <AppWarningIcon
                    v-else-if="icon === 'warning'"
                    size="md" />
                <div
                    v-else-if="color"
                    class="h-5 w-5 rounded-full border border-white"
                    :class="color" />
                <span class="ml-4">
                    {{ label ?? startCase(key) }}
                </span>
            </Link>
        </template>
    </AppDropdown>
</template>

<script setup>
import { startCase } from 'lodash-es';
import { usePage } from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    rootUrl: {
        type: String
    },
    filters: {
        type: Object,
        required: true
    },
    linkParams: {
        type: Object
    }
});

const param = 'filter';

const page = usePage();

const rootUrl = computed(() => props?.rootUrl ?? page.url);

const current = computed(() => props.filters[page.props.filter || 'default']?.label);
</script>
