<template>
    <div
        aria-live="assertive"
        class="pointer-events-none fixed inset-0 mt-10 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <transition
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <div
                    v-if="show"
                    role="error"
                    class="pointer-events-auto w-full max-w-xl overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="mx-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium text-gray-900">A server error has occured.</p>
                                <p class="mt-1 text-sm text-gray-500">Status: {{ page.props.response.status }}</p>
                                <p class="mt-1 text-sm text-gray-500">{{ page.props.response.message }}</p>
                            </div>
                            <div class="ml-4 flex flex-shrink-0">
                                <button
                                    id="close-error-notification"
                                    type="button"
                                    class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    @click="show = false">
                                    <span class="sr-only">Close</span>
                                    <AppIcon
                                        name="far fa-xmark"
                                        class="h-5 w-5" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();
const show = ref(false);

watch(
    () => page.props.response.timestamp,
    () => {
        if (page.props.response.status > 400) {
            show.value = true;
        }
    }
);
</script>
