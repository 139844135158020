<template>
    <Head title="Carriers" />
    <AppHeader
        title="Carriers"
        :icon-component="IconCarriers">
        <template #search>
            <AppSearchInput
                id="carriers-list-search"
                v-model="q" />
        </template>
        <template #actions>
            <AppFilterPills
                id="carriers-list-filters"
                class="lg:ml-auto"
                :filters="{
                    default: { label: 'All' },
                    active: { color: 'bg-iel-green' },
                    inactive: { color: 'bg-iel-red' }
                }"
                :link-params="{ only: ['carriers'] }"
                :reset-pagination="true" />
            <div>
                <AppButton
                    id="add-carrier-button"
                    icon="far fa-circle-plus"
                    @click="createCarrier">
                    {{ $t('Add Carrier') }}
                </AppButton>
            </div>
        </template>
    </AppHeader>
    <slot />
</template>

<script setup>
import IconCarriers from '/resources/img/icon-carriers.svg';

const props = defineProps({
    carrier: Object
});

const { open: carrierModal } = useModal('CarrierCreateModal');

function createCarrier() {
    carrierModal({
        carrier: props.carrier
    });
}

// Sync the `q` query parameter in the URL with the value of the search input, and
// reload the data in the 'carriers' prop (but nothing else) when it changes
const { q } = useQueryParam({ only: ['carriers'] });
</script>
