<template>
    <AppModal
        id="create-carrier-modal"
        :show="isOpen"
        title="Create New Carrier"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="handleSubmit">
            <div class="grid grid-cols-2 gap-4">
                <AppSelect
                    v-model="form.fmcsa_type"
                    name="fmcsa_type"
                    :label="$t('Carrier ID')"
                    :options="{ DOT: 'DOT #', Permit: 'Permit #' }"
                    class="col-span-1"
                    required />
                <AppInput
                    v-if="form.fmcsa_type === 'DOT'"
                    v-model="form.dot_number"
                    name="dot_number"
                    label=""
                    placeholder="DOT #"
                    class="col-span-1 self-end"
                    required />
                <AppInput
                    v-else-if="form.fmcsa_type === 'Permit'"
                    v-model="form.permit_number"
                    name="permit_number"
                    label=""
                    placeholder="Permit #"
                    class="col-span-1 self-end"
                    required />
            </div>
            <AppDisplayField
                v-if="form.fmcsa_type === 'DOT'"
                :value="carrierName"
                label="Name"
                name="name"
                required />
            <AppInput
                v-if="form.fmcsa_type !== 'DOT'"
                v-model="form.name"
                name="name"
                required />
            <AppErrorMessage
                v-if="carrierNameError"
                :error="carrierNameError"
                name="name" />
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    v-if="form.fmcsa_type === 'DOT'"
                    variant="outline"
                    @click="search(form.dot_number)">
                    {{ $t('Search') }}
                </AppButton>
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    type="submit"
                    :disabled="!carrierName && form.fmcsa_type === 'DOT'">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CarrierCreateModal');

const form = useForm({
    name: null,
    fmcsa_type: null,
    dot_number: '',
    mx_number: '',
    permit_number: '',
    mc_number: null,
    is_active: false
});

provide('form', form);

watch(isOpen, () => {
    // Reset form and clear errors whenever modal is opened or closed
    form.reset();
    form.clearErrors();
    carrierName.value = '';
    carrierNameError.value = '';
});

function handleSubmit() {
    if (carrierName.value) {
        form.name = carrierName.value;
    }
    form.post(route('carriers.store', context.value.carrierDetail), {
        preserveScroll: true,
        onSuccess() {
            close();
        }
    });
}

const carrierName = ref('');
const carrierNameError = ref('');

// if user edits dot_number after searching, the searched name should be cleared -- PX-1677
watch(
    () => form.dot_number,
    () => {
        if (carrierName.value) {
            carrierName.value = null;
        }
    }
);

function search(dot) {
    carrierName.value = null;
    carrierNameError.value = null;

    fetch(`/verification/search?dot_number=${dot}`)
        .then(response => response.json())
        .then(data => {
            if (data.name) {
                carrierName.value = data.name;
            }
            if (data.error) {
                carrierNameError.value = data.error;
            }
        });
}
</script>
