<template>
    <div>
        <AppHeading
            size="sm"
            class="pb-2 text-iel-gray">
            {{ $t('Load Notes') }}
        </AppHeading>
        <FlaggedCreateNoteForm
            v-if="showingCreateNoteForm"
            :load="load"
            @success="onNoteCreated" />
        <div class="flex justify-end pr-1">
            <AppButton
                id="load-create-note-button"
                icon="far fa-circle-plus"
                @click="showingCreateNoteForm = true">
                {{ $t('Create Note') }}
            </AppButton>
        </div>

        <ul
            v-if="load.notes.length > 0"
            role="list"
            class="my-4 max-h-96 space-y-2 overflow-y-auto px-1"
            tabindex="0">
            <li
                v-for="note in notes"
                :id="`note-${note.id}`"
                :key="note.id">
                <AppMessage
                    :user="note.user_name"
                    :title="note.title"
                    :body="note.content"
                    :created-at="note.created_at"
                    :icon="note.icon"
                    size="md" />
            </li>
        </ul>

        <div v-else>{{ $t('No notes found on this load.') }}</div>
    </div>
</template>

<script setup>
const props = defineProps({
    load: Object
});

const emit = defineEmits(['success']);

const showingCreateNoteForm = ref(false);

const initialNoteIds = ref([]);

const noteTypes = {
    claim: { title: 'Claim', icon: 'fas fa-gavel' },
    carrier_relations: { title: 'Carrier Relations', icon: 'fas fa-handshake' }
};

const notes = computed(() =>
    props.load.notes.map(note => ({
        ...note,
        icon: note.type ? noteTypes[note.type].icon : null,
        title: note.type ? noteTypes[note.type].title : null
    }))
);

provide('showingCreateNoteForm', showingCreateNoteForm);

onMounted(() => (initialNoteIds.value = props.load.notes.map(({ id }) => id)));

function onNoteCreated() {
    showingCreateNoteForm.value = false;
    emit('success');
}
</script>
