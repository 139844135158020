<template>
    <GuestLayout>
        <Head title="Register" />

        <form
            v-if="allowRegistration"
            class="grid gap-4"
            @submit.prevent="submit">
            <AppInput
                id="name"
                v-model="form.name"
                name="name"
                type="text"
                required
                label="Name"
                placeholder="Name"
                :error="form.errors.name" />
            <AppInput
                id="name"
                v-model="form.first_name"
                name="name"
                type="text"
                required
                label="Legal First Name"
                placeholder="Legal First Name"
                :error="form.errors.first_name" />
            <AppInput
                id="name"
                v-model="form.last_name"
                name="name"
                type="text"
                required
                label="Last Name"
                placeholder="Last Name"
                :error="form.errors.last_name" />
            <AppInput
                id="email"
                v-model="form.email"
                name="email"
                type="email"
                required
                label="Email"
                placeholder="Email"
                :error="form.errors.email" />
            <AppInput
                id="password"
                v-model="form.password"
                name="password"
                type="password"
                required
                label="Password"
                placeholder="Password"
                :error="form.errors.password" />
            <AppInput
                id="password"
                v-model="form.password_confirmation"
                name="password_confirmation"
                type="password"
                required
                label="Confirm Password"
                placeholder="Password"
                :error="form.errors.password_confirmation" />
            <AppButton
                id="register-button"
                class="py-2"
                type="submit"
                block
                :disabled="form.processing">
                Register
            </AppButton>
        </form>

        <Link
            :href="route('login')"
            class="mt-2 block rounded-md text-sm text-iel-dark-gray underline hover:text-iel-darkest-gray focus:outline-none focus:ring-2 focus:ring-iel-light-blue focus:ring-offset-2">
            Already registered?
        </Link>

        <!-- If user registration is not allowed, let the user know and direct them to login  -->
        <div
            v-if="!allowRegistration"
            class="text-sm text-iel-dark-gray">
            Registration is not allowed. Please
            <Link
                :href="route('login')"
                class="mt-2 rounded-md text-sm text-iel-dark-gray underline hover:text-iel-darkest-gray focus:outline-none focus:ring-2 focus:ring-iel-light-blue focus:ring-offset-2">
                log in
            </Link>
            instead.
        </div>
    </GuestLayout>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

defineProps({
    allowRegistration: {
        type: Boolean
    }
});

const form = useForm({
    name: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: ''
});

function submit() {
    form.post(route('register'), {
        onFinish: () => form.reset('password', 'password_confirmation')
    });
}
</script>
