import { useForm } from '@inertiajs/vue3';

/**
 * Set up an Inertia form and some state management helpers for a single auto-saving field.
 */
export function useAutoSave(props, key = 'value', transformer = v => v) {
    // Create an Inertia form with only one field, so we can send this field's data
    // to the backend as if we were submitting a whole form every time it changes
    const form = useForm({ [props.name]: props[key] });

    // This basically functions as a 'wrapper' or proxy to `form[props.name]`, so that we can
    // expose just a plain `value` to the field component that it can v-model on directly.
    // The value of this `value` computed will be the value of the form field, and setting
    // it to a different value will update the value of the form field to that value.
    const value = computed({
        get: () => form[props.name],
        set: v => (form[props.name] = v)
    });

    // Allow consuming components to update the value of an autosave field from 'outside', by
    // watching the `value` prop, manually updating the value of the internal form field to
    // the new prop value, and resetting the form's defaults so it isn't considered dirty.
    // This allows autosave fields to react to the parent data on the page changing.
    watch(
        () => props[key],
        () => {
            form[props.name] = props[key];
            form.defaults();
        }
    );

    // Submit the form if its value has changed
    function submit() {
        if (props.confirmation && !confirm(props.confirmation)) {
            form.reset(props.name);
            return;
        }

        if (form.isDirty && form[props.name] !== undefined) {
            form.transform(transformer).patch(props.url, {
                preserveScroll: true
            });
        }
    }

    return {
        value,
        submit,
        // Provide metadata about the state of the form/field to the field component consuming
        // this hook. `error` is the validation error message for this field if there is one
        processing: computed(() => form.processing),
        recentlySuccessful: computed(() => form.recentlySuccessful),
        error: computed(() => form.errors[props.name]),
        clearError: () => form.clearErrors()
    };
}
