<template>
    <AppDropdown align="bottom-right">
        <template #trigger>
            <button
                :id="`customer-group-${customerGroup.id}-actions-trigger`"
                class="flex"
                type="button"
                aria-label="dropdown menu to select load action options">
                <AppIcon
                    name="far fa-ellipsis"
                    class="cursor-pointer text-white" />
            </button>
        </template>
        <template #content>
            <button
                :id="`customer-group-${customerGroup.id}-add-customer-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                type="button"
                @click="addCustomer">
                {{ $t('Add Customer to Group') }}
            </button>
        </template>
    </AppDropdown>
</template>

<script setup>
const props = defineProps({
    customerGroup: Object
});

const { open: openCustomerGroupAddCustomerModal } = useModal('CustomerGroupAddCustomerModal');

function addCustomer() {
    openCustomerGroupAddCustomerModal({
        customerGroup: props.customerGroup
    });
}
</script>
