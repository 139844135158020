<template>
    <section>
        <header>
            <h2 class="text-lg font-medium text-iel-darkest-gray">Profile Information</h2>

            <p class="mt-1 text-sm text-iel-dark-gray">Update your account's profile information and email address.</p>
        </header>

        <form
            class="mt-4 grid gap-4"
            @submit.prevent="form.patch(route('profile.update'))">
            <AppInput
                id="name"
                v-model="form.name"
                name="name"
                type="text"
                required
                label="Name"
                placeholder="Name"
                :error="form.errors.name" />
            <AppInput
                id="email"
                v-model="form.email"
                name="email"
                type="email"
                required
                label="Email"
                placeholder="Email"
                :error="form.errors.email" />
            <div>
                <AppButton
                    type="submit"
                    :disabled="form.processing">
                    Save
                </AppButton>
            </div>
            <Transition
                enter-from-class="opacity-0"
                leave-to-class="opacity-0"
                class="transition ease-in-out">
                <p
                    v-if="form.recentlySuccessful"
                    class="text-sm text-iel-dark-gray">
                    Saved.
                </p>
            </Transition>
        </form>

        <div v-if="mustVerifyEmail && user.email_verified_at === null">
            <p class="mt-2 text-sm text-iel-dark-gray">
                Your email address is unverified.
                <Link
                    :href="route('verification.send')"
                    method="post"
                    as="button"
                    class="rounded-md text-sm text-iel-dark-gray underline hover:text-iel-darkest-gray focus:outline-none focus:ring-2 focus:ring-iel-light-blue focus:ring-offset-2">
                    Click here to re-send the verification email.
                </Link>
            </p>

            <div
                v-show="status === 'verification-link-sent'"
                class="mt-2 text-sm font-medium text-iel-green">
                A new verification link has been sent to your email address.
            </div>
        </div>
    </section>
</template>

<script setup>
import { Link, useForm, usePage } from '@inertiajs/vue3';

defineProps({
    mustVerifyEmail: {
        type: Boolean
    },
    status: {
        type: String
    }
});

const user = usePage().props.auth.user;

const form = useForm({
    name: user.name,
    email: user.email
});
</script>
