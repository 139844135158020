<template>
    <nav class="flex h-16 items-center gap-4 border-b border-iel-silver bg-white px-4 md:px-6">
        <button
            id="hamburger"
            aria-label="hamburger button to open main navigation"
            class="-m-2 rounded-md p-2 text-iel-dark-gray active:bg-iel-silver active:text-iel-dark-gray active:outline-none xl:hidden"
            @click="$emit('openMobileMenu')">
            <AppIcon
                name="far fa-bars"
                class="h-6 w-6 stroke-current" />
        </button>

        <div
            class="h-6 w-px bg-iel-darkest-gray/10 lg:hidden"
            aria-hidden="true" />

        <AppTopbarSearch />

        <div class="relative ml-auto">
            <AppDropdown
                align="bottom-right"
                width="48">
                <template #trigger>
                    <button
                        id="topbar-user-dropdown-trigger"
                        class="flex items-center"
                        type="button">
                        <AppAvatar
                            v-bind="{ user }"
                            class="h-8 w-8" />
                        <div
                            class="ml-3 hidden text-sm font-semibold capitalize leading-6 text-iel-darkest-gray xl:block">
                            {{ user.name }}
                        </div>
                        <AppIcon
                            name="far fa-chevron-down"
                            class="ml-2 h-4 w-4" />
                    </button>
                </template>
                <template #content>
                    <AppDropdownLink
                        id="profile-link"
                        :href="route('profile.index')">
                        Settings
                    </AppDropdownLink>
                    <AppDropdownLink
                        id="logout-link"
                        :href="route('logout')"
                        method="post"
                        as="button"
                        @click.prevent="handleLogout">
                        {{ $t('Log out') }}
                    </AppDropdownLink>
                </template>
            </AppDropdown>
        </div>
    </nav>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';

defineEmits(['openMobileMenu']);

const user = computed(() => usePage().props.auth.user);

function handleLogout() {
    Sentry.setUser(null);
}
</script>
