<template>
    <AppTablePill
        :columns="[
            { name: 'name', label: 'Name' },
            { name: 'address', label: 'Address' },
            { name: 'city', label: 'City' },
            { name: 'region', label: 'Region' },
            { name: 'postal_code', label: 'Postal Code' },
            { name: 'third_party_scheduler', label: 'Third Party Scheduler' }
        ]"
        :data="locations">
        <template #name-cell="{ row }">
            <Link
                :href="route('locations.show', row.id)"
                class="font-bold text-iel-blue">
                {{ row.name }}
            </Link>
        </template>
        <template #third_party_scheduler-cell="{ row }">
            <div>
                <a
                    v-if="row.ship_third_party_scheduler"
                    :href="row.ship_third_party_scheduler"
                    target="_blank">
                    {{ $t('Shipping') }}
                </a>
                <span v-if="row.ship_third_party_scheduler && row.receive_third_party_scheduler">|</span>
                <a
                    v-if="row.receive_third_party_scheduler"
                    :href="row.receive_third_party_scheduler"
                    target="_blank">
                    {{ $t('Receiving') }}
                </a>
            </div>
        </template>
    </AppTablePill>
    <AppPagination :meta="props.locations.meta" />
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import LocationLayout from '@/Layouts/LocationLayout.vue';

defineOptions({
    layout: [AuthenticatedLayout, LocationLayout]
});

const props = defineProps({
    locations: Object
});

const locations = computed(() =>
    props.locations.data.map(location => ({
        id: location.id,
        name: location.name,
        address: location.address.address_1,
        city: location.address.city,
        region: location.address.region,
        postal_code: location.address.postal_code,
        ship_third_party_scheduler: location.shippingDetails.third_party_scheduler_url,
        receive_third_party_scheduler: location.receivingDetails.third_party_scheduler_url
    }))
);
</script>
