<template>
    <AppModal
        id="create-note-modal"
        :show="isOpen"
        title="Create Note"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="handleSubmit">
            <AppTextarea
                v-model="form.content"
                name="content"
                label="Add Note"
                required />
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Save') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('LoadNoteCreateModal');

const form = useForm({
    content: '',
    noteable_type: 'load',
    noteable_id: null,
    type: null
});

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();

    if (value) {
        // Set default form values when modal is opened
        form.noteable_id = context.value.load.id;
        form.type = context.value.type;
    }
});

function handleSubmit() {
    form.post(route('loads.notes.store', context.value.load), {
        preserveScroll: true,
        onSuccess() {
            close();
        }
    });
}
</script>
