<template>
    <AppModal
        :show="isOpen"
        :title="context.title ?? 'Create Contact'"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="onConfirm">
            <AppInput
                id="new-customer-contact-name"
                v-model="form.name"
                label="Name"
                name="new-customer-contact-name"
                required />
            <div class="grid grid-cols-2 gap-2">
                <AppInput
                    id="new-customer-contact-phone"
                    v-model="form.phone_number"
                    label="Phone Number"
                    name="new-customer-contact-phone"
                    required />
                <AppInput
                    id="new-customer-contact-email"
                    v-model="form.email_address"
                    label="Email"
                    name="new-customer-contact-email" />
            </div>
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="save-customer-contact"
                    type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CustomerContactModal');

const form = useForm({
    name: null,
    phone_number: null,
    email_address: null
});

provide('form', form);

watch(isOpen, value => {
    // Reset form and clear errors whenever modal is opened or closed
    form.reset();
    form.clearErrors();
    if (!value) return;

    if (context.value.mode === 'edit') {
        form.name = context.value.contact.name;
        form.phone_number = context.value.contact.phone_number;
        form.email_address = context.value.contact.email_address;
    }
});

function onConfirm() {
    if (context.value.mode === 'create') {
        form.post(
            route('customers.contacts.store', {
                customer: context.value.customer
            }),
            {
                preserveScroll: true,
                onSuccess() {
                    close();
                }
            }
        );
    }

    if (context.value.mode === 'edit') {
        form.put(
            route('customers.contacts.update', {
                customer: context.value.customer,
                contact: context.value.contact
            }),
            {
                preserveScroll: true,
                onSuccess() {
                    close();
                }
            }
        );
    }
}
</script>
