<template>
    <AppCard
        title="Insurance"
        theme="white"
        class="overflow-visible"
        content-class="@container">
        <form class="grid gap-2 @lg:grid-cols-2">
            <div class="space-y-2">
                <AppCheckbox
                    id="additional_insured"
                    v-model="insurance.additional_insured"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="additional_insured" />
                <AppCheckbox
                    id="auto_liability_trailer_interchange_included"
                    v-model="insurance.auto_liability_trailer_interchange_included"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="auto_liability_trailer_interchange_included" />
                <AppDate
                    v-model="insurance.auto_liability_expiration_date"
                    permission="update_carrier_insurance"
                    name="auto_liability_expiration_date"
                    label="Auto Liability Expiration Date"
                    :url="url" />
                <AppInput
                    id="auto_liability_amount"
                    v-model="insurance.auto_liability_amount"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="auto_liability_amount"
                    type="number"
                    :min="0"
                    step=".01" />
                <AppInput
                    id="auto_liability_provider"
                    v-model="insurance.auto_liability_provider"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="auto_liability_provider"
                    label="Auto Liability Provider" />
                <AppInput
                    id="auto_liability_trailer_interchange_amount"
                    v-model="insurance.auto_liability_trailer_interchange_amount"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="auto_liability_trailer_interchange_amount"
                    label="Auto Liability Trailer Interchange Amount" />
                <AppSelectTags
                    id="auto_liability_coverage_types"
                    v-model="insurance.auto_liability_coverage_types"
                    permission="update_carrier_insurance"
                    name="auto_liability_coverage_types"
                    label="Auto Liability Coverage Types"
                    :placeholder="$t('Select...')"
                    :options="autoLiabilityTypes"
                    :url="url" />
            </div>
            <div class="space-y-2">
                <AppCheckbox
                    id="cargo_trailer_interchange_included"
                    v-model="insurance.cargo_trailer_interchange_included"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="cargo_trailer_interchange_included" />
                <AppCheckbox
                    id="cargo_reefer_breakdown_coverage"
                    v-model="insurance.cargo_reefer_breakdown_coverage"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="cargo_reefer_breakdown_coverage"
                    label="Cargo Reefer Breakdown Coverage" />
                <AppDate
                    v-model="insurance.cargo_expiration_date"
                    permission="update_carrier_insurance"
                    name="cargo_expiration_date"
                    label="Cargo Expiration Date"
                    :url="url" />
                <AppInput
                    id="cargo_amount"
                    v-model="insurance.cargo_amount"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="cargo_amount"
                    label="Cargo Amount" />
                <AppInput
                    id="cargo_provider"
                    v-model="insurance.cargo_provider"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="cargo_provider"
                    label="Cargo Provider" />
                <AppInput
                    id="cargo_trailer_interchange_amount"
                    v-model="insurance.cargo_trailer_interchange_amount"
                    permission="update_carrier_insurance"
                    :url="url"
                    name="cargo_trailer_interchange_amount"
                    label="Cargo Trailer Interchange Amount" />
                <div
                    v-if="page.props.carrier.is_active && page.props.carrier.needs_insurance_update"
                    class="bottom-2 right-2 lg:absolute">
                    <AppButton
                        id="update-insurance-button"
                        icon="far fa-shield-plus"
                        :disabled="page.props.carrier.has_open_ticket"
                        @click="openInsuranceModal(page.props.carrier)">
                        {{ $t('Update Insurance') }}
                    </AppButton>
                </div>
            </div>
        </form>
    </AppCard>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';

const page = usePage();

const url = route('carriers.insurance.update', [page.props.carrier]);

const insurance = computed(() => page.props.carrier.insurance ?? {});

const autoLiabilityTypes = Object.values(page.props.auto_liability_types);

const form = useForm({
    additional_insured: page.props.carrier.insurance?.additional_insured,
    auto_liability_expiration_date: page.props.carrier.insurance?.auto_liability_expiration_date,
    auto_liability_amount: page.props.carrier.insurance?.auto_liability_amount,
    auto_liability_currency: 'USD',
    auto_liability_provider: page.props.carrier.insurance?.auto_liability_provider,
    auto_liability_trailer_interchange_included:
        page.props.carrier.insurance?.auto_liability_trailer_interchange_included,
    auto_liability_trailer_interchange_amount: page.props.carrier.insurance?.auto_liability_trailer_interchange_amount,
    auto_liability_trailer_interchange_currency: 'USD',
    auto_liability_coverage_types: page.props.carrier.insurance?.auto_liability_coverage_types,
    cargo_expiration_date: page.props.carrier.insurance?.cargo_expiration_date,
    cargo_amount: page.props.carrier.insurance?.cargo_amount,
    cargo_currency: 'USD',
    cargo_provider: page.props.carrier.insurance?.cargo_provider,
    cargo_trailer_interchange_included: page.props.carrier.insurance?.cargo_trailer_interchange_included,
    cargo_trailer_interchange_amount: page.props.carrier.insurance?.cargo_trailer_interchange_amount,
    cargo_trailer_interchange_currency: 'USD',
    cargo_reefer_breakdown_coverage: page.props.carrier.insurance?.cargo_reefer_breakdown_coverage
});

provide('form', form);

const { open: CarrierTicketModal } = useModal('CarrierTicketModal');

const openInsuranceModal = carrier => {
    CarrierTicketModal({
        modalId: 'carrier-update-insurance-modal',
        title: 'Update Insurance',
        ticketType: 'Insurance Update',
        carrier: carrier
    });
};
</script>
