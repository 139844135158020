<template>
    <AppCard
        title="FMCSA"
        theme="white"
        content-class="@container">
        <template #nav>
            <AppButton
                v-if="carrier.verification_data?.fmcsaUrl"
                :href="carrier.verification_data?.fmcsaUrl"
                target="_blank">
                {{ $t('View on FMCSA Site') }}
            </AppButton>
        </template>
        <div class="grid gap-2 @lg:grid-cols-2">
            <div class="space-y-2">
                <AppDisplayField
                    :id="`carrier-${carrier.id}-usdot-status`"
                    :class="carrier.verification_data ? 'text-iel-green' : 'text-iel-red'"
                    :value="usdotStatus"
                    label="USDOT Status" />
                <AppDisplayField
                    :id="`carrier-${carrier.id}-rating`"
                    :class="safetyRatingClass"
                    :value="carrier.verification_data?.safetyRating"
                    label="Rating" />
                <AppDisplayField
                    :id="`carrier-${carrier.id}-rating-date`"
                    :value="carrier.verification_data?.safetyRatingDate"
                    label="Rating Date" />
                <AppDisplayField
                    :id="`carrier-${carrier.id}-common-authority`"
                    :class="commonAuthClass"
                    :value="carrier.verification_data?.commonAuthorityStatus"
                    label="Common Authority" />
                <AppDisplayField
                    :id="`carrier-${carrier.id}-contract-authority`"
                    :class="contractAuthClass"
                    :value="carrier.verification_data?.contractAuthorityStatus"
                    label="Contract Authority" />
                <AppDisplayField
                    :id="`carrier-${carrier.id}-broker-authority`"
                    :class="brokerAuthClass"
                    :value="carrier.verification_data?.brokerAuthorityStatus"
                    label="Broker Authority" />
            </div>
            <div class="space-y-2">
                <AppDisplayField
                    :id="`carrier-${carrier.id}-out-of-service-date`"
                    :class="carrier.verification_data?.oosDate ? 'text-iel-red' : ''"
                    :value="carrier.verification_data?.oosDate"
                    label="Out of Service Date" />
                <AppDisplayField
                    :id="`carrier-${carrier.id}-power-units`"
                    :value="carrier.verification_data?.totalPowerUnits"
                    label="Power Units" />
                <AppDisplayField
                    :id="`carrier-${carrier.id}-cargo-hazmat`"
                    :value="cargoHazmat"
                    label="Cargo Hazmat" />
                <AppDisplayField
                    :id="`carrier-${carrier.id}-operation`"
                    :value="carrier.verification_data?.carrierOperationDesc"
                    label="Operation" />
                <AppDisplayField
                    :id="`carrier-${carrier.id}-last-updated`"
                    :value="carrier.verification_data?.lastUpdated"
                    label="Last Updated" />
            </div>
        </div>
        <div class="mt-3 rounded-md border border-iel-dark-gray bg-iel-light-gray px-4 py-6">
            <header class="mb-4 items-center justify-between md:flex">
                <span class="uppercase text-iel-gray">{{ $t('Inspections') }}</span>
            </header>
            <div class="max-h-48 overflow-auto text-sm">
                <table class="relative w-full whitespace-nowrap">
                    <thead>
                        <tr class="mb-2 border-b border-iel-gray text-left text-iel-gray">
                            <th class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0">Inspection Type</th>
                            <th class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0">Vehicle</th>
                            <th class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0">Driver</th>
                            <th class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0">Hazmat</th>
                        </tr>
                    </thead>
                    <tbody v-if="!carrier.verification_data">
                        <tr>
                            <td
                                id="row-no-data"
                                class="py-1 italic text-iel-gray">
                                {{ $t('No data') }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="relative border-b border-iel-light-gray">
                            <td class="px-2 py-1 first:pl-0 last:pr-0">Inspections</td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">{{ carrier.verification_data?.vehicleInsp }}</td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">{{ carrier.verification_data?.driverInsp }}</td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">{{ carrier.verification_data?.hazmatInsp }}</td>
                        </tr>
                        <tr lass="relative border-b border-iel-light-gray">
                            <td class="px-2 py-1 first:pl-0 last:pr-0">Out of Service</td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">
                                {{ carrier.verification_data?.vehicleOosInsp }}
                            </td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">
                                {{ carrier.verification_data?.driverOosInsp }}
                            </td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">
                                {{ carrier.verification_data?.hazmatOosInsp }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mt-3 rounded-md border border-iel-dark-gray bg-iel-light-gray px-4 py-6">
            <header class="mb-4 items-center justify-between md:flex">
                <span class="uppercase text-iel-gray">{{ $t('Crashes') }}</span>
            </header>
            <div class="max-h-48 overflow-auto text-sm">
                <table class="relative w-full whitespace-nowrap">
                    <thead>
                        <tr class="mb-2 border-b border-iel-gray text-left text-iel-gray">
                            <th class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0">Type</th>
                            <th class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0">Fatal</th>
                            <th class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0">Injury</th>
                            <th class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0">Tow</th>
                            <th class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0">Total</th>
                        </tr>
                    </thead>
                    <tbody v-if="!carrier.verification_data">
                        <tr>
                            <td
                                id="row-no-data"
                                class="py-1 italic text-iel-gray">
                                {{ $t('No data') }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="relative border-b border-iel-light-gray">
                            <td class="px-2 py-1 first:pl-0 last:pr-0">Crashes</td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">{{ carrier.verification_data?.fatalCrash }}</td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">{{ carrier.verification_data?.injCrash }}</td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">
                                {{ carrier.verification_data?.towawayCrash }}
                            </td>
                            <td class="px-2 py-1 first:pl-0 last:pr-0">{{ carrier.verification_data?.crashTotal }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            v-if="carrier.verification_data"
            class="grid pt-2">
            <AppButton
                id="update-fmcsa-button"
                class="justify-self-end"
                @click="updateFMCSA">
                {{ $t('Update FMCSA') }}
            </AppButton>
        </div>
    </AppCard>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    carrier: Object
});

const usdotStatus = computed(() => {
    return props.carrier.verification_data ? 'Active' : 'Inactive';
});

const cargoHazmat = computed(() => {
    if (props.carrier.verification_data?.carrierOperationCode === 'B') {
        return 'Yes';
    } else if (!props.carrier.verification_data) {
        return '-';
    } else {
        return 'No';
    }
});

const safetyRatingClass = computed(() => {
    if (
        props.carrier.verification_data?.safetyRating === 'Conditional' ||
        props.carrier.verification_data?.safetyRating === 'Unsatisfactory'
    ) {
        return 'text-iel-red';
    } else {
        return '';
    }
});

const commonAuthClass = computed(() => {
    if (props.carrier.verification_data?.commonAuthorityStatus === 'Active') {
        return 'text-iel-green';
    } else if (props.carrier.verification_data?.commonAuthorityStatus === 'Inactive') {
        return 'text-iel-red';
    } else {
        return '';
    }
});

const contractAuthClass = computed(() => {
    if (props.carrier.verification_data?.contractAuthorityStatus === 'Active') {
        return 'text-iel-green';
    } else if (props.carrier.verification_data?.contractAuthorityStatus === 'Inactive') {
        return 'text-iel-red';
    } else {
        return '';
    }
});

const brokerAuthClass = computed(() => {
    if (props.carrier.verification_data?.brokerAuthorityStatus === 'Active') {
        return 'text-iel-green';
    } else if (props.carrier.verification_data?.brokerAuthorityStatus === 'Inactive') {
        return 'text-iel-red';
    } else {
        return '';
    }
});

function updateFMCSA() {
    router.post(
        route('verification.update', props.carrier),
        {},
        {
            preserveScroll: true,
            only: ['carrier'],
            onError: errors => {
                alert(errors.error);
            }
        }
    );
}
</script>
