<template>
    <AppModal
        id="create-location-modal"
        :show="isOpen"
        :title="'Create New Location'"
        class="max-w-2xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="handleSubmit">
            <AppInput
                v-model="form.name"
                name="name"
                required />
            <AppAutocomplete
                v-model="form.address"
                name="address_1"
                label="Address 1"
                :options-url="route('addresses.autocomplete')"
                :placeholder="$t('Search for an address...')"
                required />
            <AppInput
                v-model="form.address.address_2"
                name="address_2" />
            <div class="sm:grid sm:grid-cols-3 sm:gap-2">
                <AppInput
                    v-model="form.address.city"
                    name="city"
                    required />
                <AppInput
                    v-model="form.address.region"
                    name="region"
                    required />
                <AppInput
                    v-model="form.address.postal_code"
                    name="address.postal_code"
                    required />
            </div>
            <AppSelect
                v-model="form.address.country"
                name="country"
                required
                :options="{ CA: 'CA', MX: 'MX', US: 'US' }" />
            <div
                v-if="form.errors.address"
                class="mr-auto flex items-center gap-1 rounded border border-iel-light-red bg-iel-light-red px-1 py-0.5 text-sm text-iel-dark-red">
                <AppIcon
                    name="far fa-circle-exclamation"
                    class="h-4 w-4" />
                <p>{{ form.errors.address }}</p>
            </div>
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Save') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('LocationCreateModal');

const form = useForm({
    name: null,
    address: {
        address_1: null,
        address_2: null,
        city: null,
        region: null,
        postal_code: null,
        country: null
    }
});

provide('form', form);

watch(isOpen, () => {
    // Reset form and clear errors whenever modal is opened or closed
    form.reset();
    form.clearErrors();
});

function handleSubmit() {
    form.post(route('locations.store', context.value.locationDetail), {
        preserveScroll: true,
        onSuccess() {
            close();
        }
    });
}
</script>
