<template>
    <AppCard
        title="Billing"
        theme="light_gray">
        <div class="grid gap-2">
            <div class="grid gap-2 md:grid-cols-2">
                <div class="grid gap-2">
                    <div class="grid gap-2 md:grid-cols-2">
                        <AppSelect
                            name="billing_method"
                            :model-value="customerGroup.billing_method"
                            :url="url"
                            :label="$t('Billing Method')"
                            :options="{ email: 'Email', mail: 'Mail' }" />
                        <AppSelect
                            name="billing_terms"
                            :model-value="customerGroup.billing_terms"
                            :url="url"
                            :label="$t('Billing Terms')"
                            :options="{ 15: 15, 30: 30, 45: 45, 60: 60, 90: 90 }" />
                    </div>
                    <div>
                        <div class="flex items-center">
                            <AppSelectTags
                                id="select-label-customer-billing"
                                name="required_documents"
                                :model-value="customerGroup.required_documents"
                                :url="url"
                                :label="$t('Required Paperwork')"
                                :placeholder="$t('Select...')"
                                :options="loadDocumentTypes"
                                :taggable="false"
                                class="w-64" />
                        </div>
                    </div>
                </div>
                <div>
                    <AppCheckbox
                        name="no_unloading_charges"
                        :model-value="customerGroup.no_unloading_charges"
                        :url="url"
                        :label="$t('No unloading charges')"
                        class="mb-2" />
                    <AppTextarea
                        :model-value="customerGroup.billing_notes"
                        :url="url"
                        :label="$t('Billing Notes')"
                        name="billing_notes" />
                </div>
            </div>
            <div class="grid gap-2">
                <div class="grid gap-2 md:grid-cols-2">
                    <AppInput
                        :model-value="customerGroup.billing_contact_name"
                        :url="url"
                        :label="$t('Billing Contact Name')"
                        name="billing_contact_name" />
                    <AppInput
                        :model-value="customerGroup.billing_contact_emails"
                        :url="url"
                        :label="$t('Billing Contact Emails')"
                        name="billing_contact_emails" />
                </div>
                <CustomerGroupBillingAddressCard />
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { loadDocumentTypes } from '@/data.js';

const props = defineProps({
    customerGroup: Object
});

const url = route('customers.groups.billing.update', props.customerGroup);
</script>
