<template>
    <div class="pt-3">
        <AppButton
            id="check-mp-status-button"
            class="w-3/4"
            permission="update_carrier_info"
            @click="handleClick">
            <AppIcon
                v-if="isLoading"
                name="fal fa-arrows-rotate"
                class="mr-2 h-4 w-4 animate-spin text-iel-silver" />
            {{ !isLoading ? 'Check MP Status' : 'Checking...' }}
        </AppButton>
    </div>
    <AppMacropointPartnerStatus :carrier="carrier" />
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import axios from 'axios';

const page = usePage();

const carrier = computed(() => page.props.carrier);

const isLoading = ref(false);

const handleClick = () => {
    isLoading.value = true;

    axios.post(route('carriers.macropoint.partner.store', page.props.carrier)).finally(() => {
        isLoading.value = false;
    });
};
</script>
