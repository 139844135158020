<template>
    <AppTableSimple
        :columns="[
            { name: 'event_happened_at', label: 'Event Date/Time' },
            { name: 'location', label: 'Location' },
            { name: 'miles_to_next_stop', label: 'Miles to Next Stop' },
            { name: 'event', label: 'Event' },
            { name: 'delivered_at', label: 'Delivered Time' },
            { name: 'latitude', label: 'Latitude' },
            { name: 'longitude', label: 'Longitude' },
            { name: 'temperature', label: 'Temperature' },
            { name: 'type', label: 'Source' }
        ]"
        :data="trackings"
        th-class="bg-iel-light-gray"
        :row-class="row => ({ trashed: row.deleted_at })">
        <template #header>
            <header class="mb-4 items-center justify-between md:flex">
                <span class="uppercase text-iel-gray">{{ $t('Tracking') }}</span>
                <div class="md:flex md:items-center md:justify-end">
                    <AppSearchInput
                        v-model="keyword"
                        class="mb-4 md:mb-0"
                        placeholder="Search" />
                </div>
            </header>
        </template>
    </AppTableSimple>
</template>

<script setup>
import { some, toString } from 'lodash-es';

const props = defineProps({
    trackings: {
        type: Array,
        default: () => []
    }
});

const keyword = ref('');

const trackings = computed(() => {
    if (!keyword.value) return props.trackings;

    return props.trackings.filter(tracking =>
        some(
            ['location', 'event', 'delivered_at', 'latitude', 'longitude'],
            key => tracking[key] && toString(tracking[key])?.toLowerCase().includes(keyword.value.toLowerCase())
        )
    );
});
</script>
