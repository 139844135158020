<template>
    <Bar
        v-if="loaded"
        :id="kebabCase(title) + '-bar-graph'"
        :options="graphOptions"
        :data="needsAttentionData"
        aria-label="Bar graph"
        role="img">
        <p>Could not load graph.</p>
    </Bar>

    <AppGraphScreenReader
        :id="kebabCase(title) + 'graph-data-for-screen-readers'"
        :title="props.title"
        :data="needsAttentionData" />
</template>

<script setup>
import axios from 'axios';
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { kebabCase } from 'lodash-es';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const loaded = ref(false);

const props = defineProps({
    title: {
        type: String,
        default: 'Needs Attention'
    }
});

const needsAttentionData = ref({
    labels: [
        ['\ue1d9', 'Setup'],
        ['\uf46d', 'Plan'],
        ['\uf0d1', 'Ship'],
        ['\uf2e8', 'Bill']
    ],
    datasets: [
        {
            data: [],
            backgroundColor: ['#c21714']
        }
    ]
});

const graphOptions = ref({
    responsive: true,
    maintainAspectRatio: false,
    label: false,
    borderRadius: 6,
    borderSkipped: false,
    scales: {
        x: {
            ticks: {
                color: '#002a40',
                maxRotation: 0,
                font: {
                    family: "'Roboto', 'Font Awesome 6 Pro'",
                    size: 16,
                    lineHeight: 1.3
                }
            },
            grid: {
                display: false
            },
            border: {
                display: false
            }
        },
        y: {
            grid: {
                display: false
            },
            border: {
                display: false
            },
            ticks: {
                color: '#002a40',
                stepSize: 20,
                maxRotation: 0
            }
        }
    },

    plugins: {
        title: {
            text: props.title
        },
        legend: {
            display: false
        },
        tooltip: {
            titleFont: {
                family: "'Roboto', 'Font Awesome 6 Pro'"
            }
        }
    }
});

const fetchNeedsAttentionLoads = async () => {
    try {
        const response = await axios.get('/loads/needs-attention');
        const data = response.data;
        needsAttentionData.value.datasets[0].data = [data.setup, data.plan, data.ship, data.bill];
        loaded.value = true;
    } catch (error) {
        console.error('Failed to fetch needsAttention loads data:', error);
    }
};

onMounted(() => {
    fetchNeedsAttentionLoads();
});
</script>

<style scoped>
canvas {
    @apply !h-72 w-auto;
}
</style>
