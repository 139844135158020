<template>
    <div
        v-if="showMap"
        :id="`load-${load.id}-map`"
        style="height: 600px; width: 800px" />
</template>

<script setup>
import { onMounted } from 'vue';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';

const props = defineProps({
    load: Object
});

TrimbleMaps.APIKey = import.meta.env.VITE_TRIMBLE_MAPS_API_KEY;

const showMap = ref(TrimbleMaps.APIKey && props.load.planned_route_coordinates && props.load.actual_route_coordinates);

onMounted(() => {
    if (showMap.value) renderMap();
});

function renderMap() {
    const myMap = new TrimbleMaps.Map({
        container: `load-${props.load.id}-map`,
        center: new TrimbleMaps.LngLat(-96, 35),
        zoom: 3
    });

    const plannedRoute = new TrimbleMaps.Route({
        routeId: 'plannedRoute',
        routeColor: 'blue',
        stops: props.load.planned_route_coordinates.map(
            coordinates => new TrimbleMaps.LngLat(coordinates.longitude, coordinates.latitude)
        )
    });

    const actualRoute = new TrimbleMaps.Route({
        routeId: 'actualRoute',
        routeColor: 'green',
        stops: props.load.actual_route_coordinates.map(
            coordinates => new TrimbleMaps.LngLat(coordinates.longitude, coordinates.latitude)
        )
    });

    myMap.on('load', function () {
        plannedRoute.addTo(myMap);
        actualRoute.addTo(myMap);
    });
}
</script>
