<template>
    <tr>
        <td class="w-1/5">
            <span
                class="text-xs font-bold"
                data-test="uploaded-filename">
                {{ pendingFile.name }}
            </span>
        </td>
        <td>
            <AppSelectTags
                id="select-label-load-docs"
                v-model="form.labels"
                :placeholder="$t('Select Labels')"
                name="labels"
                :options="loadDocumentTypes"
                class="w-full"
                :taggable="false"
                open-direction="top"
                required />
        </td>
    </tr>
    <div class="my-2 flex space-x-2 sm:my-0">
        <AppButton
            :id="`documents-list-${pendingFile.name}-actions-upload-document-button`"
            icon="fal fa-file-arrow-up"
            @click="store">
            <span class="sr-only">Upload {{ pendingFile.name }}</span>
        </AppButton>
        <AppButton
            :id="`documents-list-${pendingFile.name}-actions-remove-document-button`"
            variant="outline"
            icon="fal fa-trash-can"
            @click="remove">
            <span class="sr-only">Remove {{ pendingFile.name }}</span>
        </AppButton>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { loadDocumentTypes } from '@/data.js';

const props = defineProps({
    load: Object,
    pendingFile: Object
});

const emit = defineEmits(['remove']);

const form = useForm({
    document: props.pendingFile,
    labels: [],
    stops: []
});

const stops = ref([]);

provide('form', form);

function store() {
    form.stops = stops.value.map(stop => stop.value);
    form.post(route('loads.documents.store', [props.load, props.pendingFile]), {
        preserveScroll: true,
        onSuccess() {
            remove();
        }
    });
}

function remove() {
    emit('remove');
}
</script>
