<template>
    <tr @keyup.esc="toggleEdit">
        <td>
            <div class="my-2 sm:my-0">
                <div class="flex items-start gap-x-2">
                    <AppInput
                        v-if="editing"
                        :model-value="document.file_name"
                        name="file_name"
                        label="Name"
                        required
                        :url="url"
                        class="w-full" />
                    <p
                        v-else
                        class="border border-transparent py-1 text-xs font-semibold leading-4 text-iel-darkest-gray">
                        <!-- Border added to maintain vertical height when editing -->
                        {{ document.file_name }}
                    </p>
                </div>
                <div class="mb-2 mt-1 flex items-center gap-x-2 text-xs leading-4 text-iel-dark-gray">
                    <div>
                        <p class="whitespace-nowrap">
                            <time :datetime="document.updated_at">{{ formatDate(document.updated_at) }}</time>
                        </p>
                        <p class="whitespace-nowrap">
                            <span>By: {{ document.submitted_by }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-2 flex gap-x-1 sm:my-0">
                <AppButton
                    :id="`document-list-${document.id}-actions-edit`"
                    variant="outline"
                    :permission="editPermission"
                    :icon="editing ? 'far fa-xmark' : 'fal fa-pen'"
                    @click="toggleEdit">
                    <span class="sr-only">Edit {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`documents-list-${document.id}-actions-view-document-button`"
                    variant="outline"
                    icon="fal fa-eye"
                    @click="open">
                    <span class="sr-only">View {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`document-list-${document.id}-actions-download`"
                    :href="route('loads.documents.download', [props.load, props.document])"
                    :download="`${document.file_name}`"
                    variant="outline"
                    icon="fal fa-file-arrow-down">
                    <span class="sr-only">Download {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`document-list-${document.id}-actions-delete`"
                    variant="outline"
                    class="border-iel-darkest-red text-iel-darkest-red"
                    icon="fal fa-trash-can"
                    :disabled="document.loading"
                    :permission="deletePermission"
                    @click="confirmDelete(document)">
                    <span class="sr-only">Delete {{ document.file_name }}</span>
                </AppButton>
            </div>
        </td>
        <td class="lg:w-1/4">
            <div class="my-1 flex flex-col items-start gap-1 sm:my-0">
                <AppSelectTags
                    v-if="editing"
                    id="select-labels-load-docs"
                    :model-value="document.labels"
                    :placeholder="$t('Select Labels')"
                    name="labels"
                    :options="loadDocumentTypes"
                    :taggable="false"
                    class="w-4/5"
                    required
                    :url="url" />
                <ul
                    v-else
                    :id="`load-card-document-labels-for-${document.file_name}-${document.id}`"
                    class="flex flex-wrap gap-1">
                    <li
                        v-for="label in document.labels"
                        :key="label"
                        class="mt-0.5 flex place-content-center whitespace-nowrap rounded-md px-1 py-0.5 text-xs font-semibold ring-1 ring-inset"
                        :class="{
                            'bg-iel-lightest-green text-iel-dark-green ring-iel-green/20':
                                label !== 'Last Saved RateCon',
                            'bg-iel-light-red text-iel-dark-red ring-iel-red/20': label === 'Last Saved RateCon'
                        }">
                        {{ label }}
                    </li>
                </ul>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { formatDate } from '@/helpers.js';
import { loadDocumentTypes } from '@/data.js';

const props = defineProps({
    load: Object,
    document: Object,
    editPermission: String,
    deletePermission: String
});

const url = route('loads.documents.update', [props.load, props.document]);

const editing = ref(false);
const emit = defineEmits(['remove']);

function toggleEdit() {
    editing.value = !editing.value;
}

function open() {
    usePopup().open(
        route('loads.documents.show', {
            load: props.load,
            media: props.document,
            embed: true
        })
    );
}

function confirmDelete() {
    if (confirm(`Are you sure you would like to delete "${props.document.file_name}"?`)) {
        props.document.loading = true;
        router.delete(route('loads.documents.destroy', [props.load, props.document]), {
            preserveScroll: true
        });
        emit('remove');
    }
}
</script>
