<template>
    <AppModal
        id="transfer-comcheck-modal"
        :show="isOpen"
        :title="$t(context.title ?? 'Transfer Comcheck')"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="submit(context.advance)">
            <span class="font-medium">What load PO would you like to transfer the Comcheck to?</span>
            <div class="grid gap-3 md:grid-cols-2 xl:grid-cols-3">
                <AppInput
                    v-model="form.po_number"
                    name="po_number"
                    label="PO Number"
                    required />
                <AppErrorMessage
                    v-if="form.errors.update"
                    :error="form.errors.update"
                    name="transfer-comcheck-form-errors" />
            </div>
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Submit') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm, router } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('TransferComcheckModal');

const form = useForm({
    po_number: null
});

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();

    if (context.value.mode === 'transfer') {
        form.po_number = context.value.comcheck.po_number;
    }
});
function submit(advance) {
    form.patch(route('comcheck.transfer', { advance: advance }), {
        preserveScroll: true,
        onError() {
            form.setError(form.errors.update);
        },
        onSuccess() {
            close();
            router.reload({ only: ['load'] });
        }
    });
}
</script>
