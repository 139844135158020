<template>
    <Component
        :is="element"
        :type="type"
        :href="href"
        :disabled="disabled || unauthorized"
        :target="target"
        :title="unauthorized ? $t('Unauthorized') : ''"
        class="flex items-center justify-center gap-2 whitespace-nowrap rounded border font-medium disabled:cursor-not-allowed disabled:opacity-50"
        :class="[variants[variant], sizes[size], { 'w-full': block }]">
        <AppIcon
            v-if="icon && !trailing"
            :class="iconSizes[iconSize]"
            :name="icon" />
        <slot />
        <AppIcon
            v-if="icon && trailing"
            :class="iconSizes[iconSize]"
            :name="icon" />
    </Component>
</template>

<script setup>
const variants = {
    primary: 'border-transparent bg-iel-blue text-white',
    outline: 'border-iel-blue bg-white text-iel-blue',
    invisible: 'border-none bg-transparent font-normal text-base text-black'
};

const sizes = {
    sm: 'text-sm px-2 py-1',
    md: 'text-sm px-4 py-1 md:text-base',
    lg: 'text-md px-4 py-1 md:text-lg md:px-4 md:py-1',
    xl: 'text-2xl px-4 py-1',
    invisible: 'text-base px-4'
};

const iconSizes = {
    sm: 'text-sm',
    base: 'text-base',
    md: 'text-md',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl'
};

const props = defineProps({
    variant: {
        type: String,
        default: 'primary',
        validator: value => ['primary', 'outline', 'invisible'].includes(value)
    },
    size: {
        type: String,
        default: 'sm',
        validator: value => ['sm', 'md', 'lg', 'xl', 'invisible'].includes(value)
    },
    type: {
        type: String,
        default: props => (props.href ? null : 'button')
    },
    icon: String,
    iconSize: {
        type: String,
        default: 'sm',
        validator: value => ['sm', 'md', 'lg', 'xl', '2xl'].includes(value)
    },
    disabled: Boolean,
    trailing: Boolean,
    href: String,
    target: String,
    download: String,
    block: Boolean,
    confirmation: String,
    permission: String
});

const element = computed(() => {
    if (!props.href) return 'button';
    if (props.target || props.download) return 'a';
    return 'Link';
});

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));
</script>
