<template>
    <div class="p-7">
        <table class="w-full text-sm">
            <tr
                v-for="(row, rowIndex) in rows"
                :key="rowIndex"
                class="last:font-bold">
                <td
                    v-for="(cell, cellIndex) in row"
                    :key="cellIndex"
                    class="pb-2 last:text-right">
                    {{ cell }}
                </td>
            </tr>
        </table>
    </div>
</template>

<script setup>
import { add } from 'lodash-es';

const props = defineProps({
    load: Object
});

const total = computed(() =>
    add(parseFloat(props.load.total_customer_billing_amount), parseFloat(props.load.total_accessorial_charges_amount))
);

const rows = computed(() => {
    return [
        ['Billing Rates', '$' + props.load.total_customer_billing_amount],
        ['Accessorial Rates', '$' + props.load.total_accessorial_charges_amount],
        ['Adjustments', '$0.00'],
        ['Payments', '$0.00'],
        ['Total', '$' + total.value.toFixed(2)]
    ];
});
</script>
