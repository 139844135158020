<template>
    <AppGraphCircle
        v-if="loaded"
        :title="'Todays Loads'"
        :data="todaysData" />
</template>

<script setup>
import axios from 'axios';

const loaded = ref(false);

const todaysData = ref({
    labels: ['On Time', 'Needs Dispatch', 'Needs Attention'],
    datasets: [
        {
            data: [],
            borderColor: ['#3bb273', '#002a40', '#c21714'],
            backgroundColor: ['#3bb273', '#002a40', '#c21714'],
            cutout: '80%'
        }
    ]
});

const fetchTodayLoads = async () => {
    try {
        const response = await axios.get('/loads/today');
        const data = response.data;

        todaysData.value.datasets[0].data = [data.on_time, data.needs_dispatch, data.needs_attention];
        loaded.value = true;
    } catch (error) {
        console.error("Failed to fetch today's loads data:", error);
    }
};

onMounted(() => {
    fetchTodayLoads();
});
</script>
