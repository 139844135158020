<script setup>
defineProps({
    size: {
        type: String,
        default: 'sm'
    }
});

const classes = {
    sm: 'text-sm font-bold text-iel-dark-gray',
    md: 'text-md font-bold text-iel-dark-gray',
    lg: 'text-lg font-medium text-iel-gray',
    xl: 'text-3xl font-bold text-iel-blue'
};
</script>

<template>
    <div :class="classes[size]">
        <slot />
    </div>
</template>
