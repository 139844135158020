<template>
    <AppDropdown align="bottom-right">
        <template #trigger>
            <button
                class="flex"
                type="button">
                <AppIcon
                    name="far fa-ellipsis"
                    class="cursor-pointer" />
            </button>
        </template>
        <template #content></template>
    </AppDropdown>
</template>
