<template>
    <form
        class="mb-4 rounded-md border bg-white p-2 @container"
        @submit.prevent="createMessage">
        <p class="text-sm md:text-base">{{ $t('New Message') }}</p>
        <div class="grid gap-2">
            <AppDisplayField
                :value="phoneNumber"
                name="phone_number"
                label="Number" />
            <AppTextarea
                v-model="form.message"
                name="message"
                label="Message"
                required />
        </div>
        <div class="my-2 flex w-full gap-x-2">
            <AppButton
                id="load-create-message-add-button"
                type="submit"
                :disabled="form.processing">
                {{ $t('Send') }}
            </AppButton>
            <AppButton
                id="load-create-message-cancel-button"
                type="submit"
                variant="outline"
                @click.prevent="cancelNote">
                {{ $t('Cancel') }}
            </AppButton>
        </div>
    </form>
</template>

<script setup>
import { router, useForm } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const page = usePage();

const props = defineProps({
    activeTab: String
});

const emit = defineEmits(['success']);

const messageForm = inject('showingCreateMessageForm');

const phoneNumber = computed(() => {
    if (props.activeTab === 'Dispatcher') return page.props.load.dispatcher?.phone_number;
    else if (props.activeTab === 'Driver') return page.props.load.driver?.phone_number;
    else if (props.activeTab === 'Driver 2') return page.props.load.driver2?.phone_number;
    else return null;
});

const form = useForm({
    message: '',
    phone_number: phoneNumber.value
});

provide('form', form);

function createMessage() {
    form.post(route('messages.send', page.props.load), {
        preserveScroll: true,
        onSuccess: () => {
            emit('success');
            router.reload({ only: ['load'] });
        }
    });
}

function cancelNote() {
    messageForm.value = false;
}
</script>
