<template>
    <form
        class="flex flex-col gap-2 rounded-md border border-iel-gray bg-white p-2"
        @submit.prevent="submit">
        <div class="grid grid-cols-2 gap-2 gap-x-2 xl:grid">
            <div class="col-span-2 space-y-1">
                <AppAutocomplete
                    v-model="form.address"
                    class="xl:col-span-3"
                    name="address_1"
                    label="Address 1"
                    :options-url="route('addresses.autocomplete')"
                    :placeholder="$t('Search for an address...')"
                    :initial-value="form.address.address_1"
                    :disabled="isReadOnly"
                    :is-read-only="isReadOnly"
                    required />
                <AppInput
                    v-model="form.address.address_2"
                    class="xl:col-span-3"
                    name="address_2"
                    :disabled="isReadOnly" />
            </div>
            <div class="col-span-2 grid gap-2 md:grid-cols-2">
                <AppInput
                    v-model="form.address.city"
                    class="xl:col-span-1"
                    name="city"
                    :disabled="isReadOnly" />
                <AppInput
                    v-model="form.address.region"
                    class="xl:col-span-1"
                    name="region"
                    :disabled="isReadOnly" />
                <AppInput
                    v-model="form.address.postal_code"
                    class="xl:col-span-1"
                    name="postal_code"
                    :disabled="isReadOnly" />
                <AppSelect
                    v-model="form.address.country"
                    class="xl:col-span-1"
                    name="country"
                    :options="{ CA: 'CA', MX: 'MX', US: 'US' }"
                    :disabled="isReadOnly" />
            </div>
        </div>
        <div
            v-if="form.errors.address"
            class="mr-auto flex items-center gap-1 rounded border border-iel-light-red bg-iel-light-red px-1 py-0.5 text-sm text-iel-dark-red">
            <AppIcon
                name="far fa-circle-exclamation"
                class="h-4 w-4" />
            <p>{{ form.errors.address }}</p>
        </div>
        <div class="flex items-center gap-2">
            <AppButton
                v-show="isReadOnly"
                :disabled="form.processing"
                @click="isReadOnly = false">
                {{ $t('Edit') }}
            </AppButton>
            <AppButton
                v-show="!isReadOnly"
                type="submit"
                :disabled="form.processing">
                {{ $t('Save') }}
            </AppButton>
            <p
                v-if="form.isDirty"
                class="text-sm italic text-iel-dark-gray">
                {{ $t('Unsaved changes') }}
            </p>
        </div>
    </form>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';

const page = usePage();

const form = useForm({
    address: {
        address_1: page.props.billing_address?.address_1,
        address_2: page.props.billing_address?.address_2,
        city: page.props.billing_address?.city,
        region: page.props.billing_address?.region,
        postal_code: page.props.billing_address?.postal_code,
        country: page.props.billing_address?.country
    }
});

provide('form', form);

const isReadOnly = ref(true);

function submit() {
    form.transform(data => data.address).patch(
        route('customers.groups.billing.address.update', page.props.customerGroup.id),
        {
            onSuccess() {
                isReadOnly.value = true;
            }
        }
    );
}
</script>
