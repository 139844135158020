<template>
    <div class="my-2 grid gap-4 px-4 lg:grid-cols-6">
        <LoadBillingNumbers
            v-bind="{ load }"
            class="col-span-1" />
        <LoadBillingRates
            class="col-span-3 max-w-screen-md"
            v-bind="{ load: load, rates: load.customerLineItems, line_item_type: 'customer' }" />
        <LoadBillingContact
            v-if="load.customer"
            v-bind="{ load }"
            class="col-span-2" />
    </div>

    <hr class="my-8" />

    <LoadBillingInvoiceForm />
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();
const load = computed(() => page.props.load);
</script>
