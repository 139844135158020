<template>
    <AppGraphCircle
        title="Days to Pay"
        :data="daysToPayData"
        :show-total="true"
        :show-legend="false" />
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    dtpRanges: {
        type: Array,
        required: true
    },
    dtpCounts: {
        type: Array,
        required: true
    },
    totalSum: {
        type: Number,
        required: true
    }
});

const daysToPayData = ref({
    labels: props.dtpRanges.map(range => range.label),
    datasets: [
        {
            data: props.dtpCounts,
            borderColor: ['#002a40', '#c18f24', '#c10910'],
            backgroundColor: ['#002a40', '#c18f24', '#c10910'],
            cutout: '70%',
            totalSum: props.totalSum
        }
    ]
});
</script>
