<template>
    <AppCard
        title="Info"
        theme="light_gray"
        content-class="gid w-full gap-2">
        <div class="grid items-center gap-2 md:grid-cols-3">
            <AppInput
                v-model="page.props.location.name"
                name="name"
                :url="url" />
            <AppInput
                v-model="page.props.location.phone_number"
                name="phone_number"
                :url="url" />
            <AppRadioGroup
                name="is_cross_dock"
                label="Cross Dock"
                class="pl-2"
                :model-value="Number(page.props.location.is_cross_dock)"
                :url="url"
                :options="[
                    { value: 1, label: 'Yes' },
                    { value: 0, label: 'No' }
                ]"
                inline />
            <AppInput
                v-model="page.props.address.latitude"
                name="latitude"
                :url="urlAddress" />
            <AppInput
                v-model="page.props.address.longitude"
                name="longitude"
                :url="urlAddress" />
        </div>
        <LocationInfoCardAddressForm class="my-4" />
        <div class="grid gap-2 text-nowrap md:grid-cols-2 lg:grid-cols-4">
            <AppInput
                :model-value="page.props.primary_contact?.name"
                name="contact_name"
                :url="url" />
            <AppInput
                :model-value="page.props.primary_contact?.job_title"
                name="contact_job_title"
                :url="url" />
            <AppInput
                :model-value="page.props.primary_contact?.phone_number"
                name="contact_phone_number"
                :url="url"
                class="truncate" />
            <AppInput
                :model-value="page.props.primary_contact?.email_address"
                name="contact_email_address"
                :url="url"
                class="truncate" />
        </div>
        <AppTextarea
            :model-value="page.props.primary_contact?.note"
            name="contact_note"
            :url="url"
            class="mt-2" />
    </AppCard>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const url = route('locations.update', page.props.location.id);
const urlAddress = route('addresses.coordinates.update', page.props.address.id);
</script>
