export function usePopup() {
    function open(url) {
        const width = window.screen.width / 2;
        const height = window.screen.height / 2;
        const left = window.screen.width / 4;
        const top = window.screen.height / 4;

        const features = [
            `width=${width}`,
            `height=${height}`,
            `left=${left}`,
            `top=${top}`,
            'scrollbars=yes',
            'resizable=yes',
            'toolbar=no',
            'location=no',
            'directories=no',
            'status=no',
            'menubar=no',
            'copyhistory=no'
        ].join(',');

        const popup = window.open(url, 'popupWindow', features);

        if (!popup) {
            alert('Please allow your browser to open pop-up windows');
        }
    }

    return { open };
}
