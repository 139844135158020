<template>
    <div class="rounded-md border border-iel-dark-gray bg-iel-light-gray px-4 py-6">
        <slot
            class="text-xl"
            name="header"
            :selected="selected" />
        <div class="max-h-48 overflow-auto text-sm">
            <table class="relative w-full whitespace-nowrap">
                <thead>
                    <tr class="mb-2 border-b border-iel-gray text-left text-iel-gray">
                        <th
                            v-if="checkable"
                            class="px-2">
                            <AppIcon
                                name="far fa-square-check"
                                class="block h-4 w-4"
                                @click="selectAll" />
                        </th>
                        <th
                            v-for="column in columns"
                            :id="`load-${parentId}_header-${column.name}`"
                            :key="column.name"
                            class="sticky left-0 top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0"
                            :class="thClass">
                            <button
                                v-if="column.sortable"
                                type="button"
                                class="flex items-center gap-2"
                                @click="sort(column.name)">
                                <span>
                                    {{ column.label }}
                                </span>
                                <template v-if="sortBy === column.name">
                                    <AppIcon
                                        v-if="sortDir === 'desc'"
                                        name="far fa-chevron-down" />
                                    <AppIcon
                                        v-if="sortDir === 'asc'"
                                        name="far fa-chevron-up" />
                                </template>
                            </button>
                            <span v-else>
                                {{ column.label }}
                            </span>
                        </th>
                        <th
                            v-if="$slots.actions"
                            :id="`load-${parentId}_checkcall-actions`"
                            class="sticky top-0 z-10 px-2 pb-2 first:pl-0 last:pr-0"
                            :class="thClass">
                            {{ $t('Actions') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="!rows.length">
                        <td
                            id="row-no-data"
                            class="py-1 italic text-iel-gray">
                            {{ $t('No data') }}
                        </td>
                    </tr>
                    <tr
                        v-for="row in rows"
                        v-else
                        :key="row.id"
                        class="relative border-b border-iel-light-gray"
                        :class="rowClass(row)">
                        <td
                            v-if="checkable"
                            class="px-2">
                            <input
                                v-model="selected"
                                type="checkbox"
                                :value="row.id"
                                class="h-4 w-4 rounded border-iel-gray text-iel-blue" />
                        </td>
                        <td
                            v-for="column in columns"
                            :id="`load-${parentId}_row-${row.id}_column-${column.name}`"
                            :key="column.name"
                            class="px-2 py-1 first:pl-0 last:pr-0">
                            {{ get(row, column.name) }}
                        </td>
                        <td
                            v-if="$slots.actions"
                            :id="`load-${parentId}_row-${row.id}_actions`"
                            class="px-2 py-1 first:pl-0 last:pr-0">
                            <slot
                                name="actions"
                                v-bind="{ ...row }" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup>
import { get, orderBy } from 'lodash-es';

const props = defineProps({
    columns: {
        type: Array,
        required: true
    },
    data: {
        type: Array,
        required: true
    },
    thClass: String,
    rowClass: {
        type: Function,
        default: () => {}
    },
    parentId: Number,
    checkable: Boolean
});

const selected = ref([]);

const sortBy = ref(props.columns.find(column => column.defaultSort)?.name);

const sortDir = ref('asc');

const rows = computed(() => {
    if (sortBy.value) {
        return orderBy(props.data, [sortBy.value], [sortDir.value]);
    }
    return props.data;
});

function selectAll() {
    if (selected.value.length === rows.value.length) {
        selected.value = [];
        return;
    }

    selected.value = rows.value.map(row => row.id);
}

function sort(column) {
    if (sortBy.value === column) {
        sortDir.value = sortDir.value === 'asc' ? 'desc' : 'asc';
        return;
    }

    sortBy.value = column;
    sortDir.value = 'asc';
}
</script>

<style>
tr.trashed {
    @apply opacity-50;
}
tr.trashed td::before {
    content: '';
    @apply absolute inset-x-0 top-1/2 w-full border-[0.5px] border-iel-dark-gray;
}
</style>
