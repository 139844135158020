<template>
    <AppGraphCircle
        class="cursor-pointer"
        title="Billed AR"
        :data="billedArData"
        :show-total="false"
        :show-legend="false"
        @update-data="pickUrlByEmittedIndex" />
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
    availableCredit: Object
});

const page = usePage();
const customer = page.props.customer;

const billedArData = computed(() => ({
    labels: ['Billed Amount', 'Unbilled Amount', 'Available Credit'],
    datasets: [
        {
            data: [
                (parseFloat(customer.billed_ar.amount) / 100).toFixed(2),
                (parseFloat(customer.unbilled_ar.amount) / 100).toFixed(2),
                parseFloat(props.availableCredit.amount).toFixed(2)
            ],
            formattedData: [
                customer.billed_ar.formatted,
                customer.unbilled_ar.formatted,
                props.availableCredit.formatted
            ],
            borderColor: ['#c10910', '#002a40', '#3bb273'],
            backgroundColor: ['#c10910', '#002a40', '#3bb273'],
            cutout: '70%'
        }
    ]
}));

const depFromChild = ref('');

// uses element[0].index emitted from child on graph click to choose correct URL
const pickUrlByEmittedIndex = data => {
    depFromChild.value = data;

    const index = data.element[0].index;
    const customer_id = customer.id;
    const routes = [
        {
            filter: 'billed',
            url: route('loads.index', { customer_id, billing_filter: 'billed' })
        },
        {
            filter: 'unbilled',
            url: route('loads.index', { customer_id, billing_filter: 'unbilled' })
        }
    ];

    window.open(routes[index].url, '_blank');
};
</script>
