<template>
    <tr @keyup.esc="toggleEdit">
        <td>
            <div class="my-2 sm:my-0">
                <div class="flex items-start gap-x-2">
                    <AppInput
                        v-if="editing"
                        :model-value="document.file_name"
                        name="file_name"
                        label="Name"
                        required
                        :url="url"
                        class="w-full" />
                    <p
                        v-else
                        class="border border-transparent py-1 text-xs font-semibold leading-4 text-iel-darkest-gray">
                        <!-- Border added to maintain vertical height when editing -->
                        {{ document.file_name }}
                    </p>
                </div>
                <div class="mb-2 mt-1 flex items-center gap-x-2 text-xs leading-4 text-iel-dark-gray">
                    <div>
                        <p class="whitespace-nowrap">
                            <time :datetime="document.updated_at">{{ formatDate(document.updated_at) }}</time>
                        </p>
                        <p class="whitespace-nowrap">
                            <span>By: {{ document.submitted_by }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-2 flex gap-x-1 sm:my-0">
                <AppButton
                    :id="`document-list-${document.id}-actions-edit`"
                    variant="outline"
                    :icon="editing ? 'far fa-xmark' : 'fal fa-pen'"
                    :permission="editPermission"
                    @click="toggleEdit">
                    <span class="sr-only">Edit {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`documents-list-${document.id}-actions-view-document-button`"
                    variant="outline"
                    icon="fal fa-eye"
                    @click="open">
                    <span class="sr-only">View {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`document-list-${document.id}-actions-delete`"
                    variant="outline"
                    class="border-iel-darkest-red text-iel-darkest-red"
                    icon="fal fa-trash-can"
                    :permission="deletePermission"
                    :disabled="document.loading"
                    @click="confirmDelete()">
                    <span class="sr-only">Delete {{ document.file_name }}</span>
                </AppButton>
            </div>
        </td>
        <td class="w-1/2">
            <div
                v-if="request.entity_type === 'carrier'"
                class="my-1 flex flex-col items-start gap-1 sm:my-0">
                <AppSelect
                    v-if="editing"
                    id="select-label-carrier-docs"
                    v-model="document.label"
                    :placeholder="$t('Select Label')"
                    name="label"
                    :options="carrierDocumentTypes"
                    class="w-full"
                    required
                    :url="url" />
                <p
                    v-else
                    class="mt-0.5 flex whitespace-nowrap rounded-md bg-iel-lightest-green px-1 py-0.5 text-xs font-semibold text-iel-dark-green ring-1 ring-inset ring-iel-green/20">
                    {{ document.label }}
                </p>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { formatDate } from '@/helpers.js';
import { carrierDocumentTypes } from '@/data.js';

const props = defineProps({
    request: Object,
    document: Object,
    editPermission: String,
    deletePermission: String
});

let targetRoute = 'tickets.customer.documents.update';
if (props.request.entity_type === 'carrier') {
    targetRoute = 'carriers.documents.update';
}

const url = route(targetRoute, [props.request.id, props.document]);

const editing = ref(false);
const emit = defineEmits(['remove', 'edit']);

function toggleEdit() {
    editing.value = !editing.value;
    if (!editing.value) {
        emit('edit');
    }
}

function open() {
    let targetRoute = 'tickets.customer.documents.show';

    if (props.request.entity_type === 'carrier') {
        targetRoute = 'carriers.documents.show';
    }

    usePopup().open(
        route(targetRoute, {
            carrier: props.request.entity.id,
            customer: props.request.entity.id,
            media: props.document,
            embed: true
        })
    );
}

function confirmDelete() {
    let targetRoute = 'tickets.customer.documents.destroy';

    if (props.request.entity_type === 'carrier') {
        targetRoute = 'carriers.documents.destroy';
    }

    if (confirm(`Are you sure you would like to delete "${props.document.file_name}"?`)) {
        props.document.loading = true;
        router.delete(route(targetRoute, [props.request.entity.id, props.document]), {
            preserveScroll: true
        });
        emit('remove');
    }
}
</script>
