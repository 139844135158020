<template>
    <AppTablePill
        :columns="[
            { name: 'name', label: $t('Name'), sortable: true },
            { name: 'description', label: $t('Description'), sortable: true },
            { name: 'actions', label: '' }
        ]"
        :data="roles.data"
        data-key="roles">
        <template #name-cell="{ row }">
            <Link
                :href="route('roles.show', row.id)"
                class="font-bold text-iel-blue">
                {{ row.name }}
            </Link>
        </template>
        <template #actions="{ id }">
            <div class="flex w-full justify-end gap-2">
                <AppButton
                    icon="far fa-pen"
                    title="Edit Role"
                    variant="outline"
                    class="inline-block transition hover:bg-iel-light-gray"
                    @click="openEditModal(id)" />
                <AppButton
                    icon="far fa-copy"
                    title="Copy Role"
                    variant="outline"
                    class="inline-block transition hover:bg-iel-light-gray"
                    @click="openCopyModal(id)" />
                <AppButton
                    icon="far fa-trash-can"
                    title="Delete Role"
                    variant="outline"
                    class="inline-block transition hover:bg-iel-red hover:text-white"
                    @click="deleteRole(id)" />
            </div>
        </template>
    </AppTablePill>
    <AppPagination :meta="roles.meta" />
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { useModal } from '@/Composables/useModal.js';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import RolesLayout from '@/Layouts/RolesLayout.vue';

defineOptions({
    layout: [AuthenticatedLayout, RolesLayout]
});

const props = defineProps({
    roles: Object
});

const { open: openRoleModal } = useModal('RoleModal');

function openEditModal(id) {
    const role = props.roles.data.find(role => role.id === id);
    openRoleModal({
        mode: 'edit',
        role,
        onClose: () => {
            router.reload({ only: ['roles'] });
        }
    });
}

function openCopyModal(id) {
    const role = props.roles.data.find(role => role.id === id);
    openRoleModal({
        mode: 'copy',
        role: {
            ...role,
            name: `${role.name} copy`,
            permissions: role.permissions.map(permission => permission.id)
        },
        onClose: () => {
            router.reload({ only: ['roles'] });
        }
    });
}

function deleteRole(id) {
    if (confirm('Are you sure you want to delete this role?')) {
        const role = props.roles.data.find(role => role.id === id);
        router.delete(route('roles.destroy', role), {
            onSuccess: () => {
                alert('Role deleted successfully');
                router.reload({ only: ['roles'] });
            }
        });
    }
}
</script>
