<template>
    <form
        class="mb-4 rounded-md border bg-white p-2 @container"
        @submit.prevent="approveRequest">
        <AppInput
            id="new-credit-limit-amount"
            v-model="form.new_credit_limit"
            name="new_credit_limit"
            label="New Credit Limit"
            required />
        <div class="my-2 flex w-full justify-end gap-x-2">
            <AppButton
                id="credit-increase-approval-cancel-button"
                type="submit"
                class="self-start"
                variant="outline"
                @click.prevent="initialize">
                {{ $t('Cancel') }}
            </AppButton>
            <AppButton
                id="credit-increase-approval-save-button"
                type="submit"
                class="self-start"
                :disabled="form.processing">
                {{ $t('Save') }}
            </AppButton>
        </div>
    </form>
</template>

<script setup>
import { router, useForm } from '@inertiajs/vue3';

import { approvalForm, denialForm } from '@/stores';

const form = useForm({
    new_credit_limit: approvalForm.value.amount
});

const props = defineProps({
    request: Object
});

provide('form', form);

const emit = defineEmits(['success']);

function approveRequest() {
    router.patch(
        route('customers.customerTicket.approveCreditIncrease', [props.request.entity.id, props.request.id]),
        {
            new_credit_limit: form.new_credit_limit
        },
        {
            preserveScroll: true,
            onSuccess: () => {
                emit('success');
            }
        }
    );
}

const initialize = () => {
    approvalForm.value.setIsOpen(false);
    denialForm.value.setIsOpen(false);
};

onMounted(() => {
    const amount = document.getElementById('new-credit-limit-amount');
    amount.focus();
    amount.select();
});
</script>
