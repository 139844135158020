<template>
    <div v-if="load.carrier">
        <div
            :key="load.carrier.id"
            class="grid gap-3">
            <LoadDetailCarrierPayRates
                id="load-detail-carrier-pay-rates"
                class="max-w-screen-xl"
                v-bind="{ loadId: load.id, rates: load.carrierLineItems, line_item_type: 'carrier' }" />
            <LoadDetailCarrierPayTotal
                id="load-detail-carrier-pay-total"
                class="max-w-screen-xl"
                v-bind="{ load }" />
        </div>

        <LoadMap :load="load" />
    </div>
    <div v-else>
        {{ $t('A carrier has not yet been booked for this load.') }}
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const load = computed(() => page.props.load);
</script>
