<template>
    <div>
        <div class="flex justify-end pr-1">
            <button
                class="mb-2 inline-block rounded bg-iel-blue px-2 py-0.5 text-left text-xs font-semibold leading-5 text-iel-light-gray transition hover:bg-iel-light-gray hover:text-iel-blue focus:bg-iel-light-gray focus:text-iel-blue focus:outline-none"
                type="button"
                :disabled="loadDisabled"
                @click="showingCreateMessageForm = true">
                <AppIcon
                    name="fal fa-sms"
                    class="mr-1 text-sm" />
                {{ $t('Send Text') }}
            </button>
        </div>
        <LoadCardMessageForm
            v-if="showingCreateMessageForm"
            :load="load"
            :active-tab="activeTab"
            @success="onMessageCreated" />
        <ul
            v-if="messages.length > 0"
            role="list"
            class="mb-4 max-h-96 space-y-2 overflow-y-auto px-0.5"
            tabindex="0">
            <li
                v-for="message in messages"
                :key="message.id">
                <AppMessage
                    :is-text="true"
                    :load="load"
                    :active-tab="activeTab"
                    icon="fal fa-sms"
                    :created-at="message.created_at"
                    :message="message"
                    size="md" />
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useLoadDisabled } from '@/helpers';

const props = defineProps({
    load: Object,
    activeTab: String
});

defineEmits(['success']);

const messages = computed(() =>
    props.load.messages.filter(message => {
        if (props.activeTab === 'Dispatcher') {
            return message.to === props.load.dispatcher.phone_number;
        }
        if (props.activeTab === 'Driver') {
            return message.to === props.load.driver.phone_number;
        }
        if (props.activeTab === 'Driver 2') {
            return message.to === props.load.driver2.phone_number;
        }
    })
);

const showingCreateMessageForm = ref(false);

provide('showingCreateMessageForm', showingCreateMessageForm);

function onMessageCreated() {
    showingCreateMessageForm.value = false;
}

defineExpose({ showingCreateMessageForm });

const loadDisabled = useLoadDisabled(props.load);
</script>
