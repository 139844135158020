<template>
    <AppModal
        id="create-load-modal"
        :show="isOpen"
        :title="$t('Create Load')"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="save">
            <AppAutocomplete
                v-model="form.customer_id"
                name="customer_id"
                label="Customer"
                :options-url="`${route('customers.autocomplete')}?active_only=true`"
                :placeholder="$t('Search for a customer...')" />
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Save') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed } = useModal('LoadCreateModal');

const form = useForm({
    customer_id: null
});

provide('form', form);

watch(isOpen, () => {
    // Reset form and clear errors whenever modal is opened or closed
    form.reset();
    form.clearErrors();
});

function save() {
    form.post(route('loads.store'), {
        onSuccess() {
            close();
        }
    });
}
</script>
