<template>
    <Head title="Customers" />
    <AppHeader
        title="Customers"
        :icon-component="IconCustomers">
        <template #search>
            <div class="flex gap-3">
                <AppSearchInput
                    id="customers-list-search"
                    v-model="q"
                    placeholder="Name, #, Location, PO" />

                <CustomerGroupsFilter :customer-groups="customerGroups" />
            </div>
        </template>
        <template #actions>
            <AppFilterPills
                id="customers-list-filters"
                class="lg:ml-auto"
                :filters="{
                    default: { label: 'All' },
                    active: { color: 'bg-iel-green' },
                    inactive: { color: 'bg-iel-red' }
                }"
                :link-params="{ only: ['customers'] }"
                :reset-pagination="true" />
            <AppButton
                id="add-customer-button"
                icon="far fa-circle-plus"
                @click="createCustomer">
                {{ $t('Add Customer') }}
            </AppButton>
            <AppButton
                id="add-customer-group-button"
                icon="far fa-circle-plus"
                @click="createCustomerGroup">
                {{ $t('Add Customer Group') }}
            </AppButton>
        </template>
    </AppHeader>
    <slot />
</template>

<script setup>
import IconCustomers from '/resources/img/icon-customers.svg';

defineProps({
    customerGroups: Array
});

function createCustomer() {
    useModal('CustomerCreateModal').open({ mode: 'create' });
}

function createCustomerGroup() {
    useModal('CustomerGroupCreateModal').open();
}

// Sync the `q` query parameter in the URL with the value of the search input, and
// reload the data in the 'customers' prop (but nothing else) when it changes
const { q } = useQueryParam({ only: ['customers'] });
</script>
