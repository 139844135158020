<template>
    <form
        class="mb-4 rounded-md border bg-white p-2 @container"
        @submit.prevent="denyRequest">
        <AppTextarea
            id="denial_reason"
            v-model="form.denial_reason"
            name="denial_reason"
            label="Denial Reason"
            required />
        <div class="my-2 flex w-full justify-end gap-x-2">
            <AppButton
                id="credit-increase-denial-cancel-button"
                type="submit"
                class="self-start"
                variant="outline"
                @click.prevent="initialize">
                {{ $t('Cancel') }}
            </AppButton>
            <AppButton
                id="credit-increase-denial-save-button"
                type="submit"
                class="self-start"
                :disabled="form.processing">
                {{ $t('Save') }}
            </AppButton>
        </div>
    </form>
</template>

<script setup>
import { useForm, router } from '@inertiajs/vue3';
import { approvalForm, denialForm } from '@/stores';

const form = useForm({
    denial_reason: ''
});

const props = defineProps({
    request: Object
});

provide('form', form);

const emit = defineEmits(['success']);

function denyRequest() {
    router.patch(
        route('customers.customerTicket.denyCreditIncrease', [props.request.entity.id, props.request.id]),
        {
            denial_reason: form.denial_reason
        },
        {
            preserveScroll: true,
            onSuccess: () => {
                emit('success');
            }
        }
    );
}

const initialize = () => {
    approvalForm.value.setIsOpen(false);
    approvalForm.value.setAmount(null);
    denialForm.value.setIsOpen(false);
};

onMounted(() => {
    document.getElementById('denial_reason')?.focus();
});
</script>
