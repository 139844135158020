<template>
    <div class="flex flex-col gap-1">
        <table
            v-if="props.rates.length"
            class="NX-mobile-stacked">
            <thead>
                <tr>
                    <th>{{ $t('Quantity') }}</th>
                    <th>{{ $t('Amount') }}</th>
                    <th>{{ $t('Type') }}</th>
                    <th>{{ $t('Note') }}</th>
                    <th>{{ $t('Stop') }}</th>
                    <th class="text-center">{{ $t('Bill to Customer') }}</th>
                    <th class="text-center">{{ $t('Pay Carrier') }}</th>
                    <th class="text-center">{{ $t('Receipt Required') }}</th>
                    <th class="md:text-right">{{ $t('Total') }}</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <LoadDetailBillingLineItem
                    v-for="rate in props.rates"
                    :key="rate.id"
                    permission="update_accessorial_charges"
                    :load-id="props.load.id"
                    :line-item="rate" />
                <tr class="font-bold">
                    <td
                        class="text-right"
                        colspan="7">
                        <AppHeading
                            size="md"
                            class="text-right">
                            {{ $t('Total') }}
                        </AppHeading>
                    </td>
                    <td
                        class="md:text-right"
                        colspan="2">
                        $ {{ total }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div
            v-else
            class="text-sm">
            {{ $t('This load has no accessorial charges.') }}
        </div>

        <div class="flex items-center gap-2">
            <AppButton
                type="button"
                permission="update_accessorial_charges"
                :disabled="loadDisabled"
                @click="addAccessorialRate">
                {{ $t('Add') }}
            </AppButton>
        </div>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';

const props = defineProps({
    load: {
        type: Object,
        required: true
    },
    rates: {
        type: Array,
        default: () => []
    },
    line_item_type: {
        type: String,
        default: () => ''
    }
});

const total = computed(() => props.load.total_accessorial_charges_amount);

function addAccessorialRate() {
    router.post(
        route('loads.billing.store', props.load.id),
        { quantity: 1, amount: 0, bill_type: props.line_item_type },
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

const loadDisabled = useLoadDisabled(props.load);
</script>
