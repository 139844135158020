<template>
    <label
        class="inline-flex cursor-pointer items-center gap-2"
        :class="$attrs.class"
        data-test="label">
        <input
            v-model="value"
            type="checkbox"
            class="sr-only"
            name="checkbox"
            :disabled="$attrs.disabled || unauthorized"
            @click="$attrs.onClick"
            @change="$attrs.onChange" />
        <div
            class="relative inline-flex h-5 w-10 items-center rounded-full border border-iel-dark-gray/50"
            :title="unauthorized ? $t('Unauthorized') : ''"
            :class="[
                value ? 'bg-iel-light-green' : 'bg-iel-light-gray',
                $attrs.disabled || unauthorized ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            ]">
            <span
                :class="value ? 'translate-x-6' : 'translate-x-1'"
                class="inline-block h-3 w-3 transform rounded-full border border-iel-gray bg-white transition" />
        </div>
        <div v-html="isArray(label) ? (value ? label[0] : label[1]) : label" />
    </label>
</template>

<script setup>
import { isArray } from 'lodash-es';

defineOptions({
    inheritAttrs: false
});

const props = defineProps({
    label: {
        type: [Array, String],
        default: ''
    },
    variant: {
        type: String,
        default: 'primary',
        validator: value => ['primary', 'secondary'].includes(value)
    },
    permission: String
});

const value = defineModel({ type: Boolean });

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));
</script>
