<template>
    <div class="grid gap-2">
        <div class="grid grid-cols-2 gap-2">
            <AppSelect
                id="source"
                name="source"
                permission="update_carrier_relations_record"
                :label="$t('Source')"
                :model-value="flag.carrier_incident?.source"
                :url="url"
                :options="sourceOptions" />
            <AppSelect
                id="method"
                name="method"
                permission="update_carrier_relations_record"
                :label="$t('Reporting Method')"
                :model-value="flag.carrier_incident?.method"
                :url="url"
                :options="methodOptions" />
            <AppSelect
                id="issue"
                name="issue"
                permission="update_carrier_relations_record"
                :label="$t('Issue')"
                :model-value="flag.carrier_incident?.issue"
                :url="url"
                :options="issueOptions"
                @auto-save-change="updateRootCauses" />
            <AppDisplayField
                v-if="rootCauseOptions && !Object.values(rootCauseOptions).length"
                :label="$t('Root Cause')"
                value="Not Applicable" />
            <AppSelect
                v-if="rootCauseOptions && Object.values(rootCauseOptions).length"
                :model-value="flag.carrier_incident?.root_cause"
                permission="update_carrier_relations_record"
                name="root_cause"
                :label="$t('Root Cause')"
                :url="url"
                :options="rootCauseOptions" />
        </div>
        <div class="grid grid-cols-2 gap-2">
            <div class="space-y-4">
                <div>
                    <AppAutocomplete
                        :model-value="assignee?.id"
                        name="user_id"
                        permission="update_carrier_relations_record"
                        :label="$t('Assignee')"
                        :options-url="route('users.autocomplete')"
                        :placeholder="$t('Search for an assignee...')"
                        :initial-options="
                            assignee?.id
                                ? [
                                      {
                                          value: assignee.id,
                                          display: assignee.name
                                      }
                                  ]
                                : []
                        "
                        :url="url" />
                    <div
                        v-if="!assignee?.id"
                        class="pt-2">
                        <AppButton
                            v-if="!flag.carrier_incident.resolved_at"
                            :id="`report-${flag.id}-assign-button`"
                            permission="update_carrier_relations_record"
                            variant="primary"
                            size="sm"
                            class="cursor-pointer"
                            @click="$emit('assign-to-me')">
                            {{ $t('Assign To Me') }}
                        </AppButton>
                    </div>
                </div>
                <AppDateTime
                    v-model:datetime="form.follow_up_at"
                    v-model:timezone="form.follow_up_at_timezone"
                    name="follow_up"
                    permission="update_carrier_relations_record"
                    :field-names="{ datetime: 'follow_up_at', timezone: 'follow_up_at_timezone' }"
                    :label="$t('Follow Up')"
                    :hide-timezone-text="true"
                    :url="url" />
                <AppCheckbox
                    v-model="completed"
                    name="completed"
                    permission="update_carrier_relations_record"
                    :label="$t('Completed')"
                    class="grid items-center"
                    :url="url" />
            </div>
            <div class="col-start-2 grid gap-4">
                <AppTextarea
                    v-model="flag.carrier_incident.description"
                    name="description"
                    permission="update_carrier_relations_record"
                    :label="$t('Description')"
                    input-class="resize-none"
                    rows="12"
                    :url="url" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { zipObject } from 'lodash-es';
import { carrierRelationsIssues } from '@/data';

defineEmits(['assign-to-me']);

const props = defineProps({
    flag: Object,
    assignee: Object
});

const completed = computed(() => !!props.flag?.carrier_incident?.completed_at);

const form = useForm({
    follow_up_at: props.flag?.carrier_incident?.follow_up_at,
    follow_up_at_timezone: props.flag?.carrier_incident?.follow_up_at_timezone
});

provide('form', form);

const issues = Object.keys(carrierRelationsIssues);
const issueOptions = zipObject(issues, issues);

const sources = [
    'Carrier',
    'Factoring Company',
    'Broker',
    'SEM / SM',
    'OPS Member',
    'Acct. Member',
    'Triumph Pay',
    'Other'
];
const sourceOptions = zipObject(sources, sources);

const methods = ['Call', 'Email', 'Voicemail'];
const methodOptions = zipObject(methods, methods);

const rootCauseOptions = ref();
function updateRootCauses(newIssue) {
    if (!newIssue) return null;
    const rootCauses = carrierRelationsIssues[newIssue];
    rootCauseOptions.value = zipObject(rootCauses, rootCauses);
}
updateRootCauses(props.flag?.carrier_incident?.issue);

const url = route('loads.carrier-incident.update', [props.flag.id, props.flag?.carrier_incident?.id]);
</script>
