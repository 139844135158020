<template>
    <div
        class="space-y-1"
        data-test="wrapper">
        <AppLabel v-bind="{ name: attributes.name, label: fieldLabel, required }" />
        <div class="sm:flex sm:items-center sm:gap-2">
            <div class="relative flex items-center gap-2">
                <VueDatePicker
                    v-bind="{ ...attributes, ...position }"
                    v-model="value"
                    data-test="date-picker"
                    placeholder="mm/dd/yyyy"
                    :text-input="{ format: 'MM/dd/yyyy' }"
                    :model-type="format"
                    :enable-time-picker="false"
                    input-class-name="block w-full rounded border-iel-light-gray text-iel-dark-gray py-1 placeholder:text-iel-gray sm:text-sm"
                    :disabled="unauthorized || props.disabled"
                    @update:model-value="handleChange">
                    <template #clear-icon="{ clear }">
                        <AppIcon
                            name="fal fa-circle-xmark"
                            class="mr-2 block h-5 w-5 text-iel-gray"
                            @click="clear" />
                    </template>
                </VueDatePicker>
                <AppRequestStatus
                    v-if="hasAutoSave"
                    v-bind="{ recentlySuccessful, processing, error }"
                    class="right-4 mr-5 sm:right-2 lg:right-1" />
            </div>
        </div>
        <AppErrorMessage
            :name="attributes.name"
            :error="error" />
    </div>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { computed, nextTick, inject } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: String,
    error: String,
    scope: [String, Number],
    type: {
        type: String,
        default: 'text'
    },
    placeholder: String,
    required: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    url: String,
    confirmation: String,
    format: {
        type: String
    },
    fieldNames: Object,
    permission: String
});

const emit = defineEmits(['blur']);

const hasAutoSave = props.url !== undefined;

const {
    value: autoSaveValue,
    submit,
    processing,
    recentlySuccessful,
    error: autoSaveError,
    clearError
} = useAutoSave(props, 'modelValue');

const definedModel = defineModel({ type: [String, Number] });

const value = hasAutoSave ? autoSaveValue : definedModel;

const form = inject('form'); // Add this line to define the 'form' variable

const error = hasAutoSave ? autoSaveError : computed(() => props.error || form?.errors?.[props.name]);

const { label: fieldLabel, attributes } = useField(props, error);

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));

function handleChange() {
    if (hasAutoSave) {
        clearError();
        nextTick(() => submit());
    } else {
        emit('blur');
        form?.clearErrors(name);
    }
}

const position = computed(() => {
    return props.isCentered ? { 'teleport-center': true } : { teleport: true };
});
</script>

<style>
.dp__icon {
    @apply text-iel-gray;
}
.dp__input {
    color: inherit;
}
.dp__input:hover {
    @apply border-iel-gray;
}
.dp__overlay {
    z-index: 9999;
}
.dp__action_select {
    background-color: #f3f4f6;
}
</style>
