import Swal from 'sweetalert2';

const Confirm = Swal.mixin({
    showCancelButton: true,
    cancelButtonText: 'No',
    confirmButtonText: 'Yes',
    customClass: {
        confirmButton: 'bg-iel-green',
        cancelButton: 'bg-iel-red'
    },
    icon: 'question'
});

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: toast => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    },
    customClass: {
        popup: 'colored-toast text-iel-dark-gray'
    },
    iconColor: '#000'
});

export { Confirm, Toast };
