<template>
    <AppModal
        id="carrier-vehicle-modal"
        :show="isOpen"
        :title="context.title ?? 'Create Vehicle'"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="onConfirm">
            <AppInput
                id="carrier-vehicle-vin-input"
                v-model="form.vin"
                label="VIN"
                name="vin"
                required
                @keydown="onlyVinCharacters"
                @input="convertToUpper" />
            <AppCheckbox
                id="carrier-vehicle-scheduled-auto-checkbox"
                v-model="form.scheduled_auto_only"
                name="scheduled_auto_only"
                label="Scheduled Auto" />
            <div
                v-if="form.errors.scheduled_auto_only"
                class="text-sm text-iel-red">
                {{ form.errors.scheduled_auto_only }}
            </div>
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="save-carrier-vehicle"
                    type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CarrierVehicleModal');

const form = useForm({
    vin: null,
    scheduled_auto_only: false
});

provide('form', form);

watch(isOpen, value => {
    // Reset form and clear errors whenever modal is opened or closed
    form.reset();
    form.clearErrors();
    if (!value) return;

    if (context.value.mode === 'edit') {
        form.vin = context.value.vehicle.vin;
        form.scheduled_auto_only = context.value.vehicle.scheduled_auto_only;
    }
});

function onConfirm() {
    if (context.value.mode === 'create') {
        form.post(
            route('carriers.vehicles.store', {
                carrier: context.value.carrier
            }),
            {
                preserveScroll: true,
                onSuccess() {
                    close();
                }
            }
        );
    }

    if (context.value.mode === 'edit') {
        form.put(
            route('carriers.vehicles.update', {
                carrier: context.value.carrier,
                vehicle: context.value.vehicle
            }),
            {
                preserveScroll: true,
                onSuccess() {
                    close();
                }
            }
        );
    }
}

function onlyVinCharacters(event) {
    if (event.key.match(/[^a-hj-npr-zA-HJ-NPR-Z0-9]/)) {
        event.preventDefault();
    }
}

function convertToUpper() {
    form.vin = form.vin.toUpperCase();
}
</script>
