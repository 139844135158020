<template>
    <div class="flex items-end gap-4">
        <div class="flex flex-1 flex-col gap-4">
            <div
                v-if="loadScheduleStatus"
                class="flex items-center gap-2 font-medium uppercase"
                :style="{ transform: `translateX(${progress}%)` }">
                <div
                    class="size-4 rounded-full"
                    :style="{ 'background-color': loadScheduleStatus.color }" />
                <div
                    class="absolute"
                    :class="progress < 70 ? 'ml-6' : '-left-20'">
                    {{ loadScheduleStatus.text }}
                </div>
            </div>
            <LoadCardProgressBar :load="load" />
            <div class="flex items-center justify-between text-sm font-medium">
                <div>
                    <p
                        v-if="hasLaneStart"
                        :id="`load-${load.id}-lane-start-display`">
                        {{ load.lane.start.city }}, {{ load.lane.start.region }}
                    </p>
                    <p v-else>N/A</p>
                    <p class="text-xs">{{ load.stops?.[0]?.stop_window_begins_at || 'N/A' }}</p>
                </div>
                <AppIcon name="far fa-arrow-right-long" />
                <div class="text-right">
                    <p
                        v-if="hasLaneEnd"
                        :id="`load-${load.id}-lane-end-display`">
                        {{ load.lane.end.city }}, {{ load.lane.end.region }}
                    </p>
                    <p v-else>N/A</p>
                    <p class="text-xs">{{ load.stops?.[load.stops.length - 1]?.stop_window_begins_at || 'N/A' }}</p>
                </div>
            </div>
        </div>
        <div class="text-right text-sm font-medium text-iel-blue">
            <p
                v-if="load.phase === 'ship' && lastCheckedTask"
                class="text-iel-light-green">
                {{ lastCheckedTask?.title }}
            </p>
            <p>
                {{ Math.floor(load.miles_completed || 0) }}/{{ Math.floor(load.trip_miles || 0) }}
                {{ $t('mi') }}
            </p>
            <p v-if="load.latest_eta">{{ $t('ETA') }}: {{ formatETA(load.latest_eta) }}</p>
            <p class="text-xs">{{ $t('Picks/Drops') }}: {{ pickUps }}/{{ dropOffs }}</p>
        </div>
    </div>
    <hr v-if="mini" />
</template>

<script setup>
import { loadboard } from '@/stores';
import { isNil, last } from 'lodash-es';

const props = defineProps({
    load: Object
});

const progress = computed(() => {
    if (isNil(props.load.miles_completed) || isNil(props.load.trip_miles) || props.load.trip_miles === 0) {
        return 0;
    }
    return (props.load.miles_completed / props.load.trip_miles) * 100;
});

const mini = computed(() => loadboard.value.layout === 'mini');

const hasLaneStart = computed(() => props.load.lane.start.city && props.load.lane.start.region);

const hasLaneEnd = computed(() => props.load.lane.end.city && props.load.lane.end.region);

const loadScheduleStatus = computed(() => {
    if (!props.load.eta_status) return null;
    const status = { on_time: { text: 'on-time', color: '#3bb273' }, behind: { text: 'behind', color: '#FF0000' } };
    return props.load.eta_status ? status[props.load.eta_status] : null;
});

const lastCheckedTask = computed(() =>
    last(props.load.tasks.filter(task => task.is_complete && task.phase === 'ship'))
);

const pickUps = computed(() => props.load.stops.filter(stop => stop.type === 'pickup').length);

const dropOffs = computed(() => props.load.stops.filter(stop => stop.type === 'dropoff').length);

function formatETA(date) {
    const eta = new Date(date);

    const options = {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: props.load.eta_timezone,
        timeZoneName: 'short'
    };

    const formatter = new Intl.DateTimeFormat(navigator.language, options);

    return !date ? null : formatter.format(eta);
}
</script>
