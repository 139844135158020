<template>
    <ul
        v-if="page.props.load.notes.length > 0"
        role="list"
        class="my-4 max-h-96 space-y-2 overflow-y-auto px-1"
        tabindex="0">
        <li
            v-for="note in notes"
            :id="`note-${note.id}`"
            :key="note.id">
            <AppMessage
                :user="note.user_name"
                :title="note.title"
                :body="note.content"
                :created-at="note.created_at"
                :icon="note.icon"
                size="lg" />
        </li>
    </ul>

    <div v-else>{{ $t('No notes found on this load.') }}</div>

    <LoadDetailCreateNoteForm
        v-if="showingCreateNoteForm"
        @success="onNoteCreated" />
    <AppButton
        id="load-create-note-button"
        class="self-start"
        @click="showingCreateNoteForm = true">
        <AppIcon
            name="fal fa-pen"
            class="text-sm" />
        {{ $t('Create Note') }}
    </AppButton>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

defineEmits(['success']);

const page = usePage();

const showingCreateNoteForm = ref(false);

const initialNoteIds = ref([]);

const noteTypes = {
    claim: { title: 'Claim', icon: 'fas fa-gavel' },
    carrier_relations: { title: 'Carrier Relations', icon: 'fas fa-handshake' },
    task_snooze: { icon: 'fas fa-alarm-snooze' }
};

const notes = computed(() =>
    page.props.load.notes.map(note => {
        const noteType = noteTypes[note.type] ?? {};

        return {
            ...note,
            icon: note.type ? noteType.icon : null,
            title: note.title ? note.title : noteType.title
        };
    })
);

provide('showingCreateNoteForm', showingCreateNoteForm);

onMounted(() => (initialNoteIds.value = page.props.load.notes.map(({ id }) => id)));

function onNoteCreated() {
    showingCreateNoteForm.value = false;
}
</script>
