<template>
    <div>
        <ul
            v-if="page.props.load.billingErrors.length > 0"
            role="list"
            class="my-1 max-h-96 space-y-2 overflow-y-auto px-1"
            tabindex="0">
            <li
                v-for="billingError in page.props.load.billingErrors"
                :id="`billingError-${billingError.id}`"
                :key="billingError.id"
                class="mb-1">
                <div class="grid grid-cols-5 gap-2 py-1">
                    <div class="col-span-5 w-full">
                        <div
                            class="rounded-t-md bg-iel-blue p-1 px-2 text-xs text-white sm:flex sm:items-center sm:justify-between">
                            <p class="mb-2 pr-2 sm:mb-0">{{ billingError.short_name }}</p>
                            <time
                                class="whitespace-nowrap"
                                :datetime="billingError.created_at">
                                {{ $t(formatDate(billingError.created_at)) }}
                            </time>
                        </div>
                        <div
                            class="max-h-20 w-auto overflow-y-auto rounded-b-md border border-iel-blue/30 bg-white p-1">
                            <p>{{ $t(billingError.body) }}</p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div
            v-else
            class="mb-4">
            {{ $t('No billing errors found on this load.') }}
        </div>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { formatDate } from '@/helpers.js';

const page = usePage();
</script>
