<template>
    <div
        v-if="loaded"
        id="custom-legend-todays-calls"
        class="absolute -ml-2 -mt-2 flex w-full items-center justify-center gap-x-6 py-4 text-sm">
        <div class="flex items-center gap-1">
            <span class="block h-3 w-3 rounded-full bg-iel-blue"></span>
            10+ Calls
        </div>
        <div class="flex items-center gap-1">
            <span class="block h-3 w-3 rounded-full bg-[#757b85]"></span>
            3-9 Calls
        </div>
        <div class="flex items-center gap-1">
            <span class="block h-3 w-3 rounded-full bg-[#c8c8c8]"></span>
            1-2 Calls
        </div>
    </div>

    <Bar
        v-if="loaded"
        :id="kebabCase(title) + '-bar-graph'"
        :options="todaysCallsOptions"
        :data="todaysCallsData"
        aria-label="Bar graph"
        role="img">
        <p>Could not load graph.</p>
    </Bar>

    <div class="absolute w-full pb-8 text-xs text-iel-blue">
        <div class="flex items-center justify-center">Hours (Eastern Time)</div>
    </div>

    <AppGraphScreenReader
        :id="kebabCase(title) + 'graph-data-for-screen-readers'"
        :title="props.title"
        :data="todaysCallsData" />
</template>

<script setup>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { kebabCase } from 'lodash-es';
import axios from 'axios';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const loaded = ref(false);

const props = defineProps({
    title: {
        type: String,
        default: 'Todays Calls'
    }
});

const todaysCallsData = {
    labels: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23'
    ],
    datasets: [
        {
            data: []
        }
    ]
};

const backgroundColors = [];

const fetchTodaysCalls = async () => {
    try {
        const response = await axios.get('/loads/todays-calls');
        const data = response.data;

        todaysCallsData.datasets.forEach(dataset => {
            // initialize with zeros or replace with data if available
            dataset.data = todaysCallsData.labels.map(label => data[label] || 0);
        });

        const backgroundColors = generateBackgroundColors(todaysCallsData.datasets[0].data);
        todaysCallsData.datasets[0].backgroundColor = backgroundColors;

        loaded.value = true;
    } catch (error) {
        console.error('Failed to fetch todays calls data:', error);
    }
};

function generateBackgroundColors(data) {
    return data.map(value => {
        if (value >= 1 && value <= 2) {
            return '#c8c8c8';
        } else if (value >= 3 && value <= 9) {
            return '#757b85';
        } else {
            return '#002a40';
        }
    });
}

todaysCallsData.datasets[0].backgroundColor = backgroundColors;
const todaysCallsOptions = ref({
    responsive: true,
    maintainAspectRatio: false,
    label: false,
    borderRadius: 5,
    indexAxis: props.indexAxis,
    scales: {
        x: {
            ticks: {
                color: 'black',
                padding: 8,
                stepSize: 20,
                maxRotation: 0
            },
            grid: {
                display: false
            },
            border: {
                display: false
            }
        },
        y: {
            grid: {
                display: false
            },
            border: {
                display: false
            },
            ticks: {
                color: 'black',
                stepSize: 20,
                maxRotation: 0
            }
        }
    },

    plugins: {
        title: {
            text: props.title
        },
        legend: {
            labels: {
                boxWidth: 0,
                boxHeight: 0,
                color: 'transparent',
                padding: 20
            }
        }
    }
});

onMounted(() => {
    fetchTodaysCalls();
});
</script>

<style scoped>
canvas {
    @apply !h-64 w-auto p-1;
}
</style>
