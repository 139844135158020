<template>
    <table class="NX-mobile-stacked mr-4">
        <thead>
            <tr>
                <th class="border-b-2">{{ $t('Document Name') }}</th>
                <th class="border-b-2">{{ $t('Size') }}</th>
                <th class="border-b-2 lg:w-1/4">{{ $t('Labels') }}</th>
                <th class="border-b-2 lg:w-1/4">{{ $t('Stops') }}</th>
                <th class="border-b-2">{{ $t('Actions') }}</th>
            </tr>
        </thead>
        <tbody>
            <slot></slot>
        </tbody>
    </table>
</template>
