<template>
    <form
        class="grid gap-5"
        @submit.prevent="handleSubmit">
        <AppSelect
            v-model="form.source"
            name="source"
            :options="sourceOptions" />
        <AppSelect
            v-model="form.method"
            name="method"
            :options="methodOptions" />
        <AppSelect
            v-model="form.issue"
            name="issue"
            :options="issueOptions" />
        <AppDisplayField
            v-if="rootCauseOptions && !Object.values(rootCauseOptions).length"
            label="Root Cause"
            value="Not Applicable" />
        <AppSelect
            v-if="rootCauseOptions && Object.values(rootCauseOptions).length"
            v-model="form.root_cause"
            name="root_cause"
            :options="rootCauseOptions" />
        <AppDateTime
            v-model:datetime="form.follow_up_at"
            v-model:timezone="form.follow_up_at_timezone"
            :field-names="{ datetime: 'follow_up_at', timezone: 'follow_up_at_timezone' }"
            name="follow_up_at"
            :label="$t('Follow Up')"
            class="w-full" />
        <AppTextarea
            v-model="form.description"
            name="description"
            :label="$t('Description')" />
        <AppTextarea
            v-model="form.contact_info"
            name="contact_info"
            :label="$t('Contact Info')" />
        <div class="flex items-center justify-end gap-4">
            <AppButton
                variant="outline"
                type="button"
                @click="cancel">
                Cancel
            </AppButton>
            <AppButton type="submit">Submit</AppButton>
        </div>
    </form>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { zipObject } from 'lodash-es';
import { carrierRelationsIssues } from '@/data';

const props = defineProps({
    load: Object
});

const form = useForm({
    source: null,
    method: null,
    issue: null,
    root_cause: null,
    follow_up_at: null,
    follow_up_at_timezone: null,
    completed_at: false,
    description: null,
    contact_info: null
});

provide('form', form);

const emit = defineEmits(['cancel', 'success']);

const issues = Object.keys(carrierRelationsIssues);
const issueOptions = zipObject(issues, issues);

const sources = [
    'Carrier',
    'Factoring Company',
    'Broker',
    'SEM / SM',
    'OPS Member',
    'Acct. Member',
    'Triumph Pay',
    'Other'
];
const sourceOptions = zipObject(sources, sources);

const methods = ['Call', 'Email', 'Voicemail'];
const methodOptions = zipObject(methods, methods);

const rootCauseOptions = computed(() => {
    if (!form.issue) return null;
    const rootCauses = carrierRelationsIssues[form.issue];
    return zipObject(rootCauses, rootCauses);
});

watch(
    () => form.issue,
    value => {
        const options = carrierRelationsIssues[value];
        if (!options.length) form.root_cause = 'Not Applicable';
    }
);

function cancel() {
    form.reset();
    form.clearErrors();
    emit('cancel');
}

function handleSubmit() {
    const endpoint = route('loads.carrier-incident.store', props.load);

    form.post(endpoint, {
        preserveScroll: true,
        onSuccess() {
            emit('success');
        }
    });
}
</script>
