<template>
    <AppCard
        title="Details"
        theme="light_gray"
        content-class="grid gap-4 lg:grid-cols-2">
        <div class="col-span-full rounded-md border border-iel-gray p-2">
            <div class="grid gap-2 lg:grid-cols-4">
                <AppSelect
                    v-model="commodityType"
                    name="commodity_type"
                    class="col-span-full lg:col-span-2">
                    <option
                        v-for="(label, value) in commodityTypes"
                        :key="value"
                        :value="value">
                        {{ label }}
                    </option>
                </AppSelect>

                <AppTextarea
                    v-model="commodityTypeDetail.special_instructions"
                    name="special_instructions"
                    :url="commodityDetailUrl"
                    class="lg:col-span-2" />
                <AppTextarea
                    v-model="commodityTypeDetail.directions"
                    name="directions"
                    :url="commodityDetailUrl"
                    class="lg:col-span-2" />

                <AppInput
                    v-model="commodityTypeDetail.hours_of_operation"
                    name="hours_of_operation"
                    :url="commodityDetailUrl"
                    class="truncate text-nowrap" />
                <AppInput
                    v-model="commodityTypeDetail.dock_hours"
                    name="dock_hours"
                    :url="commodityDetailUrl" />
                <AppInput
                    v-model="commodityTypeDetail.dock_hour_notes"
                    name="dock_hour_notes"
                    :url="commodityDetailUrl" />

                <AppRadioGroup
                    name="is_fcfs"
                    label="FCFS/Appt"
                    :model-value="Number(commodityTypeDetail.is_fcfs)"
                    :url="commodityDetailUrl"
                    :options="[
                        { value: 1, label: 'FCFS' },
                        { value: 0, label: 'Appt' }
                    ]"
                    inline />
            </div>
        </div>
        <div class="w-full overflow-hidden">
            <AppTableSimple
                :columns="[
                    { name: 'name', label: 'Name' },
                    { name: 'phone_number', label: 'Phone' },
                    { name: 'email_address', label: 'Email' }
                ]"
                :data="contacts"
                th-class="bg-iel-light-gray">
                <template #actions="actionsProps">
                    <div class="flex gap-2">
                        <AppButton
                            icon="fal fa-pen"
                            variant="outline"
                            @click="editContact(actionsProps)" />
                        <AppButton
                            icon="far fa-trash-can"
                            variant="outline"
                            @click="deleteContact(actionsProps)" />
                    </div>
                </template>
                <template #header>
                    <header class="mb-4 flex items-center justify-between">
                        <span class="uppercase text-iel-gray">{{ $t('Contacts') }}</span>
                        <div class="ml-2 md:flex md:items-center md:justify-end md:gap-2 xl:ml-5">
                            <AppButton
                                icon="far fa-circle-plus"
                                class="mb-2 md:mb-0"
                                @click="createContact">
                                {{ $t('Add') }}
                            </AppButton>
                            <AppSearchInput
                                v-model="contactQuery"
                                class="mb-4 md:mb-0"
                                placeholder="Search" />
                        </div>
                    </header>
                </template>
            </AppTableSimple>
        </div>
        <div>
            <AppTextarea
                v-model="detail.loading_procedure"
                name="loading_procedure"
                :label="`${detail.type === 'shipping' ? 'Loading' : 'Unloading'}: Procedures/Requirements`"
                :url="detailUrl" />
            <div class="mt-2 flex gap-2">
                <AppInput
                    v-model="detail.third_party_scheduler_url"
                    name="third_party_scheduler_url"
                    label="Third Party Scheduler Link"
                    :url="detailUrl" />
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { router } from '@inertiajs/vue3';

import { commodityTypes } from '@/data';

const props = defineProps({
    // location detail provided by parent component, could be either shipping or receiving
    detail: Object
});

// currently selected commodity type (dry/cooler/frozen)
const commodityType = ref('dry');

// current location commodity type detail based on commodity type select
const commodityTypeDetail = computed(() =>
    props.detail.commodityTypeDetails.find(({ commodity_type }) => commodity_type === commodityType.value)
);

// dynamically determine the endpoints to send updates to depending on the current detail and commodity

const detailUrl = computed(() => route('locationDetails.update', props.detail));
const commodityDetailUrl = computed(() => route('locationDetailCommodities.update', commodityTypeDetail.value));

const contactQuery = ref('');

const contacts = computed(() =>
    contactQuery.value
        ? (props.detail?.contacts?.filter(
              ({ name, phone_number, email_address }) =>
                  name.toLowerCase().includes(contactQuery.value) ||
                  phone_number.toLowerCase().includes(contactQuery.value) ||
                  email_address.toLowerCase().includes(contactQuery.value)
          ) ?? [])
        : (props.detail?.contacts ?? [])
);

const { open: openLocationContactModal } = useModal('LocationContactModal');

function createContact() {
    openLocationContactModal({
        title: 'Create Contact',
        mode: 'create',
        locationDetail: props.detail
    });
}

function deleteContact(contact) {
    if (confirm('Delete contact?')) {
        router.delete(
            route('locationDetails.contacts.destroy', {
                locationDetail: props.detail,
                contact
            }),
            {
                preserveScroll: true
            }
        );
    }
}

function editContact(contact) {
    openLocationContactModal({
        title: 'Edit Contact',
        mode: 'edit',
        locationDetail: props.detail,
        contact
    });
}
</script>
