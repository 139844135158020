<template>
    <form
        class="flex flex-col gap-2 rounded-md border border-iel-gray bg-white p-2"
        @submit.prevent="submit">
        <div class="grid gap-4">
            <div class="grid gap-4 md:grid-cols-2">
                <AppAutocomplete
                    id="remit-address-1"
                    v-model="form.remit_address"
                    permission="update_remit_info"
                    name="remit-address-1"
                    label="Address 1"
                    :options-url="route('addresses.autocomplete')"
                    :placeholder="$t('Search for an address...')"
                    :initial-value="form.remit_address.address_1"
                    :disabled="isReadOnly"
                    required />
                <AppInput
                    id="remit-address-2"
                    v-model="form.remit_address.address_2"
                    permission="update_remit_info"
                    name="remit-address-2"
                    :disabled="isReadOnly" />
            </div>
            <div class="grid grid-cols-4 gap-4 lg:grid-cols-5">
                <AppInput
                    id="remit-city"
                    v-model="form.remit_address.city"
                    permission="update_remit_info"
                    class="col-span-3 lg:col-span-2"
                    name="remit-city"
                    :disabled="isReadOnly" />
                <AppInput
                    id="remit-region"
                    v-model="form.remit_address.region"
                    permission="update_remit_info"
                    class="lg:col-span-1"
                    name="remit-region"
                    :disabled="isReadOnly" />
                <AppInput
                    id="remit-postal-code"
                    v-model="form.remit_address.postal_code"
                    permission="update_remit_info"
                    class="col-span-2 lg:col-span-1"
                    name="remit_address.postal_code"
                    :disabled="isReadOnly" />
                <AppSelect
                    id="remit-country"
                    v-model="form.remit_address.country"
                    permission="update_remit_info"
                    class="col-span-2 lg:col-span-1"
                    name="remit-country"
                    :options="{ CA: 'CA', MX: 'MX', US: 'US' }"
                    :disabled="isReadOnly" />
            </div>
        </div>
        <div
            v-if="form.errors.address"
            class="bg-iel-light-redpx-1 mr-auto flex items-center gap-1 rounded border border-iel-light-red py-0.5 text-sm text-iel-dark-red">
            <AppIcon
                name="far fa-circle-exclamation"
                class="h-4 w-4" />
            <p>{{ form.errors.address }}</p>
        </div>
        <div class="flex items-center gap-2">
            <AppButton
                v-show="isReadOnly"
                :disabled="form.processing"
                permission="update_remit_info"
                @click="isReadOnly = false">
                {{ $t('Edit') }}
            </AppButton>
            <AppButton
                v-show="!isReadOnly"
                type="submit"
                :disabled="form.processing"
                permission="update_remit_info">
                {{ $t('Save') }}
            </AppButton>
            <p
                v-if="form.isDirty"
                class="text-sm italic text-iel-dark-gray">
                {{ $t('Unsaved changes') }}
            </p>
        </div>
    </form>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';

const page = usePage();

const form = useForm({
    remit_address: {
        address_1: page.props.carrier.remit_address?.address_1,
        address_2: page.props.carrier.remit_address?.address_2,
        city: page.props.carrier.remit_address?.city,
        region: page.props.carrier.remit_address?.region,
        postal_code: page.props.carrier.remit_address?.postal_code,
        country: page.props.carrier.remit_address?.country
    }
});

provide('form', form);

const isReadOnly = ref(true);

function submit() {
    form.transform(data => data.remit_address).patch(route('carriers.remit.address.update', page.props.carrier.id), {
        preserveScroll: true,
        onSuccess() {
            isReadOnly.value = true;
        }
    });
}
</script>
