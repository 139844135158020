<template>
    <div
        class="relative w-full rounded-lg border border-iel-lightest-gray bg-white shadow"
        :class="[cardClass, overflowClass]">
        <header
            class="rounded-t p-2"
            :class="[headerClass, flexClass]">
            <div class="flex items-center gap-2">
                <slot name="title">
                    <AppIcon
                        v-if="icon"
                        :name="icon"
                        class="-mb-0.5 mr-2 text-lg"
                        :class="titleClass" />
                    <div
                        class="font-semibold uppercase"
                        :class="titleClass">
                        {{ title }}
                    </div>
                    <slot name="titleMeta" />
                </slot>
            </div>
            <div>
                <slot name="nav" />
            </div>
        </header>
        <div
            class="p-2"
            :class="contentClass"
            @click="$emit('clickInside')">
            <slot />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: String,
    titleMeta: {
        type: String,
        required: false
    },
    icon: String,
    stacked: Boolean,
    theme: {
        type: String,
        default: 'gray',
        validator: value => ['blue', 'gray', 'light_gray', 'white', 'warning', 'past_due'].includes(value)
    },
    contentClass: [String, Array],
    cardClass: [String, Array],
    overflowClass: {
        type: String,
        default: 'overflow-hidden'
    }
});

defineEmits(['clickInside']);

const headerClass = computed(
    () =>
        ({
            blue: 'bg-iel-blue',
            gray: 'bg-iel-gray',
            light_gray: 'bg-iel-gray/20',
            white: 'border-b-2',
            warning: 'bg-iel-dark-yellow',
            past_due: 'bg-iel-red'
        })[props.theme]
);

const titleClass = computed(
    () =>
        ({
            blue: 'text-white',
            gray: 'text-white',
            light_gray: 'text-iel-dark-gray',
            white: 'text-iel-dark-gray',
            warning: 'text-white',
            past_due: 'text-white'
        })[props.theme]
);

// This is to accommodate headers with varying amounts of elements. The default min, is for a smaller header such as the loadcard header. Use the stacked attribute on the AppCard component when a header has more elements such as customers/show
const flexClass = computed(() =>
    props.stacked
        ? 'flex flex-col items-center justify-between gap-2 md:flex-row'
        : 'flex items-start md:items-center justify-between'
);
</script>
