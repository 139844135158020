<template>
    <GuestLayout>
        <Head title="Forgot Password" />

        <p class="mb-4 text-sm text-iel-dark-gray">
            Forgot your password? No problem. Just let us know your email address and we will email you a password reset
            link that will allow you to choose a new one.
        </p>

        <div
            v-if="status"
            class="mb-4 text-sm font-medium text-iel-green">
            {{ status }}
        </div>

        <form
            class="grid gap-4"
            @submit.prevent="submit">
            <AppInput
                id="email"
                v-model="form.email"
                name="email"
                type="email"
                required
                label="Email"
                placeholder="Email"
                :error="form.errors.email" />

            <AppButton
                type="submit"
                class="py-2"
                block
                :disabled="form.processing">
                Email Password Reset Link
            </AppButton>
        </form>
    </GuestLayout>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

defineProps({
    status: {
        type: String
    }
});

const form = useForm({
    email: ''
});

function submit() {
    form.post(route('password.email'));
}
</script>
