<template>
    <AppModal
        :id="context.modalId"
        :show="isOpen"
        :title="context.title"
        class="max-w-sm"
        @close="close"
        @closed="onClosed">
        <form @submit.prevent="submit">
            <div>
                <div class="grid items-end gap-2">
                    <AppInput
                        v-model="form.customer_contact_name"
                        name="customer_contact_name"
                        label="Customer AP Contact Name"
                        required />
                    <AppInput
                        v-model="form.customer_email"
                        name="customer_email"
                        label="Customer AP Email"
                        type="email"
                        required />
                    <AppLabel
                        :label="$t('Upload Credit Application')"
                        required />
                    <AppDocsDragDrop
                        id="customer-activation-document"
                        v-model="pending"
                        class="mt-2"
                        :accepted-file-types="['application/pdf', 'image/jpeg', 'image/png']" />
                    <div
                        v-if="pending.length > 0"
                        class="grid grid-cols-2 pt-2">
                        <div
                            v-for="file in pending"
                            :key="file.name">
                            {{ file.name }}
                            <AppButton
                                id="customer-activation-document-remove"
                                variant="outline"
                                class="w-fit grid-cols-1 place-self-start"
                                icon="fal fa-trash-can"
                                @click="removePending(index)">
                                <span class="sr-only">Remove {{ file.name }}</span>
                            </AppButton>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex items-end justify-end pt-4">
                <AppButton
                    variant="outline"
                    class="ml-4 mr-4 justify-end gap-4 p-2"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="save-customer-request-update"
                    class="justify-end gap-4 p-2"
                    type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>

            <div v-if="form.hasErrors">
                <ul>
                    <li
                        v-for="(message, index) in form.errors"
                        :key="index"
                        class="text-iel-red">
                        {{ message }}
                    </li>
                </ul>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CustomerActivationModal');

let customerId;

const form = useForm({
    type: 'Customer Activation',
    customer_contact_name: null,
    customer_email: null,
    documents: null
});

watch(isOpen, value => {
    form.reset();
    form.clearErrors();
    pending.value = [];

    customerId = context.value.customer.id;

    if (!value) return;
});

function submit() {
    form.documents = pending.value;
    form.post(
        route('customers.customerTicket.store', {
            customer: customerId
        }),
        {
            preserveScroll: true,
            onSuccess() {
                close();
            }
        }
    );
}

const pending = ref([]);

function removePending(i) {
    pending.value.splice(i, 1);
}
</script>
