<template>
    <AppIcon
        :title="mpStatusIconMessage"
        name="fa-circle"
        :class="mpStatusIconColor"
        class="h-5 w-5"
        solid />
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    mpStatusColor: String,
    mpStatusMessage: String
});

const mpStatusColorMap = {
    red: 'text-iel-red',
    green: 'text-iel-green',
    yellow: 'text-iel-yellow'
};

const mpStatusIconColor = computed(() => mpStatusColorMap[props.mpStatusColor] || null);

const mpStatusIconMessage = computed(() => props.mpStatusMessage || null);
</script>
