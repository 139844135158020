<template>
    <AppGraphBar
        v-if="loaded"
        :title="'Future Loads'"
        :data="futureData"
        :index-axis="'y'" />
</template>

<script setup>
import axios from 'axios';

// defines a reactive reference 'loaded' with an initial value of false. prevents graph from loading before data is fetched.
const loaded = ref(false);

// defines a reactive reference 'futureData' with an initial value of an object containing the labels and datasets for the graph.
const futureData = ref({
    labels: ['Unscheduled', 'Not Booked', 'Ready', 'Needs Attention'], // Labels for the graph
    datasets: [
        {
            data: [], // store the data points for the graph
            backgroundColor: ['#002a40', '#002a40', '#3bb273', '#c21714']
        }
    ]
});

// async function to fetch data from the server
const fetchFutureLoads = async () => {
    try {
        const response = await axios.get('/loads/future'); // sends a GET request to the server
        const data = response.data; // extracts the data from the server response

        // Updates the 'futureData' reference with the data from the server
        futureData.value.datasets[0].data = [data.unscheduled, data.not_booked, data.ready, data.needs_attention];
        loaded.value = true; // sets 'loaded' to true so the graph can render
    } catch (error) {
        console.error('Failed to fetch future loads data:', error);
    }
};

// calls 'fetchFutureLoads' when the component is mounted
onMounted(() => {
    fetchFutureLoads();
});
</script>
