<template>
    <div class="grid gap-2">
        <div class="grid grid-cols-2 gap-2">
            <AppSelect
                id="claim-type"
                name="claim_type"
                permission="update_claim_record"
                :label="$t('Claim Type')"
                :model-value="flag.claim.claim_type"
                :url="url"
                :options="claimTypeOptions" />
            <AppInput
                v-model="flag.claim.amount"
                name="amount"
                type="number"
                permission="update_claim_record"
                :min="0"
                step=".01"
                :label="$t('Amount')"
                :url="url" />
            <AppSelectTags
                id="required-load_documents"
                name="required_load_docs"
                permission="update_claim_record"
                :label="$t('Required Load Docs')"
                :model-value="flag.claim.required_load_docs"
                :url="url"
                :options="claimLoadTypes" />
            <AppSelectTags
                id="required-customer-documents"
                name="required_customer_docs"
                permission="update_claim_record"
                :label="$t('Required Customer Docs')"
                :model-value="flag.claim.required_customer_docs"
                :url="url"
                :options="claimCustomerTypes" />
        </div>
        <div class="grid grid-cols-2 gap-2">
            <div class="space-y-4">
                <div>
                    <AppAutocomplete
                        :model-value="assignee?.id"
                        name="user_id"
                        permission="update_claim_record"
                        :label="$t('Assignee')"
                        :options-url="route('users.autocomplete')"
                        :placeholder="$t('Search for an assignee...')"
                        :initial-options="
                            assignee?.id
                                ? [
                                      {
                                          value: assignee.id,
                                          display: assignee.name
                                      }
                                  ]
                                : []
                        "
                        :url="url" />
                    <div
                        v-if="!assignee?.id"
                        class="pt-2">
                        <AppButton
                            v-if="!props.flag.claim.resolved_at"
                            :id="`report-${props.flag.id}-assign-button`"
                            variant="primary"
                            size="sm"
                            class="cursor-pointer"
                            permission="update_claim_record"
                            @click="$emit('assign-to-me')">
                            {{ $t('Assign To Me') }}
                        </AppButton>
                    </div>
                </div>
                <AppDateTime
                    v-model:datetime="form.follow_up_at"
                    v-model:timezone="form.follow_up_at_timezone"
                    :field-names="{ datetime: 'follow_up_at', timezone: 'follow_up_at_timezone' }"
                    name="follow_up"
                    permission="update_claim_record"
                    :label="$t('Follow Up')"
                    :url="url"
                    class="col-span-2"
                    :hide-timezone-text="true" />
                <AppSelect
                    id="resolution"
                    name="resolution"
                    permission="update_claim_record"
                    :label="$t('Resolution')"
                    :model-value="props.flag.claim.resolution"
                    :url="url"
                    :options="resolutionTypes"
                    class="col-span-2"
                    @auto-save-change="$emit('resolution-set')" />
            </div>
            <div class="col-start-2 grid gap-4">
                <AppTextarea
                    v-model="props.flag.claim.description"
                    name="description"
                    permission="update_claim_record"
                    :label="$t('Description')"
                    input-class="resize-none"
                    rows="12"
                    :url="url"
                    class="xl:col-span-2" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { zipObject } from 'lodash';
import { claimTypes } from '@/data';

defineEmits(['resolution-set', 'assign-to-me']);

const props = defineProps({
    flag: Object,
    assignee: Object
});

const form = useForm({
    follow_up_at: props.flag.claim.follow_up_at,
    follow_up_at_timezone: props.flag.claim.follow_up_at_timezone
});

provide('form', form);

const claimTypeOptions = zipObject(claimTypes, claimTypes);

const claimLoadTypes = [
    'BOL',
    'Carrier RateCon',
    'Damage Photo',
    'POD',
    'Pulp Temp Photo',
    'Reefer Download',
    'TempTale',
    'USDA Inspection Cert'
];

const claimCustomerTypes = ['Claim Invoice', 'Disposal Invoice', 'Original Sales Invoice', 'Salvage Invoice'];

const resolutionTypes = ['Closed', 'Sent to Collections', 'Suspended'];

const url = route('loads.claims.update', { load: props.flag.id, claim: props.flag.claim.id });
</script>
