<template>
    <div>
        <AppHeading
            size="sm"
            class="pb-2 text-iel-gray">
            {{ $t('Load Documents') }}
        </AppHeading>
        <div class="mb-6 flex flex-col gap-4">
            <FlaggedDocsList v-if="load.documents.length > 0">
                <FlaggedDocsItem
                    v-for="document in load.documents"
                    :key="document.id"
                    :load="load"
                    :document="document"
                    :edit-permission="permissions.edit"
                    :delete-permission="permissions.delete"
                    @remove="success" />
            </FlaggedDocsList>
            <div
                v-else
                class="text-sm">
                {{ $t('No documents found on this load.') }}
            </div>
            <AppDocsDragDrop
                :id="`load-${load.id}-files-new`"
                v-model="pending"
                :permission="permissions.upload"
                :accepted-file-types="['application/pdf', 'image/jpeg', 'image/png']" />
            <div
                v-if="pending.length > 0"
                class="flex flex-col">
                <h2 class="text-sm font-bold">Pending Uploads</h2>
                <FlaggedDocsList>
                    <FlaggedDocsPendingItem
                        v-for="file in pending"
                        :key="file.name"
                        :load="load"
                        :pending-file="file"
                        @remove="removePending(index)" />
                </FlaggedDocsList>
            </div>
        </div>
    </div>
</template>

<script setup>
defineProps({
    load: Object,
    permissions: {
        type: Object,
        default: () => ({})
    }
});

const pending = ref([]);
const emit = defineEmits(['success']);

function success() {
    emit('success');
}

function removePending(i) {
    pending.value.splice(i, 1);
    success();
}
</script>
