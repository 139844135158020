<template>
    <form
        class="mb-2 flex flex-col gap-2 rounded-md border bg-white p-2 @container"
        @submit.prevent="createStop">
        <p class="text-sm md:text-base">{{ $t('New Stop') }}</p>
        <div class="grid gap-2">
            <AppAutocomplete
                v-model="form.location_id"
                name="location_id"
                label=""
                :options-url="route('locations.autocomplete')"
                :placeholder="$t('Search for a location...')"
                :confirmation="confirmation" />
            <AppRadioGroup
                v-model="form.type"
                name="type"
                label=""
                :options="[
                    { value: 'pickup', label: 'Pickup' },
                    { value: 'dropoff', label: 'Dropoff' }
                ]" />
        </div>
        <div class="my-2 flex w-full gap-x-2">
            <AppButton
                id="load-create-stop-add-button"
                type="submit"
                class="self-start"
                :disabled="form.processing">
                {{ $t('Add') }}
            </AppButton>
            <AppButton
                id="load-create-stop-cancel-button"
                type="submit"
                class="self-start"
                variant="outline"
                @click.prevent="cancelStop">
                {{ $t('Cancel') }}
            </AppButton>
        </div>
    </form>
</template>

<script setup>
import { router, useForm, usePage } from '@inertiajs/vue3';

const emit = defineEmits(['success']);

const stopForm = inject('showingCreateStopForm');

const page = usePage();
const load = computed(() => page.props.load);

const form = useForm({
    load_id: page.props.load.id,
    location_id: null,
    type: null
});

provide('form', form);

const ratecon = useRateCon(load);
const confirmation = ratecon.confirmChange;

function createStop() {
    form.post(route('stops.store'), {
        preserveScroll: true,
        onSuccess: () => {
            emit('success');
            // TODO change reload call to `only: ['stops']` if stops are exposed as their own key in the response
            router.reload({ only: ['load'] });
        }
    });
}

function cancelStop() {
    stopForm.value = false;
}
</script>
