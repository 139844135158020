<template>
    <div
        v-if="reports.length === 0"
        class="p-4 text-center text-gray-500">
        {{ $t('No reports available') }}
    </div>
    <AppTablePill
        v-else
        :columns="[
            { name: 'name', label: $t('Name'), sortable: true },
            { name: 'description', label: $t('Description') },
            { name: 'source', label: $t('Source') }
        ]"
        :data="reports">
        <template #actions="actionsProps">
            <div class="flex w-full justify-end gap-4">
                <AppButton
                    :id="`edit-report-${actionsProps.id}`"
                    icon="fal fa-pen"
                    variant="outline"
                    aria-label="button to edit report"
                    @click="editReport(actionsProps)" />
                <AppButton
                    :id="`delete-report-${actionsProps.id}`"
                    icon="far fa-trash-can"
                    variant="outline"
                    aria-label="button to delete report"
                    @click="deleteReport(actionsProps)" />
            </div>
        </template>
        <template #name-cell="{ row }">
            <Link :href="route('reports.show', row.id)">
                <div class="flex gap-2 text-iel-blue">
                    <strong>{{ row.name }}</strong>
                </div>
            </Link>
        </template>
    </AppTablePill>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import ReportLayout from '@/Layouts/ReportLayout.vue';
import { router } from '@inertiajs/vue3';

const { open: openReportModal } = useModal('ReportModal');

defineOptions({
    layout: [AuthenticatedLayout, ReportLayout]
});

defineProps({
    reports: Object,
    tiers: Object
});

function editReport(report) {
    openReportModal({
        mode: 'edit',
        title: 'Edit Report',
        report: report
    });
}

function deleteReport(report) {
    if (confirm('Are you sure you want to delete this report?')) {
        router.delete(
            route('reports.destroy', {
                report
            }),
            {
                preserveScroll: true,
                onError(e) {
                    alert(e.error_message);
                }
            }
        );
    }
}
</script>
