<template>
    <AppCard
        title="RateCon"
        theme="light_gray"
        content-class="grid w-full gap-2 md:grid-cols-2">
        <AppInput
            v-model="customer.team_contact_name"
            name="team_contact_name"
            label="Sales Team Member Name"
            permission="update_ratecon"
            :url="url" />
        <AppInput
            v-model="customer.team_contact_email"
            name="team_contact_email"
            label="Sales Team Member Email"
            permission="update_ratecon"
            :url="url" />
        <AppInput
            v-model="customer.team_contact_phone"
            name="team_contact_phone"
            label="Sales Team Member Phone"
            permission="update_ratecon"
            :url="url" />
        <AppInput
            v-model="customer.team_contact_fax"
            name="team_contact_fax"
            label="Sales Team Member Fax"
            permission="update_ratecon"
            :url="url" />
        <AppTextarea
            v-model="customer.special_instructions"
            name="special_instructions"
            label="RateCon Special Instructions"
            permission="update_ratecon"
            class="md:col-span-2"
            :url="url" />
    </AppCard>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const customer = computed(() => page.props.customer);

const url = route('customers.ratecon.update', page.props.customer);
</script>
