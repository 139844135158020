<template>
    <div
        id="load-detail-carrier-pay-total"
        class="grid border-t border-iel-lightest-gray pt-4"
        :class="{ 'xl:grid-cols-10': hasTotal }">
        <div :class="{ 'xl:col-span-9': hasTotal }">
            <AppHeading
                size="md"
                class="text-right">
                {{ $t('Total') }}
            </AppHeading>
        </div>
        <div
            v-if="hasTotal"
            id="load-detail-carrier-pay-total-value"
            class="text-center font-bold">
            $ {{ total }}
        </div>
        <div
            v-else
            id="load-detail-carrier-pay-total-empty-state"
            class="text-sm">
            {{ $t('Add rates to see the total displayed here.') }}
        </div>
    </div>
</template>

<script setup>
import { isNil } from 'lodash-es';

const props = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const total = computed(() => props.load.total_pay_carrier_amount);
const hasTotal = computed(() => !isNil(total.value));
</script>
