<template>
    <div class="relative z-50">
        <div class="fixed bottom-2 left-2">
            <div class="group flex w-32 items-center gap-1">
                <button
                    class="feedback-trigger flex h-14 w-14 items-center justify-center rounded-full border-2 border-iel-silver bg-iel-red transition duration-[850ms] ease-in-out"
                    :class="{ 'rotate-[360deg]': isPopupOpen, '-rotate-[360deg]': !isPopupOpen }"
                    @click="toggle">
                    <AppIcon
                        :name="icon"
                        class="h-7 w-7 text-white" />
                </button>
                <p
                    class="rounded-md border border-iel-blue bg-white p-1 text-sm opacity-0 transition group-hover:opacity-100">
                    Feedback
                </p>
            </div>

            <!-- Popup -->
            <Transition name="slide-fade">
                <div
                    v-if="isPopupOpen"
                    class="border-silver absolute bottom-16 left-6 min-h-[345px] w-[375px] border-2 bg-white shadow-xl">
                    <div class="pt-8">
                        <div class="mx-auto mb-6 h-auto w-48">
                            <img
                                src="/images/logo-ratecon.png"
                                alt="Logo for IEL" />
                        </div>
                        <h1 class="border-b border-iel-light-gray pb-8 text-center text-iel-blue">
                            How was your experience?
                        </h1>
                    </div>

                    <div>
                        <AppFeedbackForm
                            :feedbacks="feedbacks"
                            :labels="labels"
                            @selected="setSelectedFeedback"
                            @unselected="selectedFeedback = undefined" />
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const isPopupOpen = ref(false);
const icon = ref('fal fa-comment-pen');

const selectedFeedback = ref(null);

function toggle() {
    isPopupOpen.value = !isPopupOpen.value;
}

watch(isPopupOpen, newValue => {
    icon.value = newValue ? 'far fa-xmark' : 'fal fa-comment-pen';
});

const setSelectedFeedback = feedback => {
    selectedFeedback.value = feedback;
};

const feedbacks = ref({
    positive: { icon: 'fal fa-thumbs-up', title: 'positive' },
    negative: { icon: 'fal fa-face-frown', title: 'negative' },
    suggestion: { icon: 'fal fa-lightbulb-on', title: 'suggestion' }
});

const labels = ref({
    feedbacks: {
        positive: { label: 'I liked something...' },
        negative: { label: 'I did not like something...' },
        suggestion: { label: 'I have a suggestion...' }
    },
    placeholder: 'Please leave your feedback...',
    button: 'Submit Feedback',
    success: 'Thank you for your feedback!'
});
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(40px);
    opacity: 0;
}
</style>
