<template>
    <div>
        <AppGraphCircle
            class="cursor-pointer"
            title="Days to Pay"
            :data="daysToPayData"
            :show-total="true"
            :show-legend="false"
            @update-data="pickUrlByEmittedIndex" />
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
    dtpRanges: {
        type: Array,
        required: true
    },
    dtpCounts: {
        type: Array,
        required: true
    },
    totalSum: {
        type: Number,
        required: true
    }
});

const daysToPayData = ref({
    labels: props.dtpRanges.map(range => range.label),
    datasets: [
        {
            data: props.dtpCounts,
            borderColor: ['#002a40', '#c18f24', '#c10910'],
            backgroundColor: ['#002a40', '#c18f24', '#c10910'],
            cutout: '70%',
            totalSum: props.totalSum
        }
    ]
});

const page = usePage();
const customer = page.props.customer;

const depFromChild = ref('');

// uses element[0].index emitted from child on graph click to choose correct URL
const pickUrlByEmittedIndex = data => {
    depFromChild.value = data;

    const index = data.element[0].index;
    const customer_id = customer.id;
    const routes = [
        {
            filter: '31-to-60',
            url: route('loads.index', { customer_id, billing_filter: '31-to-60' })
        },
        {
            filter: '61-to-90',
            url: route('loads.index', { customer_id, billing_filter: '61-to-90' })
        },
        { filter: 'overNinety', url: route('loads.index', { customer_id, billing_filter: 'overNinety' }) }
    ];

    window.open(routes[index].url, '_blank');
};
</script>
